import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LanguageSelectCmp from "./LanguageSelectCmp";

const SignUpInHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img
            src="/images/logo_100_px.png"
            width="40"
            height="40"
            alt="Shop itemz Logo"
          />

          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: 15, sm: 20 },
              fontWeight: "bold",
            }}
          >
            Shop itemz
          </Typography>
        </Box>
      </Link>

      <LanguageSelectCmp />
    </Box>
  );
};
export default SignUpInHeader;
