import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/productImage";

// Add All Product Images at ones Request
export const useAddAllProductImagesRequest = (files, personId) => {
  return useMutation({
    mutationFn: async (productId) => {
      const queryParams = {
        personId: personId,
        productId: productId,
      };

      const queryString = new URLSearchParams(queryParams).toString();

      const fullUrl = `${BACK_URL}${basePath}/add_all_product_images?${queryString}`;

      // form data
      const form = new FormData();

      const remainingFiles = files.filter((file, index) => index !== 0);
      remainingFiles.forEach((file) => {
        form.append("files", file);
      });

      return await axios.post(fullUrl, form, {
        headers: {
          Authorization: JWT_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};

// get Product Images
export const useGetProductImagesRequest = (pageNum, pageSize, productId) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/productImages/${productId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
  });
};
