import { Box, Typography } from "@mui/material";
import ProductsCmp from "../homePage/ProductsCmp";
import { CreateProductCmp } from "../../components/largeComponents/CreateProductCmp";
import { useTranslation } from "react-i18next";

const ProductsViewBoutique = ({
  productsList,
  setProductsList,
  boutiqueToView,
  handleOpenModalUseMobileApp,
  urlIsMyBoutiqueAndImOwner,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {productsList && (
        <Box sx={{ paddingBottom: 3, marginTop: 3 }}>
          {urlIsMyBoutiqueAndImOwner ? (
            <CreateProductCmp
              boutiqueToView={boutiqueToView}
              setProductsList={setProductsList}
            />
          ) : (
            <Typography variant="h6" mb={2}>
              {t("Store_Products")}
            </Typography>
          )}

          <ProductsCmp
            products={productsList}
            setProductsList={setProductsList}
            urlIsMyBoutiqueAndImOwner={urlIsMyBoutiqueAndImOwner}
            handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
          />
        </Box>
      )}
    </>
  );
};
export default ProductsViewBoutique;
