import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/post";

// Save Post Request
export const useSavePostRequest = () => {
  return useMutation({
    mutationFn: async (post) => {
      const fullUrl = `${BACK_URL}${basePath}/savePost`;

      return await axios.post(fullUrl, post, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Add A Post Request
export const useAddAPostRequest = (file, personId, comment) => {
  const queryParams = {
    personId: personId,
    comment: comment,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/addPostWithFile?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      // form data
      const form = new FormData();

      form.append("file", file);

      return await axios.post(fullUrl, form, {
        headers: {
          Authorization: JWT_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};

// Delete Post Request
export const useDeletePostRequest = (postId) => {
  const queryParams = {
    postId: postId,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/deleteMyNeed?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Get My Posts Request
export const useGetMyPostsRequest = (personId, pageNum, pageSize) => {
  const queryParams = {
    personId: personId,
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getMyNeeds?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: personId !== 0,
  });
};

// Get Posts Request
export const useGetPostsRequest = (
  sort,
  pageNum,
  pageSize,
  postIds,
  enabled
) => {
  const queryParams = {
    sort: sort,
    pageNum: pageNum,
    pageSize: pageSize,
    postIds: postIds,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getPost?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
