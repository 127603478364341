import { Box, Button, CircularProgress } from "@mui/material";
import OrderMini from "../ui/OrderMini";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useModalViewOrderCmp } from "../../hooks/useModalViewOrderCmp";
import { useTranslation } from "react-i18next";

export default function MyOrdersMiniCmp({
  myOrdersList,
  isFinished,
  queryGetMyOrders,
  loadMore,
}) {
  const uniqueMyOrdersList = myOrdersList.filter(
    (not, index, self) => index === self.findIndex((n) => n.id === not.id)
  );

  const { t } = useTranslation();

  const {
    ModalViewOrderComponent,
    handleOpenModalViewOrder,
    setOrderToView,
    setTypeViewOrder,
  } = useModalViewOrderCmp();

  const viewOrder = (order, type) => {
    setOrderToView(order);
    setTypeViewOrder(type);
    handleOpenModalViewOrder();
  };

  return (
    <Box>
      <ModalViewOrderComponent />

      {uniqueMyOrdersList.map((order) => (
        <OrderMini
          order={order}
          key={order.id}
          viewOrder={viewOrder}
          type={"sent"}
        />
      ))}

      {/* Progress bar */}
      <Box
        sx={{
          margin: 4,
          display: isFinished.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryGetMyOrders.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMore}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
            }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </Box>
  );
}
