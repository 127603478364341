import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { FRONT_URL, SHOP_LOGO_URL } from "../../myHelpers/constants";

const SEO = ({ title, description, image, url }) => {
  const { t, i18n } = useTranslation();

  return (
    <Helmet>
      <title>{title || t("seo_meta.title")}</title>

      <meta
        name="description"
        content={description || t("seo_meta.description")}
      />

      {/* Open Graph (Facebook) */}
      <meta property="og:title" content={title || t("seo_meta.title")} />
      <meta
        property="og:description"
        content={description || t("seo_meta.description")}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={description || t("seo_meta.description")}
      />
      <meta property="og:image" content={image || SHOP_LOGO_URL} />
      <meta property="og:image:alt" content="company logo" />
      <meta property="og:url" content={url || FRONT_URL} />
      <meta property="og:locale" content={i18n.language} />

      {/* Twitter Card */}
      <meta name="twitter:title" content={title || t("seo_meta.title")} />
      <meta
        name="twitter:description"
        content={description || t("seo_meta.description")}
      />
      <meta name="twitter:image" content={image || SHOP_LOGO_URL} />

      {/* Canonical URL */}
      <link rel="canonical" href={url || FRONT_URL} />
    </Helmet>
  );
};
export default SEO;
