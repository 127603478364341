import { Box, Container } from "@mui/material";

export const ContainerizedBox = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "85%", md: "78%", lg: "73%" },
        }}
      >
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};
