import { useMutation } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/accountVerification";

// Add Account Verification Request
export const useAddAccountVerificationRequest = (
  personId,
  documentType,
  fileFront,
  fileBack,
  fileSelfie
) => {
  const queryParams = {
    personId: personId,
    documentType: documentType,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/addAccountVerification?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      // form data
      const form = new FormData();
      form.append("fileFront", fileFront);
      form.append("fileBack", fileBack);
      form.append("fileSelfie", fileSelfie);

      return await axios.post(fullUrl, form, {
        headers: {
          Authorization: JWT_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};
