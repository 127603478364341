import { CancelOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PRINCIPAL } from "../../myHelpers/constants";
import EmptyDossiersCmp from "./EmptyDossiersCmp";
import { useGetEmptyDossiersRequest } from "../../requestsApis/dossiersRequest";
import { CreateFavoritesFolderCmp } from "./CreateFavoritesFolderCmp";
import { DialogLoginExpiredComponent } from "./DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "50%", lg: "40%" },
  height: "70vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

export const ModalEmptyDossiers = ({
  product,
  open,
  handleClose,
  handleSaveCart,
}) => {
  const [emptyDossiersList, setEmptyDossiersList] = useState([]);
  const [enabledQueryDossiers, setEnabledQueryDossiers] = useState(true);
  const { t } = useTranslation();

  let pageNum = useRef(0);
  let pageSize = useRef(50);
  let isFinished = useRef(false);

  const queryGetEmptyDossiers = useGetEmptyDossiersRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current,
    enabledQueryDossiers
  );

  const loadMore = () => {
    if (!isFinished.current && !queryGetEmptyDossiers.isLoading) {
      pageNum.current += 1;
      queryGetEmptyDossiers.refetch();
    }
  };

  useEffect(() => {
    if (queryGetEmptyDossiers.isError) {
      console.log(queryGetEmptyDossiers.error);
    }

    if (queryGetEmptyDossiers.isSuccess && queryGetEmptyDossiers.data) {
      const fetchedDossiers = queryGetEmptyDossiers.data.data;

      setEmptyDossiersList((prevList) => [...prevList, ...fetchedDossiers]);

      if (fetchedDossiers.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetEmptyDossiers.isPending]);

  return (
    <>
      <DialogLoginExpiredComponent />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            color="inherit"
            sx={{ position: "absolute", top: -8, right: -8 }}
          >
            <CancelOutlined />
          </IconButton>
          {/* Top Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{ fontSize: { xs: 15, sm: 20 }, fontWeight: "bold" }}
            >
              {t("Save_product_to")}
            </Typography>
            <CreateFavoritesFolderCmp
              setDossierList={setEmptyDossiersList}
              pageSize={pageSize}
              whereFrom={"emptyDossier"}
            />
          </Box>

          {/* Main conaint */}
          <Box
            sx={{
              position: "relative",
              height: "90%",
              width: "100%",
              overflow: "auto",
              marginRight: 1,
            }}
          >
            <EmptyDossiersCmp
              dossiers={emptyDossiersList}
              product={product}
              handleSaveCart={handleSaveCart}
            />

            {/* Progress bar */}
            <Box
              sx={{
                margin: 3,
                display: isFinished.current ? "none" : "flex",
                justifyContent: "center",
              }}
            >
              {queryGetEmptyDossiers.isFetching && <CircularProgress />}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
