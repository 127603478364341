import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import {
  useRegisterRequest,
  useVerifyPhoneRequest,
} from "../../requestsApis/personRequest";
import { DoneOutline } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";
import SignUpInHeader from "../../components/ui/SignUpInHeader";
import { useTranslation } from "react-i18next";
import DeviceAndroidComponent from "../../components/ui/DeviceAndroidComponent";

export default function SignUp() {
  const navigate = useNavigate();
  const [person, setPerson] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [signUpError, setSignUpError] = useState("");
  const [stepSignUp, setStepSignUp] = useState(1);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [passwordHelperText, setPasswordHelper] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [firstnameHelperText, setFirstnameHelperText] = useState("");
  const [errorFirstname, setErrorFirstname] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);

  const { t, i18n } = useTranslation();

  // ****************     Methods               *******************
  const handleChange = (event) => {
    setPerson({
      ...person,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneChange = (newPhone) => {
    setPerson({
      ...person,
      phoneNumber: newPhone,
    });
  };

  const validateFirstname = () => {
    if (person.firstname.length < 3) {
      setFirstnameHelperText(t("At_least_3_characters"));
      setErrorFirstname(true);
      return false;
    } else {
      setFirstnameHelperText("");
      setErrorFirstname(false);
      return true;
    }
  };

  const validatePhoneNumber = () => {
    if (person.phoneNumber.length < 9) {
      setPhoneNumberHelperText(t("Enter_a_valid_phone_number"));
      setErrorPhoneNumber(true);
      return false;
    } else {
      setPhoneNumberHelperText("");
      setErrorPhoneNumber(false);
      return true;
    }
  };

  const validateEmail = () => {
    if (person.email.length < 5) {
      setEmailHelperText(t("Enter_a_valid_email"));
      setErrorEmail(true);
      return false;
    } else {
      setEmailHelperText("");
      setErrorEmail(false);
      return true;
    }
  };

  const validatePassword = () => {
    if (person.password.length < 4) {
      setPasswordHelper(t("At_least_4_characters"));
      setErrorPassword(true);
      return false;
    } else {
      setPasswordHelper("");
      setErrorPassword(false);
      return true;
    }
  };

  const verifyPhoneNum = () => {
    validateFirstname();
    validatePhoneNumber();

    if (validateFirstname() && validatePhoneNumber()) {
      mutationVerifPhone.mutate();
      // setStepSignUp((oldStepValue) => oldStepValue + 1);
    }
  };

  const previousSignUp = () => {
    setStepSignUp((oldStepValue) => oldStepValue - 1);
  };

  const registerPerson = () => {
    validateEmail();
    validatePassword();

    if (validateEmail() && validatePassword()) {
      mutationRegister.mutate();
      // setStepSignUp((oldStepValue) => oldStepValue + 1);
    }
  };

  // ****************     Mutations               *******************
  const mutationVerifPhone = useVerifyPhoneRequest(person);
  const mutationRegister = useRegisterRequest(person);

  // ****************     Use Effects               *******************

  useEffect(() => {
    if (mutationVerifPhone.isPending) {
      setSignUpError("");
    }
    if (mutationVerifPhone.isError) {
      const error = mutationVerifPhone.error;
      setSignUpError(
        error?.response?.data?.body
          ? error.response.data.body
          : t("Error_while_trying_to_Verify_Phone")
      );
    }

    if (mutationVerifPhone.isSuccess) {
      setStepSignUp((oldStepValue) => oldStepValue + 1);
    }
  }, [mutationVerifPhone.isPending]);

  useEffect(() => {
    if (mutationRegister.isPending) {
      setSignUpError("");
    }

    if (mutationRegister.isError) {
      const error = mutationRegister.error;

      // Email déjà utilisé
      if (
        error.response.status === 302 &&
        error.response.data.body === "Email déjà utilisé"
      ) {
        setSignUpError(t("Email_already_taken"));
      } // Numéro de téléphone déjà utilisé
      else if (
        error.response.status === 404 &&
        error.response.data.body === "Numéro de téléphone déjà utilisé"
      ) {
        setSignUpError(t("Phone_number_already_taken"));
      } else if (error.response.status === 400) {
        setSignUpError(t("Fill_in_all_the_field"));
      } else {
        setSignUpError(t("Error_while_trying_to_register"));
      }
    }

    if (mutationRegister.isSuccess) {
      setStepSignUp((oldStepValue) => oldStepValue + 1);
    }
  }, [mutationRegister.isPending]);

  return (
    <>
      <DeviceAndroidComponent />
      <ContainerizedBox>
        <SignUpInHeader />
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: 24,
              fontWeight: "bold",
              marginTop: 5,
              marginBottom: 3,
            }}
          >
            {t("Create_an_account")}
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontSize: 23,
              fontWeight: "bold",
              marginBottom: 2,
            }}
          >
            {t("Step")} {stepSignUp}
          </Typography>
          <Typography variant="p" color="error">
            {signUpError}
          </Typography>

          <Stack direction="column" spacing={3} alignItems="center">
            {stepSignUp === 1 && (
              <>
                <TextField
                  name="firstname"
                  label={t("First_name")}
                  required
                  value={person.firstname}
                  onChange={handleChange}
                  sx={{ width: { xs: "90%", sm: "25rem" } }}
                  color="secondary"
                  error={errorFirstname}
                  helperText={firstnameHelperText}
                />
                <TextField
                  name="lastname"
                  label={t("Last_name")}
                  value={person.lastname}
                  onChange={handleChange}
                  sx={{ width: { xs: "90%", sm: "25rem" } }}
                  color="secondary"
                />
                {/* <Box>
                <Typography sx={{ textAlign: "left" }}>
                  Phone number *
                </Typography>
                <PhoneInput
                  country="us"
                  size="large"
                  value={person.phoneNumber}
                  onChange={(phone) => {
                    setPerson({
                      ...person,
                      phoneNumber: `+${phone}`,
                    });
                  }}
                />
                <Typography color="error" fontSize={11}>
                  {phoneNumberHelperText}
                </Typography>
              </Box> */}

                <MuiTelInput
                  color="secondary"
                  sx={{ width: { xs: "90%", sm: "25rem" } }}
                  value={person.phoneNumber}
                  onChange={handlePhoneChange}
                  defaultCountry="US"
                  forceCallingCode
                  focusOnSelectCountry
                  disableFormatting
                  langOfCountryName={
                    i18n.language.slice(0, 2) === "fr" ||
                    i18n.language.slice(0, 2) === "en"
                      ? i18n.language.slice(0, 2)
                      : "fr"
                  }
                  error={errorPhoneNumber}
                  helperText={phoneNumberHelperText}
                  placeholder="000000000"
                />

                <Button
                  disabled={mutationVerifPhone.isPending}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={verifyPhoneNum}
                  sx={{
                    textTransform: "none",
                    width: { xs: "90%", sm: "25rem" },
                  }}
                >
                  {mutationVerifPhone.isPending ? (
                    <CircularProgress size={26} color="white" />
                  ) : (
                    t("to_Continue")
                  )}
                </Button>

                <Stack
                  spacing={1}
                  direction="row"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography>
                    {t("Already_have_an_account_questionMark")}
                  </Typography>
                  <Link to="/signIn">
                    <Button
                      variant="text"
                      color="secondary"
                      sx={{ textTransform: "none" }}
                    >
                      {t("To_Login")}
                    </Button>
                  </Link>
                </Stack>
              </>
            )}

            {stepSignUp === 2 && (
              <>
                <TextField
                  name="email"
                  label={t("Email")}
                  required
                  value={person.email}
                  onChange={handleChange}
                  sx={{ width: { xs: "90%", sm: "25rem" } }}
                  color="secondary"
                  error={errorEmail}
                  helperText={emailHelperText}
                />
                <TextField
                  type="password"
                  name="password"
                  label={t("Password")}
                  required
                  value={person.password}
                  onChange={handleChange}
                  sx={{ width: { xs: "90%", sm: "25rem" } }}
                  color="secondary"
                  error={errorPassword}
                  helperText={passwordHelperText}
                />

                <Box
                  sx={{
                    width: { xs: "90%", sm: "25rem" },
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    onClick={previousSignUp}
                    sx={{
                      textTransform: "none",
                      width: "8rem",
                    }}
                  >
                    {t("Previous")}
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={registerPerson}
                    sx={{
                      textTransform: "none",
                      width: "8rem",
                    }}
                  >
                    {mutationRegister.isPending ? (
                      <CircularProgress size={26} color="white" />
                    ) : (
                      t("Register")
                    )}
                  </Button>
                </Box>
              </>
            )}

            {stepSignUp === 3 && (
              <>
                <Typography
                  color="success"
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  {t("SignUp_Complete")} <DoneOutline />
                </Typography>
                <Link to="/signIn">
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{
                      textTransform: "none",
                      minWidth: { xs: "20rem", sm: "25rem" },
                    }}
                  >
                    {t("To_Login")}
                  </Button>
                </Link>
              </>
            )}
          </Stack>
        </Box>
        {/* Footer */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="https://podosoft.netlify.app/shopitemz-privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none", whiteSpace: "nowrap" }}
            >
              {t("Terms_of_Use")}
            </Button>
          </Link>{" "}
          |
          <Link
            to="https://podosoft.netlify.app/shopitemz-privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none", whiteSpace: "nowrap" }}
            >
              {t("Privacy_Policy")}
            </Button>
          </Link>
        </Box>
      </ContainerizedBox>
    </>
  );
}
