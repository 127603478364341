import { useEffect, useRef, useState } from "react";
import { useDeleteFollowBoutiqueRequest } from "../../requestsApis/followRequest";
import { FRONT_URL, PRINCIPAL } from "../../myHelpers/constants";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  DeleteOutline,
  MoreVertOutlined,
  RemoveCircleOutline,
  ShareOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import { FaRegTrashCan } from "react-icons/fa6";
import { RWebShare } from "react-web-share";
import { useTranslation } from "react-i18next";

const AFollowingStore = ({ boutique, handleDeletedFollowing }) => {
  const navigate = useNavigate();

  // menu vars
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // delete following (Un Follow) boutique vars
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  // share following boutique vars
  const shareRef = useRef(null);
  const { t } = useTranslation();

  // mutation delete following (Un Follow) boutique
  const mutationDeleteFollowing = useDeleteFollowBoutiqueRequest(
    boutique.id,
    PRINCIPAL?.id || 0
  );

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // delete Follow boutique logic
  const handleOpenDialogDeleteFollow = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDeleteFollow = () => {
    setOpenDialogDelete(false);
  };

  const handleDeleteFollow = () => {
    handleCloseDialogDeleteFollow();
    mutationDeleteFollowing.mutate();
  };

  // share programmatic
  const handleProgrammaticShare = () => {
    shareRef.current?.click();
  };

  // View Follow Boutique
  const handleViewFollowBoutique = (event) => {
    if (event.target === event.currentTarget) {
      navigateToViewFollowBoutique();
    } else {
    }
  };

  // navigate to View Follow Boutique after Paper Clicked, or Store, Name, CreatedAt Clicked
  const navigateToViewFollowBoutique = () => {
    navigate(`/ViewBoutique/${boutique.id}`);
  };

  // delete effect
  useEffect(() => {
    if (mutationDeleteFollowing.isError) {
    }

    if (mutationDeleteFollowing.isSuccess) {
      handleDeletedFollowing(boutique);
    }
  }, [mutationDeleteFollowing.isPending]);

  return (
    <Paper
      variant="outlined"
      onClick={handleViewFollowBoutique}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
        marginBottom: 2,
        borderRadius: 0,
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
    >
      <Box
        onClick={navigateToViewFollowBoutique}
        sx={{
          textAlign: "center",
          width: "25%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            cursor: "default",
            width: 40,
            height: 40,
            border: "1px solid gray",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {boutique.baniere && boutique.baniere.length > 20 ? (
            <Box
              component="img"
              src={boutique.baniere}
              alt="Boutique img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <StoreOutlined
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </Box>
      </Box>

      <Typography
        onClick={navigateToViewFollowBoutique}
        sx={{
          textAlign: "center",
          width: "50%",
          cursor: "default",
          fontSize: 15,
        }}
      >
        {boutique.name}
      </Typography>

      {/* Actions Buttons */}
      <Box
        sx={{
          textAlign: "center",
          width: "25%",
          display: "flex",
          gap: 1,
          justifyContent: "center",
        }}
      >
        {mutationDeleteFollowing.isPending ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
              <IconButton
                color="error"
                onClick={handleOpenDialogDeleteFollow}
                sx={{
                  backgroundColor: "pink",
                  borderRadius: 2,
                }}
              >
                <FaRegTrashCan fontSize="medium" />
              </IconButton>
              <RWebShare
                data={{
                  text: boutique.name,
                  url: `${FRONT_URL}/boutique/view/boutique?boutiqueId=${boutique.id}&personId=0`,
                  title: `Share (${boutique.name}) "${FRONT_URL}/boutique/view/boutique?boutiqueId=${boutique.id}&personId=0"`,
                }}
              >
                <IconButton color="inherit" ref={shareRef}>
                  <ShareOutlined fontSize="small" />
                </IconButton>
              </RWebShare>
            </Box>
            <IconButton
              color="inherit"
              onClick={(event) => handleOpenMenu(event)}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <MoreVertOutlined fontSize="small" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={(e) => {
                  handleCloseMenu(e);
                  handleProgrammaticShare();
                }}
              >
                <ShareOutlined />
                {t("to_Share")}
              </MenuItem>
              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={(e) => {
                  handleCloseMenu(e);
                  handleOpenDialogDeleteFollow();
                }}
              >
                <RemoveCircleOutline />
                {t("UnFollow")}
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>

      {/* Dialog Delete Follow Boutique ? */}
      <DialogDeleteBoutiqueCmp
        openDialogDelete={openDialogDelete}
        handleCloseDialogDeleteFollow={handleCloseDialogDeleteFollow}
        boutique={boutique}
        handleDeleteFollow={handleDeleteFollow}
      />
    </Paper>
  );
};
export default AFollowingStore;

// Cmp Dialog Delete Boutique
const DialogDeleteBoutiqueCmp = ({
  openDialogDelete,
  handleCloseDialogDeleteFollow,
  boutique,
  handleDeleteFollow,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={openDialogDelete}
      onClose={handleCloseDialogDeleteFollow}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" />
        <span>
          {t("UnFollow")}{" "}
          <i>
            <u>"{boutique.name}"</u>
          </i>{" "}
          ?
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}>
          {t("You_are_about_to_delete")}{" "}
          <i>
            <u>"{boutique.name}"</u>
          </i>{" "}
          {t("from_your_following_list")}
          <br /> {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialogDeleteFollow}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteFollow}
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("to_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
