import { Box } from "@mui/material";
import ADossier from "../ui/ADossier";

const MyDossiersCmp = ({ dossierList, setDossierList }) => {
  // make sure that every dossier is rendered once only
  const uniqueDossiers = dossierList.filter(
    (store, index, self) => index === self.findIndex((s) => s.id === store.id)
  );

  // replace Edited Dossier with new one
  const handleReplaceAfterEditDossier = (newDossier) => {
    const newDossierList = dossierList.map((dossier) => {
      if (newDossier.id === dossier.id) {
        return newDossier;
      } else {
        return dossier;
      }
    });

    setDossierList(newDossierList);
  };

  // remove deleted dossier from list
  const handleDeletedDossier = (deletedDossier) => {
    setDossierList((prevList) =>
      prevList.filter((row) => row.id !== deletedDossier.id)
    );
  };

  return (
    <Box>
      {uniqueDossiers.map((dossier) => (
        <ADossier
          dossier={dossier}
          key={dossier.id}
          handleReplaceAfterEditDossier={handleReplaceAfterEditDossier}
          handleDeletedDossier={handleDeletedDossier}
        />
      ))}
    </Box>
  );
};
export default MyDossiersCmp;
