import { Stack } from "@mui/material";
import PostsWrapperCmp from "./PostsWrapperCmp";
import NewsWrapper from "./NewsWrapper";
import { useEffect, useRef, useState } from "react";
import { useGetPostsRequest } from "../../requestsApis/postsRequests";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";

const PostsPage = ({ navTo, setNavTo }) => {
  const [sortSelected, setSortSelected] = useState("recent");
  let pageNumPost = useRef(0);
  let pageSizePost = useRef(10);
  let postsIds = useRef([0]);
  let isFinishedPost = useRef(false);
  const [postsList, setPostsList] = useState([]);
  let enabledFetchPosts = useRef(true);

  const handleChangeSort = (event, newSelected) => {
    if (
      newSelected !== null &&
      !queryGetPosts.isLoading &&
      !queryGetPosts.isPending
    ) {
      enabledFetchPosts.current = false;
      pageNumPost.current = 0;
      postsIds.current = [0];
      isFinishedPost.current = false;
      setPostsList([]);
      enabledFetchPosts.current = true;
      setSortSelected(newSelected);
    }
  };

  const queryGetPosts = useGetPostsRequest(
    sortSelected,
    pageNumPost.current,
    pageSizePost.current,
    postsIds.current,
    enabledFetchPosts.current
  );

  const loadMoreProducts = () => {
    if (!isFinishedPost.current) {
      if (!queryGetPosts.isLoading) {
        if (sortSelected === "random") {
          let newIds = postsList.map((post) => post.id);
          postsIds.current = newIds;
        } else {
          pageNumPost.current++;
          queryGetPosts.refetch();
        }
      }
    }
  };

  useEffect(() => {
    setNavTo(1);
  }, []);

  useEffect(() => {
    if (queryGetPosts.isSuccess && queryGetPosts.data) {
      setPostsList((prevPosts) => [...prevPosts, ...queryGetPosts.data.data]);
      if (queryGetPosts.data.data.length < pageSizePost.current) {
        isFinishedPost.current = true;
      }
    }
  }, [queryGetPosts.isPending]);

  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />
      <Stack mt={3} direction="row" spacing={4} justifyContent="space-between">
        <PostsWrapperCmp
          sortSelected={sortSelected}
          handleChangeSort={handleChangeSort}
          posts={postsList}
          setPostsList={setPostsList}
          isFinishedPost={isFinishedPost}
          queryGetPosts={queryGetPosts}
          loadMoreProducts={loadMoreProducts}
        />
        <NewsWrapper />
      </Stack>
    </ContainerizedBox>
  );
};

export default PostsPage;
