import { Box, Card, CardMedia, Grid } from "@mui/material";

export const AProductImage = ({
  productImage,
  selectImageToView,
  selectedImg,
}) => {
  return (
    <Grid
      item
      key={productImage.id}
      sx={{ cursor: "pointer" }}
      onClick={() => selectImageToView(productImage.image)}
    >
      <Box
        sx={{
          display: selectedImg === productImage.image ? "block" : "none",
          bgcolor: "secondary.main",
          height: 2,
        }}
      ></Box>

      <Card elevation={0} sx={{ maxWidth: 50, marginTop: 1 }}>
        <CardMedia
          sx={{
            height: 50,
            width: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Placeholder background color
          }}
        >
          <Box
            component="img"
            src={productImage.image}
            alt="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </CardMedia>
      </Card>
    </Grid>
  );
};
