import { useMutation } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/cart";

// Delete Cart From Dossier Request
export const useDeleteCartFromDossierRequest = (productId, dossierId) => {
  const queryParams = {
    productId: productId,
    dossierId: dossierId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/deleteCartFromDoss?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Add Cart Request
export const useAddCartRequest = () => {
  return useMutation({
    mutationFn: async (cart) => {
      const fullUrl = `${BACK_URL}${basePath}/saveCart`;

      return await axios.post(fullUrl, cart, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};
