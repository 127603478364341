import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetMyBoutiqueRequest } from "../../requestsApis/boutiqueRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { MyStoresCmp } from "../../components/largeComponents/MyStoresCmp";
import { CreateStoreCmp } from "../../components/largeComponents/CreateStoreCmp";
import { LoadMoreBtnCmp } from "../../components/ui/LoadMoreBtnCmp";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const MyStoresPage = ({ setSelectedTab }) => {
  const [myStoresList, setMyStoresList] = useState([]);
  let pageNum = useRef(0);
  let pageSize = useRef(10);
  let isFinished = useRef(false);

  const queryGetMyStores = useGetMyBoutiqueRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current
  );

  const loadMore = () => {
    if (!isFinished.current && !queryGetMyStores.isLoading) {
      pageNum.current += 1;
      queryGetMyStores.refetch();
    }
  };

  // ********************     Use Effects
  useEffect(() => {
    setSelectedTab(0);
  }, []);

  useEffect(() => {
    if (queryGetMyStores.isError) {
      console.log(queryGetMyStores.error);
    }

    if (queryGetMyStores.isSuccess && queryGetMyStores.data) {
      setMyStoresList((prevList) => [
        ...prevList,
        ...queryGetMyStores.data.data,
      ]);

      if (queryGetMyStores.data.data.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetMyStores.isPending]);

  return (
    <Box>
      <DialogLoginExpiredComponent />
      <CreateStoreCmp setMyStoresList={setMyStoresList} pageSize={pageSize} />

      <TableauHeaderCmp />
      <MyStoresCmp
        storesList={myStoresList}
        setMyStoresList={setMyStoresList}
      />

      <LoadMoreBtnCmp
        isFinished={isFinished}
        query={queryGetMyStores}
        loadMore={loadMore}
      />
    </Box>
  );
};
export default MyStoresPage;

// Tableau Header
const TableauHeaderCmp = () => {
  const { t } = useTranslation();

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
        backgroundColor: "#e0e0e0",
        borderRadius: 0,
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          width: { xs: "25%", md: "15%" },
        }}
      >
        {t("My_Store")}
      </Typography>

      <Typography sx={{ textAlign: "center", width: { xs: "50%", md: "40%" } }}>
        Store Name
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          width: "30%",
          display: { xs: "none", md: "inline-block" },
        }}
      >
        {t("Date")}
      </Typography>

      <Typography sx={{ textAlign: "center", width: { xs: "25%", md: "15%" } }}>
        {t("Actions")}
      </Typography>
    </Paper>
  );
};
