import {
  Box,
  Button,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { CustomSnackbar } from "../../components/ui/CustomSnackbar";
import APost from "../../components/ui/APost";
import PostInput from "./PostInput";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useTranslation } from "react-i18next";

const PostsWrapperCmp = ({
  sortSelected,
  handleChangeSort,
  posts,
  setPostsList,
  isFinishedPost,
  queryGetPosts,
  loadMoreProducts,
}) => {
  // make sure that every post is rendered once only
  const uniquePosts = posts.filter(
    (post, index, self) => index === self.findIndex((p) => p.id === post.id)
  );

  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  const { t } = useTranslation();

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  return (
    <Box flex={4}>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="p" component="p">
          {t("Sorts")} :
        </Typography>

        <ToggleButtonGroup
          color="primary"
          value={sortSelected}
          exclusive
          onChange={handleChangeSort}
          aria-label="Platform"
          size="small"
        >
          <ToggleButton value="recent" sx={{ textTransform: "none" }}>
            {t("Recent")}
          </ToggleButton>
          <ToggleButton value="old" sx={{ textTransform: "none" }}>
            {t("Old")}
          </ToggleButton>
          <ToggleButton value="random" sx={{ textTransform: "none" }}>
            {t("Random")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {/* Add Post */}
      <PostInput setPostsList={setPostsList} />

      {uniquePosts.map((post, index) => (
        <APost
          post={post}
          setPostsList={setPostsList}
          key={post.id}
          showSnackBarMessage={showSnackBarMessage}
          handleDialogLoginOpen={handleDialogLoginOpen}
        />
      ))}

      <Box
        sx={{
          margin: 4,
          display: isFinishedPost.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryGetPosts.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMoreProducts}
            sx={{ whiteSpace: "nowrap", textTransform: "none" }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>

      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <DialogLoginComponent />
    </Box>
  );
};

export default PostsWrapperCmp;
