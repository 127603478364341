import {
  ContactPhone,
  ContentCopyRounded,
  Favorite,
  FavoriteBorderOutlined,
  LocationOnOutlined,
  PersonOutline,
  PhoneOutlined,
  ShareOutlined,
  StoreOutlined,
  WhatsApp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetProductImagesRequest } from "../../requestsApis/productImagesRequests";
import { useGetProductVideosRequest } from "../../requestsApis/productVideosRequest";
import { useViewBoutiqueRequest } from "../../requestsApis/boutiqueRequest";
import { useViewProductRequest } from "../../requestsApis/productsRequests";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { AProductVideo } from "./AProductVideo";
import { AProductImage } from "./AProductImage";
import ModalViewProductVideo from "./ModalViewProductVideo";
import { ProductsOfSameStore } from "./ProductsSameStore";
import { RelatedProducts } from "./RelatedProducts";
import copy from "copy-to-clipboard";
import { CustomSnackbar } from "../../components/ui/CustomSnackbar";
import ModalAddOrderCmp from "../../components/largeComponents/ModalAddOrderCmp";
import { FRONT_URL, PRINCIPAL } from "../../myHelpers/constants";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useModalViewImage } from "../../hooks/useModalViewImage";
import { RWebShare } from "react-web-share";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { ModalEmptyDossiers } from "../../components/largeComponents/ModalEmptyDossiers";
import { useAddCartRequest } from "../../requestsApis/cartRequest";
import { useTranslation } from "react-i18next";

const ViewProductPage = () => {
  const { productIdParam } = useParams();
  const productId = Number(productIdParam);

  const [productToView, setProductToView] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [productImages, setProductImages] = useState([]);
  let pageNumImages = useRef(0);
  let pageSizeImages = useRef(10);
  const [productVideos, setProductVideos] = useState([]);
  let pageNumVideos = useRef(0);
  let pageSizeVideos = useRef(10);
  const [boutique, setBoutique] = useState(null);
  const descriptionLimit = 400;
  const [productDescription, setProductDescription] = useState("");
  const [seeMoreProductDescription, setSeeMoreProductDescription] =
    useState(false);
  const [selectedProductVideo, setSelectedProductVideo] = useState(null);

  const [openModalViewProductVideo, setOpenModalViewProductVideo] =
    useState(false);
  const handleOpenModalViewProductVideo = (productVideo) => {
    setSelectedProductVideo(productVideo);
    setOpenModalViewProductVideo(true);
  };
  const handleCloseModalViewProductVideo = () =>
    setOpenModalViewProductVideo(false);

  const navigate = useNavigate();

  const goToViewBoutique = (boutiqueId) => {
    navigate(`/ViewBoutique/${boutiqueId}`);
  };

  const [openContactSeller, setOpenContactSeller] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  const [openModalEmptyDossiers, setOpenModalEmptyDossiers] = useState(false);

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  const {
    ModalViewImageFullSizeCmp,
    handleModalViewImageFullSizeOpen,
    setImageToViewFullSizeLink,
  } = useModalViewImage();

  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  const { t } = useTranslation();

  //*** Queries */
  // query View Product
  const queryViewProduct = useViewProductRequest(productId);
  // query Images
  const queryViewProductImages = useGetProductImagesRequest(
    pageNumImages.current,
    pageSizeImages.current,
    productId
  );

  // query Videos
  const queryViewProductVideos = useGetProductVideosRequest(
    pageNumVideos.current,
    pageSizeVideos.current,
    productId
  );

  // query View Boutique
  const queryViewBoutique = useViewBoutiqueRequest(
    productToView?.boutiqueId || 0,
    PRINCIPAL?.id || 0
  );

  const mutationSaveCart = useAddCartRequest();

  const selectImageToView = (imageUrl) => {
    setSelectedImg(imageUrl);
  };

  const handleSeeMore = () => {
    if (productToView.description.length > descriptionLimit) {
      if (!seeMoreProductDescription) {
        setProductDescription(productToView.description);
        setSeeMoreProductDescription(true);
      } else {
        setProductDescription(
          `${productToView.description.slice(0, descriptionLimit)} ...${t(
            "see_more"
          )} ↓`
        );
        setSeeMoreProductDescription(false);
      }
    }
  };

  const handleClickedContactSeller = () => {
    setOpenContactSeller(!openContactSeller);
  };

  const viewProductImageFullSize = () => {
    setImageToViewFullSizeLink(selectedImg);
    handleModalViewImageFullSizeOpen();
  };

  const handleOpenEmptyDossiers = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      setOpenModalEmptyDossiers(true);
    }
  };

  const handleCloseEmptyDossiers = () => {
    setOpenModalEmptyDossiers(false);
  };

  // add favorite
  const handleSaveCart = (dossierId) => {
    handleCloseEmptyDossiers();
    const cart = {
      personId: PRINCIPAL?.id || 0,
      productId: productToView?.id || 0,
      dossierCartId: dossierId || 0,
    };
    mutationSaveCart.mutate(cart);
  };

  //  *****   Effects
  useEffect(() => {
    if (productToView !== null && productToView.description !== null) {
      if (productToView.description.length > descriptionLimit) {
        setProductDescription(
          `${productToView.description.slice(0, descriptionLimit)} ...${t(
            "see_more"
          )} ↓`
        );
      } else {
        setProductDescription(productToView.description);
      }
    }
  }, [productToView]);

  useEffect(() => {
    setSelectedImg(productToView?.image);
  }, [productToView]);

  useEffect(() => {
    if (queryViewProductImages.isSuccess && queryViewProductImages.data) {
      setProductImages(queryViewProductImages.data.data);
    }
  }, [queryViewProductImages.isPending]);

  useEffect(() => {
    if (queryViewProductVideos.isSuccess && queryViewProductVideos.data) {
      setProductVideos(queryViewProductVideos.data.data);
    }
  }, [queryViewProductVideos.isPending]);

  useEffect(() => {
    if (queryViewBoutique.isSuccess && queryViewBoutique.data) {
      setBoutique(queryViewBoutique.data.data);
    }
  }, [queryViewBoutique.isPending]);

  useEffect(() => {
    if (queryViewProduct.isSuccess && queryViewProduct.data) {
      setProductToView(queryViewProduct.data.data.product);
    }
  }, [queryViewProduct.isPending]);

  useEffect(() => {
    if (mutationSaveCart.isError) {
      if (mutationSaveCart.error.response.status === 302) {
        showSnackBarMessage(
          t("Product_already_exits_in_that_catalog"),
          "warning"
        );
      } else {
        console.log(mutationSaveCart.error);
        showSnackBarMessage(t("Error_occured_3_dots"), "error");
      }
    }

    if (mutationSaveCart.isSuccess) {
      showSnackBarMessage(t("Added_product_to_Favorites"), "success");
    }
  }, [mutationSaveCart.isPending]);

  return (
    <ContainerizedBox>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            marginY: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {t("Products")} &gt;{" "}
          <Link
            to={`/ViewCategoryProducts/${productToView?.categoryId}`}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Box
              component="span"
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                  color: "secondary.main",
                },
              }}
            >
              {productToView?.category}
            </Box>
          </Link>{" "}
          &gt;
          <Box component="span" sx={{ color: "secondary.main", marginLeft: 1 }}>
            {productToView?.name}
          </Box>
        </Typography>

        {/* Start Icon */}
        {productToView && (
          <Box
            className="icon-buttons"
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <IconButton
              onClick={(e) => {
                handleOpenEmptyDossiers();
              }}
              size="small"
              aria-label="add to favorites"
            >
              <Checkbox
                size="small"
                icon={<FavoriteBorderOutlined sx={{ color: "black" }} />}
                checkedIcon={<Favorite sx={{ color: "red" }} />}
              />
            </IconButton>

            <RWebShare
              data={{
                text: "Product",
                url: `${FRONT_URL}/viewProduct/${productToView.id}`,
                title: `${t("Share_Product")} "${FRONT_URL}/viewProduct/${
                  productToView.id
                }"`,
              }}
            >
              <IconButton color="inherit" size="small" aria-label="share">
                <Checkbox
                  size="small"
                  icon={<ShareOutlined sx={{ color: "black" }} />}
                  checkedIcon={<ShareOutlined sx={{ color: "black" }} />}
                />
              </IconButton>
            </RWebShare>
          </Box>
        )}
        {/* End Icons */}
      </Box>

      <Divider sx={{ marginBottom: 4 }} />

      {queryViewProduct.isPending && (
        <Box sx={{ margin: 4, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {productToView && (
        <Box>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                onClick={viewProductImageFullSize}
                component="img"
                src={selectedImg}
                alt={productToView.name}
                sx={{
                  width: "100%",
                  height: 450,
                  objectFit: "contain",
                  borderRadius: 2,
                  boxShadow: 3,
                  cursor: "pointer",
                }}
              />

              <Box
                marginTop={2}
                sx={{
                  width: "100%",
                }}
              >
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{
                    overflow: "auto",
                    paddingBottom: 1,
                  }}
                >
                  {productImages.map((productImage) => (
                    <AProductImage
                      productImage={productImage}
                      selectImageToView={selectImageToView}
                      selectedImg={selectedImg}
                      key={productImage.id}
                    />
                  ))}
                </Stack>

                {productVideos.length > 0 ? (
                  <>
                    <Typography>{t("Product_Videos")}</Typography>
                    <Stack
                      direction="row"
                      spacing={3}
                      sx={{
                        overflow: "auto",
                        paddingBottom: 1,
                      }}
                    >
                      {productVideos.map((productVideo) => (
                        <AProductVideo
                          productVideo={productVideo}
                          handleOpenModalViewProductVideo={
                            handleOpenModalViewProductVideo
                          }
                          key={productVideo.id}
                        />
                      ))}
                    </Stack>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Link
                to={`/ViewCategoryProducts/${productToView?.categoryId}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      color: "secondary.main",
                    },
                  }}
                >
                  {productToView.category}
                </Typography>
              </Link>

              <Typography variant="h6" fontWeight="bold">
                {productToView.priceCode} {productToView.price}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                {productToView.name}
              </Typography>

              <Box
                sx={{
                  overflow: "auto",
                  marginBottom: 4,
                  minHeight: { xs: "inherit", md: 138 },
                  maxHeight: { xs: "inherit", md: 200 },
                }}
              >
                <Typography onClick={handleSeeMore} variant="body2">
                  {productDescription}
                </Typography>
              </Box>

              <Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" component="span">
                    {t("Available")}
                  </Typography>
                  <Typography variant="h6" component="span">
                    {productToView.available ? "Yes" : "No"}
                  </Typography>
                </Box>

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" component="span">
                    {t("Quantity")}
                  </Typography>
                  <Typography variant="h6" component="span">
                    {productToView.quantity}
                  </Typography>
                </Box>

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" component="span">
                    {t("Category")}
                  </Typography>
                  <Link
                    to={`/ViewCategoryProducts/${productToView?.categoryId}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                          color: "secondary.main",
                        },
                      }}
                    >
                      {productToView.category}
                    </Typography>
                  </Link>
                </Box>

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" component="span">
                    {t("Posted_on")}
                  </Typography>
                  <Typography variant="h6" component="span">
                    {myFormatDate(productToView.createdAt)}
                  </Typography>
                </Box>
                <Divider />
              </Box>

              {/* Contact Seller */}

              {openContactSeller && boutique && (
                <ContactSellerCmp
                  boutique={boutique}
                  showSnackBarMessage={showSnackBarMessage}
                />
              )}

              {/* Contact and Place Order */}
              <Box display="flex" gap={2} mt={4}>
                <Button
                  onClick={handleClickedContactSeller}
                  variant="outlined"
                  color="secondary"
                  sx={{ width: "50%", textTransform: "none" }}
                  startIcon={<ContactPhone />}
                  size="small"
                >
                  {t("Contact_Seller")}
                </Button>
                <ModalAddOrderCmp
                  product={productToView}
                  boutique={boutique}
                  showSnackBarMessage={showSnackBarMessage}
                />
              </Box>
            </Grid>
          </Grid>

          <Typography mt={2} variant="h6">
            {t("Published_by")} :
          </Typography>
          {boutique && (
            <Link
              to={`/ViewBoutique/${boutique.id}`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: { xs: 1, md: 4 },
                  marginBottom: 2,
                  "&:hover": {
                    backgroundColor: "whitesmoke",
                  },
                }}
              >
                <Card sx={{ maxWidth: 100 }}>
                  {/* Card Media with a Placeholder if Image is Not Available */}
                  <CardMedia
                    sx={{
                      height: 100,
                      width: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f0f0f0", // Placeholder background color
                    }}
                  >
                    {boutique?.baniere && boutique.baniere !== "baniere" ? (
                      <Box
                        component="img"
                        src={boutique.baniere}
                        alt={boutique.name}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <StoreOutlined sx={{ fontSize: 70 }} /> // Placeholder Icon
                    )}
                  </CardMedia>
                </Card>

                <Box>
                  <Typography
                    variant="h6"
                    fontSize={17}
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <StoreOutlined />
                    {boutique.name}
                  </Typography>

                  <Typography
                    variant="h6"
                    fontSize={17}
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PersonOutline />
                    {boutique.person?.firstname} {boutique.person?.lastname}
                  </Typography>

                  <Typography
                    variant="h6"
                    fontSize={17}
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocationOnOutlined />
                    {boutique.secondAddress?.addressLines
                      ? boutique.secondAddress?.addressLines
                      : t("Business_Address")}
                  </Typography>
                </Box>
              </Card>
            </Link>
          )}
          <ProductsOfSameStore boutiqueId={productToView.boutiqueId} />

          <Divider sx={{ mt: 2 }} />
          <Typography mt={4} mb={2} variant="h6">
            {t("Related_Products")}
          </Typography>
          <RelatedProducts categoryId={productToView.categoryId} />
        </Box>
      )}

      <ModalViewProductVideo
        open={openModalViewProductVideo}
        handleOpen={handleOpenModalViewProductVideo}
        handleClose={handleCloseModalViewProductVideo}
        selectedProductVideo={selectedProductVideo}
      />
      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <DialogLoginComponent />
      <DialogLoginExpiredComponent />
      <ModalViewImageFullSizeCmp />

      {openModalEmptyDossiers && (
        <ModalEmptyDossiers
          product={productToView}
          open={openModalEmptyDossiers}
          handleClose={handleCloseEmptyDossiers}
          handleSaveCart={handleSaveCart}
          showSnackBarMessage={showSnackBarMessage}
        />
      )}
    </ContainerizedBox>
  );
};
export default ViewProductPage;

const ContactSellerCmp = ({ boutique, showSnackBarMessage }) => {
  const { t } = useTranslation();

  const handleCopyToClipboard = (text) => {
    copy(text);
    showSnackBarMessage(t("Copied_exclamative"), "success");
  };
  return (
    <Box mt={4}>
      {boutique.person?.phoneNumber && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 2,
          }}
        >
          <PhoneOutlined fontSize="small" />
          <Typography sx={{ wordBreak: "break-all" }}>
            {boutique.person.phoneNumber}
          </Typography>
          <IconButton
            onClick={() => handleCopyToClipboard(boutique.person.phoneNumber)}
            size="small"
            sx={{
              color: "#348781",
              backgroundColor: "#CFECEC",
              borderRadius: 2,
            }}
          >
            <ContentCopyRounded fontSize="inherit" />
          </IconButton>
        </Box>
      )}

      {boutique.person?.whatsapp && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <WhatsApp fontSize="small" color="success" />
          <Typography sx={{ wordBreak: "break-all" }}>
            {boutique.person.whatsapp}
          </Typography>
          <IconButton
            onClick={() => handleCopyToClipboard(boutique.person.whatsapp)}
            size="small"
            sx={{
              color: "#348781",
              backgroundColor: "#CFECEC",
              borderRadius: 2,
            }}
          >
            <ContentCopyRounded fontSize="inherit" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
