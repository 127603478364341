import {
  AddCircleOutline,
  AddOutlined,
  Check,
  CheckCircle,
  ContentCopyRounded,
  DeleteOutline,
  PhoneOutlined,
  ShareOutlined,
  StoreOutlined,
  Verified,
  WarningOutlined,
  WhatsApp,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { RiWhatsappFill, RiWhatsappLine } from "react-icons/ri";
import {
  useDeleteFollowBoutiqueRequest,
  useFollowBoutiqueRequest,
} from "../../requestsApis/followRequest";
import { useEffect, useState } from "react";
import { FRONT_URL, PRINCIPAL } from "../../myHelpers/constants";
import { RWebShare } from "react-web-share";
import { IoCameraOutline } from "react-icons/io5";
import { DialogChangeBoutiqueBaniere } from "../../components/largeComponents/DialogChangeBoutiqueBaniere";
import { CustomSnackbar } from "../../components/ui/CustomSnackbar";
import { useDeleteBoutiqueBaniereRequest } from "../../requestsApis/boutiqueRequest";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useModalViewImage } from "../../hooks/useModalViewImage";
import { useViewAddressOfBoutiqueRequest } from "../../requestsApis/myAddressRequest";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";

const HeaderViewBoutique = ({
  boutiqueToView,
  setBoutiqueToView,
  urlIsMyBoutique,
}) => {
  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  const [anchorElMenuBaniere, setAnchorElMenuBaniere] = useState(null);
  const openMenuBaniere = Boolean(anchorElMenuBaniere);

  const [openDialogChangeBoutiqueBaniere, setOpenDialogChangeBoutiqueBaniere] =
    useState(false);

  const { t } = useTranslation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  const [openDialogDeleteBaniere, setOpenDialogDeleteBaniere] = useState(false);

  const [boutiqueAddress, setBoutiqueAddress] = useState("");
  const [typeViewBoutiqueAddress, setTypeViewBoutiqueAddress] = useState("");
  const [idOfBoutiqueToViewAddress, setIdOfBoutiqueToViewAddress] = useState(0);

  const [openContactSeller, setOpenContactSeller] = useState(false);

  const {
    ModalViewImageFullSizeCmp,
    handleModalViewImageFullSizeOpen,
    setImageToViewFullSizeLink,
  } = useModalViewImage();

  // mut follow
  const mutationFollowBoutique = useFollowBoutiqueRequest();

  // mut unfollow
  const mutationUnFollowBoutiqe = useDeleteFollowBoutiqueRequest(
    boutiqueToView?.id || 0,
    PRINCIPAL?.id || 0
  );

  // mut delete Baniere
  const mutationDeleteBoutiqueBaniere = useDeleteBoutiqueBaniereRequest(
    boutiqueToView.id || 0
  );

  const queryViewBoutiqueAddress = useViewAddressOfBoutiqueRequest(
    idOfBoutiqueToViewAddress,
    typeViewBoutiqueAddress
  );

  // menu logic
  const handleClickCameraMenuBaniere = (event) => {
    setAnchorElMenuBaniere(event.currentTarget);
  };
  const handleCloseMenuBaniere = () => {
    setAnchorElMenuBaniere(null);
  };

  // follow UnFollow
  const handleFollowBoutique = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      const followBoutique = {
        boutiqueId: boutiqueToView?.id || 0,
        followerId: PRINCIPAL?.id || 0,
      };

      mutationFollowBoutique.mutate(followBoutique);
    }
  };

  const handleUnFollowBoutique = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      mutationUnFollowBoutiqe.mutate();
    }
  };

  // Dialog Change Baniere logic
  const handleOpenDialogChangeBoutiqueBaniere = () => {
    setOpenDialogChangeBoutiqueBaniere(true);
  };

  const handleCloseDialogChangeBoutiqueBaniere = () => {
    setOpenDialogChangeBoutiqueBaniere(false);
  };

  // Dialog Delete Baniere
  const handleOpenDialogDeleteBaniere = () => {
    setOpenDialogDeleteBaniere(true);
  };

  const handleCloseDialogDeleteBaniere = () => {
    setOpenDialogDeleteBaniere(false);
  };

  const handleDeleteBaniere = () => {
    handleCloseDialogDeleteBaniere();
    setMessageSnackbar(t("Deleting_picture_3_dots"));
    setSeveritySnackbar("secondary");
    setOpenSnackbar(true);
    mutationDeleteBoutiqueBaniere.mutate();
  };

  const viewImageFullSize = () => {
    setImageToViewFullSizeLink(boutiqueToView?.baniere || "");
    handleModalViewImageFullSizeOpen();
  };

  const viewBoutiqueAddress = (type) => {
    setIdOfBoutiqueToViewAddress(boutiqueToView?.id || 0);
    setTypeViewBoutiqueAddress(type);
  };

  const handleClickedContactSeller = () => {
    setOpenContactSeller(!openContactSeller);
  };

  //  Effects
  useEffect(() => {
    if (mutationFollowBoutique.isError) {
      console.log(mutationFollowBoutique.error);
    }

    if (mutationFollowBoutique.isSuccess) {
      setBoutiqueToView({ ...boutiqueToView, following: true });
      setMessageSnackbar(t("Following_Store"));
      setSeveritySnackbar("secondary");
      setOpenSnackbar(true);
    }
  }, [mutationFollowBoutique.isPending]);

  useEffect(() => {
    if (mutationUnFollowBoutiqe.isError) {
      console.log(mutationUnFollowBoutiqe.error);
    }

    if (mutationUnFollowBoutiqe.isSuccess) {
      setBoutiqueToView({ ...boutiqueToView, following: false });
    }
  }, [mutationUnFollowBoutiqe.isPending]);

  useEffect(() => {
    if (mutationDeleteBoutiqueBaniere.isError) {
      setMessageSnackbar(t("Error_deleting_Store_picture"));
      setSeveritySnackbar("error");
      setOpenSnackbar(true);
    }

    if (mutationDeleteBoutiqueBaniere.isSuccess) {
      setMessageSnackbar(t("Deleted_Store_picture_successfully"));
      setSeveritySnackbar("success");
      setOpenSnackbar(true);

      const boutiqueResponse = mutationDeleteBoutiqueBaniere.data.data;
      if (boutiqueResponse) {
        setBoutiqueToView({
          ...boutiqueToView,
          baniere: boutiqueResponse.baniere,
        });
      }
    }
  }, [mutationDeleteBoutiqueBaniere.isPending]);

  useEffect(() => {
    if (boutiqueToView?.secondAddress === null) {
      if (boutiqueToView?.secondAddressId == null) {
        if (!urlIsMyBoutique) {
          if (boutiqueToView.person?.secondAddress) {
            setBoutiqueAddress(boutiqueToView.person.secondAddress);
          } else {
            viewBoutiqueAddress("boutiqueOrPerson");
          }
        }
      } else {
        // if owner
        if (urlIsMyBoutique) {
          viewBoutiqueAddress("boutique");
        } else {
          viewBoutiqueAddress("boutiqueOrPerson");
        }
      }
    } else {
      setBoutiqueAddress(boutiqueToView.secondAddress);
    }
  }, [boutiqueToView]);

  useEffect(() => {
    if (queryViewBoutiqueAddress.isError) {
      console.log(queryViewBoutiqueAddress.error);
    }

    if (queryViewBoutiqueAddress.isSuccess && queryViewBoutiqueAddress.data) {
      setBoutiqueAddress(queryViewBoutiqueAddress.data.data);
    }
  }, [queryViewBoutiqueAddress.isPending]);

  return (
    <>
      <DialogLoginComponent />
      <ModalViewImageFullSizeCmp />
      {boutiqueToView && (
        <Box mb={1}>
          <Typography
            variant="body2"
            sx={{ marginY: 1, display: "flex", alignItems: "center" }}
          >
            <StoreOutlined /> {t("Store")} &gt;
            <Box
              component="span"
              sx={{ color: "secondary.main", marginLeft: 1 }}
            >
              {boutiqueToView.name}
            </Box>
          </Typography>

          <Box
            sx={{
              height: { xs: 150, md: 200 },
              border: "2px solid black",
              borderRadius: "10px",
              backgroundColor: "silver",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {boutiqueToView?.baniere && boutiqueToView.baniere !== "baniere" ? (
              <Box
                onClick={viewImageFullSize}
                sx={{
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url("${boutiqueToView.baniere}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <StoreOutlined sx={{ fontSize: 200 }} /> // Placeholder Icon
            )}
            {urlIsMyBoutique && (
              <>
                <IconButton
                  onClick={handleClickCameraMenuBaniere}
                  color="secondary"
                  sx={{ position: "absolute", right: 5, top: 5 }}
                >
                  <IoCameraOutline />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorElMenuBaniere}
                  open={openMenuBaniere}
                  onClose={handleCloseMenuBaniere}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseMenuBaniere();
                      handleOpenDialogChangeBoutiqueBaniere();
                    }}
                  >
                    {t("Change_photo")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenuBaniere();
                      handleOpenDialogDeleteBaniere();
                    }}
                  >
                    {t("Delete_photo")}
                  </MenuItem>
                </Menu>

                <DialogChangeBoutiqueBaniere
                  boutique={boutiqueToView}
                  setBoutiqueToView={setBoutiqueToView}
                  open={openDialogChangeBoutiqueBaniere}
                  handleClose={handleCloseDialogChangeBoutiqueBaniere}
                  setMessageSnackbar={setMessageSnackbar}
                  setSeveritySnackbar={setSeveritySnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                />

                <DialogDeleteBaniere
                  open={openDialogDeleteBaniere}
                  handleClose={handleCloseDialogDeleteBaniere}
                  handleDelete={handleDeleteBaniere}
                />
              </>
            )}
            <Avatar
              src={boutiqueToView.person?.profile}
              alt="Profile Image"
              sx={{
                position: "absolute",
                left: 15,
                bottom: { xs: -35, md: -40 },
                width: { xs: 50, md: 80 },
                height: { xs: 50, md: 80 },
                border: "2px solid gray",
                backgroundColor: "whitesmoke",
              }}
            />
            {/* btns on share, follow, whats */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
                position: "absolute",
                right: 5,
                // bottom: { xs: "inherit", md: 5 },
                bottom: 5,
              }}
            >
              {boutiqueToView.following ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    textTransform: "none",
                  }}
                  startIcon={<Check />}
                  onClick={handleUnFollowBoutique}
                >
                  {mutationUnFollowBoutiqe.isPending ? (
                    <CircularProgress size={20} />
                  ) : (
                    t("Following")
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    textTransform: "none",
                  }}
                  startIcon={<AddOutlined />}
                  onClick={handleFollowBoutique}
                >
                  {mutationFollowBoutique.isPending ? (
                    <CircularProgress size={20} />
                  ) : (
                    t("Follow")
                  )}
                </Button>
              )}
              <RWebShare
                data={{
                  text: boutiqueToView.name,
                  url: `${FRONT_URL}/ViewBoutique/${boutiqueToView.id}`,
                  title: `${t("to_Share")} (${
                    boutiqueToView.name
                  }) "${FRONT_URL}/ViewBoutique/${boutiqueToView.id}`,
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    textTransform: "none",
                  }}
                  startIcon={<ShareOutlined />}
                >
                  {t("to_Share")}
                </Button>
              </RWebShare>

              <Button
                onClick={handleClickedContactSeller}
                variant="contained"
                size="small"
                color="secondary"
                sx={{
                  display: { xs: "none", md: "flex" },
                  textTransform: "none",
                }}
                startIcon={<RiWhatsappFill />}
              >
                {t("Message")}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              paddingLeft: { xs: 8, md: 12 },
              marginTop: { xs: 1, md: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="column" sx={{ paddingLeft: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ wordBreak: "break-word", fontWeight: "bold" }}
                >
                  {boutiqueToView.person.firstname}{" "}
                  {boutiqueToView.person.lastname}
                </Typography>
                {boutiqueToView.person.accountVerified && (
                  <Verified
                    sx={{ color: "#306EFF", marginLeft: 1 }}
                    fontSize="small"
                  />
                )}
              </Box>
              <Typography variant="body2" color="textSecondary" fontSize={13}>
                @
                {boutiqueToView.person.accountVerified
                  ? t("Business_account")
                  : t("Personal_Account")}
              </Typography>
            </Stack>
          </Box>

          {/* btns on mobile */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {boutiqueToView.following ? (
              <IconButton onClick={handleUnFollowBoutique} size="large">
                {mutationUnFollowBoutiqe.isPending ? (
                  <CircularProgress size={20} />
                ) : (
                  <CheckCircle color="secondary" />
                )}
              </IconButton>
            ) : (
              <Button
                onClick={handleFollowBoutique}
                size="small"
                color="inherit"
                startIcon={<AddCircleOutline />}
                sx={{
                  textTransform: "none",
                  padding: 0,
                }}
              >
                {mutationFollowBoutique.isPending ? (
                  <CircularProgress size={20} />
                ) : (
                  t("Follow")
                )}
              </Button>
            )}

            <RWebShare
              data={{
                text: boutiqueToView.name,
                url: `${FRONT_URL}/ViewBoutique/${boutiqueToView.id}`,
                title: `${t("to_Share")} (${
                  boutiqueToView.name
                }) "${FRONT_URL}/ViewBoutique/${boutiqueToView.id}`,
              }}
            >
              <IconButton
                size="large"
                sx={{
                  textTransform: "none",
                  color: "inherit",
                }}
              >
                <ShareOutlined />
              </IconButton>
            </RWebShare>

            <IconButton
              onClick={handleClickedContactSeller}
              size="large"
              sx={{
                textTransform: "none",
                color: "success.main",
              }}
            >
              <RiWhatsappLine />
            </IconButton>
          </Box>

          {/* Contact Seller */}

          {openContactSeller && boutiqueToView && (
            <ContactSellerCmp
              boutique={boutiqueToView}
              showSnackBarMessage={showSnackBarMessage}
            />
          )}

          {/* Business Address */}
          <Box
            sx={{
              // backgroundImage: `url(/images/google_map_icon_on_map.png)`,
              backgroundColor: `rgba(160, 32, 240, 0.1)`,
              textAlign: "center",
              padding: 1,
              my: 1,
              color: "secondary.main",
            }}
          >
            <Box
              component="img"
              src="/images/google-map-logo.png"
              alt="Boutique img"
              sx={{
                width: 20,
                height: 20,
                objectFit: "cover",
              }}
            />
            <Typography fontSize={12} fontWeight="bold" variant="body2">
              {t("Business_Address")}
            </Typography>

            {boutiqueAddress && (
              <Typography variant="body2" fontSize={10}>
                {boutiqueAddress.addressLines
                  ? boutiqueAddress.addressLines
                  : `${boutiqueAddress.locality}, ${boutiqueAddress.subAdminArea}, ${boutiqueAddress.adminArea}, ${boutiqueAddress.countryName}`}
              </Typography>
            )}

            {!boutiqueAddress && (
              <Typography variant="body2" fontSize={10}>
                {t("No_Address_Yet")} !{" "}
                {urlIsMyBoutique &&
                  t("Your_main_profile_Address_will_be_shown_by_default")}
              </Typography>
            )}
          </Box>

          <Typography
            variant="body2"
            sx={{
              // width: "100%",
              textAlign: "center",
              fontSize: 11,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {boutiqueToView.person.accountVerified ? (
              ""
            ) : (
              <>
                <WarningOutlined color="warning" />
                {t("The_owner_of_this_Store_is_not_yet_verified")}
              </>
            )}
          </Typography>

          <CustomSnackbar
            message={messageSnakbar}
            severity={severitySnackbar}
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
          />
        </Box>
      )}
    </>
  );
};
export default HeaderViewBoutique;

const DialogDeleteBaniere = ({ open, handleClose, handleDelete }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" /> {t("Delete_Store_Picture")} ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
          id="alert-dialog-description"
        >
          {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
          variant="text"
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleDelete();
            handleClose();
          }}
          color="error"
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("to_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ContactSellerCmp = ({ boutique, showSnackBarMessage }) => {
  const { t } = useTranslation();

  const handleCopyToClipboard = (text) => {
    copy(text);
    showSnackBarMessage(t("Copied_exclamative"), "success");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 1,
      }}
    >
      {boutique.person?.phoneNumber && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <PhoneOutlined fontSize="small" />
          <Typography sx={{ wordBreak: "break-all" }}>
            {boutique.person.phoneNumber}
          </Typography>
          <IconButton
            onClick={() => handleCopyToClipboard(boutique.person.phoneNumber)}
            size="small"
            sx={{
              color: "#348781",
              backgroundColor: "#CFECEC",
              borderRadius: 2,
            }}
          >
            <ContentCopyRounded fontSize="inherit" />
          </IconButton>
        </Box>
      )}

      {boutique.person?.whatsapp && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <WhatsApp fontSize="small" color="success" />
          <Typography sx={{ wordBreak: "break-all" }}>
            {boutique.person.whatsapp}
          </Typography>
          <IconButton
            onClick={() => handleCopyToClipboard(boutique.person.whatsapp)}
            size="small"
            sx={{
              color: "#348781",
              backgroundColor: "#CFECEC",
              borderRadius: 2,
            }}
          >
            <ContentCopyRounded fontSize="inherit" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
