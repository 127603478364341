import { Box, Button, CircularProgress } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useSeeAllReceivedOrdersRequest } from "../../requestsApis/myOrdersRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useEffect } from "react";
import OrderMini from "../ui/OrderMini";
import { useModalViewOrderCmp } from "../../hooks/useModalViewOrderCmp";
import { useTranslation } from "react-i18next";

export default function ReceivedOrdersCmp({
  receivedOrdersList,
  isFinished,
  queryGetReceivedOrders,
  loadMore,
  notificationsCount,
  setNotificationsCount,
}) {
  const uniqueReceivedOrdersList = receivedOrdersList.filter(
    (not, index, self) => index === self.findIndex((n) => n.id === not.id)
  );

  const mutationSeeAllReceivedOrders = useSeeAllReceivedOrdersRequest(
    PRINCIPAL?.id || 0
  );

  const { t } = useTranslation();

  const {
    ModalViewOrderComponent,
    handleOpenModalViewOrder,
    setOrderToView,
    setTypeViewOrder,
  } = useModalViewOrderCmp();

  const viewOrder = (order, type) => {
    setOrderToView(order);
    setTypeViewOrder(type);
    handleOpenModalViewOrder();
  };

  useEffect(() => {
    if (notificationsCount.ordersReceived > 0) {
      mutationSeeAllReceivedOrders.mutate();
    }
  }, [notificationsCount.ordersReceived]);

  useEffect(() => {
    if (mutationSeeAllReceivedOrders.isError) {
      console.log(mutationSeeAllReceivedOrders.error);
    }

    if (mutationSeeAllReceivedOrders.isSuccess) {
      console.log(mutationSeeAllReceivedOrders.data.data);
      setNotificationsCount({ ...notificationsCount, ordersReceived: 0 });
    }
  }, [mutationSeeAllReceivedOrders.isPending]);

  return (
    <Box>
      <ModalViewOrderComponent />

      {uniqueReceivedOrdersList.map((order) => (
        <OrderMini
          order={order}
          key={order.id}
          viewOrder={viewOrder}
          type={"received"}
        />
      ))}

      {/* Progress bar */}
      <Box
        sx={{
          margin: 4,
          display: isFinished.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryGetReceivedOrders.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMore}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
            }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </Box>
  );
}
