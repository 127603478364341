import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useChangeBoutiqueBaniereRequest } from "../../requestsApis/boutiqueRequest";
import { PhotoLibrary } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// Drop zone Style
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "20rem",
  width: "100%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const DialogChangeBoutiqueBaniere = ({
  boutique,
  setBoutiqueToView,
  open,
  handleClose,
  setMessageSnackbar,
  setSeveritySnackbar,
  setOpenSnackbar,
}) => {
  //   *************   Drop Zone    ************************************
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] }, maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const filesCmp = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  // *************************    Defs
  const { t } = useTranslation();
  const mutationChangeBoutiqueBaniere = useChangeBoutiqueBaniereRequest(
    acceptedFiles[0],
    boutique.id
  );

  const handleSaveChangePhoto = () => {
    handleClose();
    setMessageSnackbar(t("Uploading_picture_3_dots"));
    setSeveritySnackbar("secondary");
    setOpenSnackbar(true);
    mutationChangeBoutiqueBaniere.mutate();
  };

  useEffect(() => {
    if (mutationChangeBoutiqueBaniere.isError) {
      console.log(mutationChangeBoutiqueBaniere.error.response);
      setMessageSnackbar(t("Error_changing_photo"));
      setSeveritySnackbar("error");
      setOpenSnackbar(true);
    }

    if (mutationChangeBoutiqueBaniere.isSuccess) {
      setMessageSnackbar(t("Changed_photo_successfully"));
      setSeveritySnackbar("success");
      setOpenSnackbar(true);

      const boutiqueResponse = mutationChangeBoutiqueBaniere.data.data;
      setBoutiqueToView({ ...boutique, baniere: boutiqueResponse.baniere });
    }
  }, [mutationChangeBoutiqueBaniere.isPending]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("Add_Store_cover_image")}</DialogTitle>
      <DialogContent sx={{ minWidth: { xs: "60vw", sm: "30rem" } }}>
        <section className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {acceptedFiles.length > 0 ? (
              <Typography sx={{ textWrap: "nowrap", textAlign: "center" }}>
                {t("File_selected")}
              </Typography>
            ) : (
              <Typography sx={{ textAlign: "center" }}>
                <PhotoLibrary />
                <br />
                <br />
                {t("Drag_n_Drop_Or_click")}
              </Typography>
            )}
          </div>
          <aside>
            <Typography>
              {t("Selected_File_must_be")}: <b>1024x500px</b>
            </Typography>
            <ul>{filesCmp}</ul>
          </aside>
        </section>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          sx={{ textTransform: "none" }}
          onClick={handleClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          color="secondary"
          sx={{ textTransform: "none" }}
          onClick={handleSaveChangePhoto}
          disabled={acceptedFiles.length === 0}
          variant="contained"
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
