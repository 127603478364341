import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useSeeAllNotificationsRequest } from "../../requestsApis/notificationsRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NotificationsCmp({
  notificationsList,
  isFinished,
  queryGetNotifications,
  loadMore,
  notificationsCount,
  setNotificationsCount,
  handleCloseNotificationsPopover,
}) {
  const uniqueNotificationsList = notificationsList.filter(
    (not, index, self) => index === self.findIndex((n) => n.id === not.id)
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  const mutationSeeAllNotifications = useSeeAllNotificationsRequest(
    PRINCIPAL?.id || 0
  );

  const handleOpenNotification = (notification) => {
    // if my post notification
    if (notification.type === "postComment") {
      handleCloseNotificationsPopover();
      navigate("/profile/myPosts");
    }
  };

  useEffect(() => {
    if (notificationsCount.activities > 0) {
      mutationSeeAllNotifications.mutate();
    }
  }, [notificationsCount.activities]);

  useEffect(() => {
    if (mutationSeeAllNotifications.isError) {
      console.log(mutationSeeAllNotifications.error);
    }

    if (mutationSeeAllNotifications.isSuccess) {
      console.log(mutationSeeAllNotifications.data.data);
      setNotificationsCount({ ...notificationsCount, activities: 0 });
    }
  }, [mutationSeeAllNotifications.isPending]);

  return (
    <Box>
      {uniqueNotificationsList.map((notification) => (
        <ANotification
          notification={notification}
          key={notification.id}
          handleOpenNotification={handleOpenNotification}
        />
      ))}

      {/* Progress bar */}
      <Box
        sx={{
          margin: 4,
          display: isFinished.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryGetNotifications.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMore}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
            }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </Box>
  );
}

const ANotification = ({ notification, handleOpenNotification }) => {
  const [fullname, setFullname] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [notAction, setNotAction] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (notification.type === "postReaction") {
      if (notification.receiverPerson !== null) {
        setFullname(
          `${notification.receiverPerson?.firstname} ${notification.receiverPerson?.lastname}`
        );
        setProfilePic(notification.receiverPerson?.profile);
      }
      setNotAction(
        `${t("You_interacted_on_a_post_from")} @ ${
          notification.senderPerson?.firstname
        } ${notification.senderPerson?.lastname}`
      );
    } else if (notification.type === "postComment") {
      if (notification.senderPerson !== null) {
        setFullname(
          `${notification.senderPerson?.firstname} ${notification.senderPerson?.lastname}`
        );
        setProfilePic(notification.senderPerson?.profile);

        if (notification.multiple) {
          setNotAction(t("And_other_people_commented_on_your_post"));
        } else {
          setNotAction(t("Commented_on_your_post"));
        }
      }
    }
  }, [notification]);
  return (
    <Card
      onClick={() => handleOpenNotification(notification)}
      variant="outlined"
      sx={{
        mb: 2,
        padding: 0,
        bgcolor: notification.read ? "inherit" : "whitesmoke",
        "&:hover": {
          bgcolor: "#e0e0e0",
        },
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={profilePic}
            sx={{
              border: "1px solid gray",
            }}
          />
        }
        title={fullname}
        subheader={
          <Box>
            <Typography fontSize={14}>{notAction}</Typography>
            <Typography fontSize={11} textAlign="right">
              {myFormatDate(notification.createdAt)}
            </Typography>
          </Box>
        }
        action={
          notification.post?.image?.includes(".com") && (
            <Box
              component="img"
              src={notification.post?.image}
              alt="img"
              sx={{
                width: 60,
                height: 50,
                objectFit: "contain",
              }}
            />
          )
        }
      />
    </Card>
  );
};
