import {
  Delete,
  MoreVertOutlined,
  Reply,
  ReportOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { PRINCIPAL } from "../../myHelpers/constants";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const APostComment = ({ comment, handleDeletePostComment }) => {
  // menu vars
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box
      width="95%"
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        gap: 1,
        paddingY: 2,
      }}
    >
      <Avatar
        src={comment.commenter?.profile}
        sx={{
          width: 30,
          height: 30,
          cursor: "pointer",
          border: "1px solid gray",
        }}
      />

      <Box width="100%">
        <Card
          variant="outlined"
          sx={{ borderRadius: 5, marginBottom: 1, padding: 1 }}
        >
          <Typography
            sx={{
              whiteSpace: "pre-wrap",
              whiteSpaceCollapse: "break-spaces",
              wordBreak: "break-word",
              textWrap: "wrap",
              width: "100%",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {comment.commenter.firstname} {comment.commenter.lastname}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "pre-wrap",
              whiteSpaceCollapse: "break-spaces",
              wordBreak: "break-word",
              textWrap: "wrap",
              width: "100%",
            }}
          >
            {comment.commentText}
          </Typography>
        </Card>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" color="textSecondary">
            {myFormatDate(comment.createdAt)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                color: "secondary.main",
                textDecoration: "underline",
              },
            }}
          >
            {t("Reply")} <Reply />
          </Typography>
        </Box>
      </Box>

      <IconButton onClick={handleOpenMenu} color="inherit">
        <MoreVertOutlined />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
          }}
          onClick={(e) => {
            handleCloseMenu(e);
          }}
        >
          <ReportOutlined />
          {t("to_Report")}
        </MenuItem>
        {comment.commenterId === PRINCIPAL?.id && (
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
            onClick={(e) => {
              handleCloseMenu(e);
              handleDeletePostComment(comment.id);
            }}
          >
            <Delete color="error" />
            {t("Delete")}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
export default APostComment;
