import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";

export const useModalViewImage = () => {
  const [imageToViewFullSizeLink, setImageToViewFullSizeLink] = useState("");
  const [isModalViewImageOpen, setIsModalViewImageOpen] = useState(false);

  const handleModalViewImageFullSizeOpen = () => {
    setIsModalViewImageOpen(true);
  };

  const handleModalViewImageFullSizeClose = () => {
    setIsModalViewImageOpen(false);
  };

  const ModalViewImageFullSizeCmp = () => (
    <Modal
      open={isModalViewImageOpen}
      onClose={handleModalViewImageFullSizeClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "95%",
          height: "95%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={imageToViewFullSizeLink}
          alt="img"
          sx={{
            height: "100%",
            width: "100%",
            display: "block",
            objectFit: "contain",
            ml: "auto",
            mr: "auto",
          }}
        />

        {/* <img
          src={imageToViewFullSizeLink}
          alt="img"
          
          ml={"auto"}
          mr={"auto"}
          
        /> */}
        <IconButton
          sx={{
            position: "absolute",
            top: 6,
            right: 6,
            color: "white",
            backgroundColor: "black",
          }}
          onClick={handleModalViewImageFullSizeClose}
        >
          <Close />
        </IconButton>
      </Box>
    </Modal>
  );

  return {
    ModalViewImageFullSizeCmp,
    handleModalViewImageFullSizeOpen,
    setImageToViewFullSizeLink,
  };
};
