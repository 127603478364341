import { useQuery } from "@tanstack/react-query";
import { BACK_URL } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/address";

// view Address Of Person Request
export const useViewAddressOfPersonRequest = (personId) => {
  const queryParams = {
    personId: personId,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/addressOfPerson?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: personId !== 0,
  });
};

// view Address Of Boutique Request
export const useViewAddressOfBoutiqueRequest = (boutiqueId, type) => {
  const queryParams = {
    boutiqueId: boutiqueId,
    type: type,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/addressOfBoutique?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: boutiqueId !== 0,
  });
};
