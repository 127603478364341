import { useQuery } from "@tanstack/react-query";
import { BACK_URL } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/productCategory";

// ViewAll Categories Request
export const useViewAllCategoriesRequest = (pageNum, pageSize, enabled) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/allProductCategories?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
