import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/myOrder";

// Add My Order Request
export const useAddMyOrderRequest = () => {
  return useMutation({
    mutationFn: async (myOrder) => {
      const fullUrl = `${BACK_URL}${basePath}/addMyOrder`;

      return await axios.post(fullUrl, myOrder, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// See All Received Orders Request ❌
export const useSeeAllReceivedOrdersRequest = (userId) => {
  const queryParams = {
    userId: userId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/seeAllOrdersReceived?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.put(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// GET Received Orders Request
export const useGetReceivedOrdersRequest = (userId, pageNum, pageSize) => {
  const queryParams = {
    userId: userId,
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/viewAllOrdersReceived?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: userId !== 0,
  });
};

// GET My Orders Request
export const useGetMyOrdersRequest = (userId, pageNum, pageSize) => {
  const queryParams = {
    userId: userId,
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/viewAllOrdersSent?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: userId !== 0,
  });
};
