import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Divider, IconButton, InputAdornment, Stack } from "@mui/material";
import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SearchViewCmp({
  mobile,
  openSearchMobile,
  handleCloseSearchMobile,
}) {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [showClearIcon, setShowClearIcon] = useState(false);
  const { t } = useTranslation();

  const handleInputChange = (event, newValue) => {
    setSearchValue(newValue);
    setShowClearIcon(newValue.length > 0);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setShowClearIcon(false);
  };

  const goToSearchClicked = () => {
    if (searchValue?.length > 0) {
      navigate(`/search/${searchValue}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      goToSearchClicked();
    }
  };

  return (
    <Autocomplete
      // if on mobile phones, hide second searchView
      sx={{
        display: {
          xs: openSearchMobile ? "flex" : "none",
          sm: mobile ? "none" : "flex",
        },
      }}
      freeSolo
      id="free-solo-2-demo"
      size="small"
      disableListWrap
      disablePortal
      disableClearable
      inputValue={searchValue}
      onInputChange={handleInputChange}
      options={top100Films.map((option) => option.title)}
      renderInput={(params) => (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            marginX: { xs: 1, sm: 0 },
            marginBottom: { xs: 1, sm: 0 },
            width: "100%",
          }}
        >
          <IconButton
            onClick={handleCloseSearchMobile}
            color="inherit"
            size="small"
            sx={{ display: { xs: "flex", sm: "none" } }}
          >
            <CloseOutlined />
          </IconButton>
          <TextField
            {...params}
            variant="outlined"
            color="secondary"
            placeholder={t("Search_Products_Stores")}
            onKeyDown={handleKeyDown}
            slotProps={{
              input: {
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <InputAdornment position="end">
                    {showClearIcon && (
                      <IconButton
                        sx={{ display: { xs: "none", md: "flex" } }}
                        size="small"
                        onClick={handleClearSearch}
                      >
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    )}
                    <Divider orientation="vertical" flexItem variant="middle" />
                    <IconButton onClick={goToSearchClicked} size="small">
                      <SearchOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            sx={{
              width: { sm: 200, md: 200, lg: 250 },

              "& .MuiOutlinedInput-root": {
                borderRadius: 50,
                height: 35,
              },
            }}
          />
        </Stack>
      )}
    />
  );
}

const top100Films = [];
