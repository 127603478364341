import { useMutation, useQuery } from "@tanstack/react-query";
import {
  BACK_URL,
  JWT_TOKEN,
  PRODUCTS_LOCATION_COUNTRY,
} from "../myHelpers/constants";
import axios from "axios";
const basePath = "/product";

// get Random Products ByCountry Request
export const useGetRandomProductsByCountryRequest = (
  countryCode,
  pageSize,
  productIds
) => {
  const queryParams = {
    countryCode: countryCode,
    pageSize: pageSize,
    productIds: productIds,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/random/productsByCountry?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: countryCode !== "",
  });
};

// Pin Unpin Product Request
export const usePinUnpinProductRequest = (productId, typePinUnpin) => {
  const queryParams = {
    productId: productId,
    typePinUnpin: typePinUnpin,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/pin_unpin_product?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.put(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Mark product availability Request
export const useMarkProductAvailabilityRequest = (productId, availability) => {
  const queryParams = {
    productId: productId,
    availability: availability,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/markAvailability?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.put(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Delete Product Request
export const useDeleteProductRequest = (productId) => {
  const queryParams = {
    productId: productId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/delete_product?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Add Product Image Request
export const useAddProductImageRequest = (file) => {
  return useMutation({
    mutationFn: async (productId) => {
      const queryParams = {
        productId: productId,
      };

      const queryString = new URLSearchParams(queryParams).toString();

      const fullUrl = `${BACK_URL}${basePath}/add_product_image?${queryString}`;

      // form data
      const form = new FormData();

      form.append("file", file);

      return await axios.put(fullUrl, form, {
        headers: {
          Authorization: JWT_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};

// Add Product Request
export const useAddProductRequest = (product) => {
  return useMutation({
    mutationFn: async () => {
      const fullUrl = `${BACK_URL}${basePath}/add_product`;

      return await axios.post(fullUrl, product, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// get Random Products Request
export const useGetRandomProductsRequest = (pageSize, productIds) => {
  const queryParams = {
    pageSize: pageSize,
    productIds: productIds,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/random/products?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: PRODUCTS_LOCATION_COUNTRY === null,
  });
};

// get Products of a Category Request
export const useViewProductsOfCategoryRequest = (
  categoryId,
  pageSize,
  productsIds
) => {
  const queryParams = {
    pageSize: pageSize,
    productsIds: productsIds,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/category_id_products/${categoryId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: categoryId !== 0,
  });
};

// View Product Request
export const useViewProductRequest = (productId) => {
  const fullUrl = `${BACK_URL}${basePath}/view/product/${productId}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
  });
};

// View Boutique Products Request
export const useViewBoutiquesProductsRequest = (
  boutiqueId,
  pageNum,
  pageSize
) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/boutiquesProducts/${boutiqueId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: boutiqueId !== 0,
  });
};
