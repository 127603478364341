import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/person";

// Add Address Of Person
export const useAddAddressOfPersonRequest = (
  personId,
  typeAddress,
  myAddress
) => {
  const queryParams = {
    personId: personId,
    typeAddress: typeAddress,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/addAddressOfPerson?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.post(fullUrl, myAddress, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Get Count My Notifications Request
export const useGetCountMyNotificationsRequest = (personId) => {
  const queryParams = {
    personId: personId,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getNotifications?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: personId !== 0,
  });
};

// Edit Password Request
export const useEditPasswordRequest = (personId, oldPassword, newPassword) => {
  const queryParams = {
    id: personId,
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/edit_password?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.put(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Edit Whatsapp ❌
export const useEditWhatsappRequest = (person) => {
  const queryParams = {
    id: person?.id,
    whatsapp: person?.whatsapp,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/edit_whatsapp?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      console.log(queryString);
      return await axios.put(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Edit Person Request
export const useEditPersonRequest = (person) => {
  const fullUrl = `${BACK_URL}${basePath}/edit_person`;

  return useMutation({
    mutationFn: async () => {
      return await axios.put(fullUrl, person, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Modify Profile Picture Request
export const useModifyProfilePictureRequest = (file, personId) => {
  const queryParams = {
    personId: personId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/change_profile?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      // form data
      const form = new FormData();
      // form.append("personId", PRINCIPAL.id);

      form.append("file", file);

      return await axios.put(fullUrl, form, {
        headers: {
          Authorization: JWT_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};

// Delete Profile Picture Request
export const useDeleteProfilePicRequest = (personId) => {
  const queryParams = {
    personId: personId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/delete_profile?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Profile Request
export const useProfileRequest = (personId) => {
  const fullUrl = `${BACK_URL}${basePath}/profile/${personId}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 10 * 1000,
    enabled: personId !== 0,
  });
};

// Register Request
export const useRegisterRequest = (person) => {
  const queryParams = {
    confirmPassword: person.password,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/register?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.post(fullUrl, person);
    },
  });
};

// Verify Phone Request
export const useVerifyPhoneRequest = (person) => {
  const fullUrl = `${BACK_URL}${basePath}/verifyPhone`;

  return useMutation({
    mutationFn: async () => {
      return await axios.post(fullUrl, person);
    },
  });
};

// Login Request
export const useLoginRequest = (loginTypeText, formData) => {
  const queryParams = {
    loginType: loginTypeText,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/login?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.post(fullUrl, formData);
    },
  });
};
