import { Box, Button, Paper, Typography } from "@mui/material";
import useIsDeviceAndroid from "../../hooks/useIsDeviceAndroid";
import { useTranslation } from "react-i18next";
import { SHOPITEMZ_PLAY_STORE_LINK } from "../../myHelpers/constants";

const DeviceAndroidComponent = () => {
  const isDeviceAndroid = useIsDeviceAndroid();

  const { t } = useTranslation();

  const handleOpenApp = () => {
    const appScheme = "com.podosoft.shopitemz://"; // Replace with your app scheme (like "whatsapp://")

    // Try to open the app using the scheme
    const timeout = setTimeout(() => {
      // If the app doesn't open, redirect to the Play Store
      window.location.href = SHOPITEMZ_PLAY_STORE_LINK;
    }, 500); // Adjust the timeout as needed

    // Try to open the app
    window.location.href = appScheme;

    // Clear the timeout if the app is opened successfully
    window.addEventListener("blur", () => clearTimeout(timeout));
  };

  return (
    <Paper sx={{ marginBottom: 1 }}>
      {isDeviceAndroid && (
        <Box
          onClick={handleOpenApp}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <img
              src="/images/logo_100_px.png"
              width="40"
              height="40"
              alt="Logo"
            />

            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                {t("Shop_itemz_for_Android")}
              </Typography>
              <Typography>{t("Light_fast_smooth")}</Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: "none",
            }}
          >
            {t("Open_App")}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default DeviceAndroidComponent;
