import { DialogLoginExpiredComponent } from "./components/largeComponents/DialogLoginExpiredComponent";
import Navbar from "./components/largeComponents/Navbar";

import Sidebar from "./components/largeComponents/Sidebar";
import { Outlet } from "react-router-dom";

export function Layout({
  setOpenDrawer,
  navTo,
  setNavTo,
  handleOpenModalUseMobileApp,
  selectedMenuListIndex,
  setSelectedMenuListIndex,
  openDrawer,
}) {
  return (
    <>
      <DialogLoginExpiredComponent />
      <Navbar
        setOpenDrawer={setOpenDrawer}
        getUrl={(url) => `/${url}`}
        navTo={navTo}
        setNavTo={setNavTo}
        handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
      />
      <Sidebar
        selectedMenuListIndex={selectedMenuListIndex}
        setSelectedMenuListIndex={setSelectedMenuListIndex}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        getUrl={(url) => `/${url}`}
        navTo={navTo}
        setNavTo={setNavTo}
      />

      <main>
        <Outlet />
      </main>
    </>
  );
}
