import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useViewAllCategoriesRequest } from "../../requestsApis/categoriesRequests";
import { useViewAllCurrenciesRequest } from "../../requestsApis/currenciesRequest";
import {
  CURRENCIES,
  CURRENCIES_STRING,
  PRINCIPAL,
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_STRING,
} from "../../myHelpers/constants";
import { useAddProductRequest } from "../../requestsApis/productsRequests";

import { useTranslation } from "react-i18next";

export const ModalEditProductCmp = ({
  productToEdit,
  setProductToEdit,
  openModal,
  handleCloseModal,
  handleReplaceAfterEditProduct,
  showSnackBarMessage,
}) => {
  // modal logic
  const [categoriesList, setCategoriesLis] = useState([]);
  const [queryCategEnabled, setQueryCategEnabled] = useState(false);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [queryCurrencyEnabled, setQueryCurrencyEnabled] = useState(false);

  //    Queries
  const queryViewCategories = useViewAllCategoriesRequest(
    0,
    200,
    queryCategEnabled
  );
  const queryViewCurrencies = useViewAllCurrenciesRequest(queryCurrencyEnabled);

  const mutationAddProduct = useAddProductRequest(productToEdit);
  const { t } = useTranslation();

  //    *****************     Methods

  const handleChange = (event) => {
    setProductToEdit({
      ...productToEdit,
      [event.target.name]: event.target.value,
    });
  };

  const handlePost = () => {
    // check categ Eng
    const selectedCategory = categoriesList.filter(
      (category) => category.name === productToEdit.category
    );
    if (selectedCategory[0]) {
      setProductToEdit({
        ...productToEdit,
        categoryId: selectedCategory[0].id,
      });
    } // check categ Fr
    else {
      const selectedCategory = categoriesList.filter(
        (category) => category.nameFr === productToEdit.category
      );
      if (selectedCategory[0]) {
        setProductToEdit({
          ...productToEdit,
          categoryId: selectedCategory[0].id,
        });
      }
    }

    mutationAddProduct.mutate();
  };

  //    Effects   ********************************

  useEffect(() => {
    if (categoriesList.length < 1) {
      if (PRODUCT_CATEGORIES === null || PRODUCT_CATEGORIES.length < 1) {
        setQueryCategEnabled(true);
      } else {
        setCategoriesLis(PRODUCT_CATEGORIES);
        setQueryCategEnabled(false);
      }
    }
  }, [categoriesList.length]);

  useEffect(() => {
    if (currenciesList.length < 1) {
      if (CURRENCIES === null || CURRENCIES.length < 1) {
        setQueryCurrencyEnabled(true);
      } else {
        setCurrenciesList(CURRENCIES);
        setQueryCurrencyEnabled(false);
      }
    }
  }, [currenciesList.length]);

  useEffect(() => {
    if (queryViewCategories.isError) {
      console.log(queryViewCategories.error);
    }

    if (queryViewCategories.isSuccess) {
      setCategoriesLis(queryViewCategories.data.data);
      localStorage.setItem(
        PRODUCT_CATEGORIES_STRING,
        JSON.stringify(queryViewCategories.data.data)
      );
    }
  }, [queryViewCategories.isPending]);

  useEffect(() => {
    if (queryViewCurrencies.isError) {
      console.log(queryViewCurrencies.error);
    }

    if (queryViewCurrencies.isSuccess) {
      setCurrenciesList(queryViewCurrencies.data.data);
      localStorage.setItem(
        CURRENCIES_STRING,
        JSON.stringify(queryViewCurrencies.data.data)
      );
    }
  }, [queryViewCurrencies.isPending]);

  // effect add Product
  useEffect(() => {
    if (mutationAddProduct.isError) {
      console.log(mutationAddProduct.error);
      showSnackBarMessage(t("Error_Publishing_Product_Edit"), "error");
    }

    if (mutationAddProduct.isSuccess) {
      const editedProduct = mutationAddProduct.data.data;
      handleReplaceAfterEditProduct(editedProduct);
      handleCloseModal();
      showSnackBarMessage(t("Published_Product_Edit_Successfully"));
    }
  }, [mutationAddProduct.isPending]);

  return (
    <>
      {productToEdit && (
        <>
          <Modal
            open={openModal}
            // onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", md: "70%" },
                // height: 650,
                height: "90vh",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
              }}
            >
              {/* Top Header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  borderBottom: "1px solid gray",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t("Edit_Product_Details")}
                </Typography>
                <Button
                  onClick={handleCloseModal}
                  size="small"
                  color="error"
                  variant="outlined"
                  startIcon={<Close />}
                  sx={{ textTransform: "none" }}
                  disabled={mutationAddProduct.isPending}
                >
                  {t("Close")}
                </Button>
              </Box>

              {/* Main conaint */}
              <Box
                sx={{
                  position: "relative",
                  height: "80%",
                  width: "100%",
                  overflow: "auto",
                  marginRight: 1,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <img
                    width={100}
                    height={100}
                    src={productToEdit.image}
                    alt=""
                  />
                </Box>

                <Box sx={{ paddingBottom: 4, paddingX: 2 }}>
                  <Grid container sx={{ marginTop: 2 }} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="outlined-basic"
                        label={t("Product_Name")}
                        required
                        name="name"
                        type="text"
                        color="secondary"
                        value={productToEdit.name}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label={t("Category")}
                        name="category"
                        type="text"
                        color="secondary"
                        value={productToEdit.category}
                        helperText="Select the product Category"
                        sx={{ width: "100%" }}
                        onChange={handleChange}
                      >
                        {categoriesList.map((category) => (
                          <MenuItem key={category.id} value={category.name}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ marginTop: 2 }} spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label={t("Price")}
                        name="price"
                        type="number"
                        color="secondary"
                        value={productToEdit.price}
                        variant="outlined"
                        helperText="Default 0"
                        sx={{ width: "100%" }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label={`$ ${t("Currency")}`}
                        name="priceCode"
                        type="text"
                        color="secondary"
                        value={productToEdit.priceCode}
                        helperText="Select the Currency"
                        sx={{ width: "100%" }}
                        onChange={handleChange}
                      >
                        {currenciesList.map((currency) => (
                          <MenuItem key={currency.code} value={currency.code}>
                            {currency.code}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ marginTop: 2 }} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="outlined-basic"
                        label={t("Descriptions")}
                        name="description"
                        type="text"
                        color="secondary"
                        value={productToEdit.description}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="outlined-basic"
                        label={t("Quantity")}
                        name="quantity"
                        type="number"
                        color="secondary"
                        value={productToEdit.quantity}
                        variant="outlined"
                        helperText="Default 1"
                        sx={{ width: "100%" }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Footer */}
              <Box
                sx={{
                  borderTop: "1px solid gray",
                  paddingTop: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {mutationAddProduct.isPending && (
                  <Typography>
                    {t("Uploading_product_Do_not_refresh_the_page_please_wait")}
                    <CircularProgress size={15} />
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md: "right" },
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      minWidth: 100,
                    }}
                    disabled={mutationAddProduct.isPending}
                  >
                    {t("Cancel")}
                  </Button>

                  <Button
                    onClick={handlePost}
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      marginLeft: { xs: 5, md: 10 },
                      minWidth: 100,
                    }}
                    disabled={
                      productToEdit.name.length < 3 ||
                      mutationAddProduct.isPending
                    }
                  >
                    {mutationAddProduct.isPending ? (
                      <CircularProgress size={15} />
                    ) : (
                      t("Post")
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
