import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useModifyProfilePictureRequest } from "../../requestsApis/personRequest";
import { PhotoLibrary } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// Drop zone Style
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "20rem",
  width: "100%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const DialogModifyProfilePicture = ({
  principal,
  setPrincipal,
  open,
  handleClose,
  setMessageSnackbar,
  setSeveritySnackbar,
  setOpenSnackbar,
  setDurationSnackbar,
}) => {
  //   *************   Drop Zone    ************************************
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] }, maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const filesCmp = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  // *************************    Defs
  const { t } = useTranslation();
  const mutationModifyProfPic = useModifyProfilePictureRequest(
    acceptedFiles[0],
    PRINCIPAL?.id || 0
  );

  const handleSaveChangeProfPic = () => {
    handleClose();
    setMessageSnackbar(t("Uploading_picture_3_dots"));
    setSeveritySnackbar("secondary");
    setOpenSnackbar(true);
    mutationModifyProfPic.mutate();
  };

  useEffect(() => {
    if (mutationModifyProfPic.isError) {
      console.log(mutationModifyProfPic.error.response);
      setMessageSnackbar(t("Error_changing_profile_picture"));
      setSeveritySnackbar("error");
      setOpenSnackbar(true);
    }

    if (mutationModifyProfPic.isSuccess) {
      setMessageSnackbar(t("Changed_profile_picture_successfully"));
      setSeveritySnackbar("success");
      setOpenSnackbar(true);
      const principalResponse = mutationModifyProfPic.data?.data?.principal;

      if (principalResponse) {
        setPrincipal({ ...principal, profile: principalResponse.profile });
      }
    }
  }, [mutationModifyProfPic.isPending]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("Modify_Profile_Picture")}</DialogTitle>
      <DialogContent sx={{ minWidth: { xs: "60vw", sm: "30rem" } }}>
        <section className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {acceptedFiles.length > 0 ? (
              <Typography sx={{ textWrap: "nowrap", textAlign: "center" }}>
                {t("File_selected")}
              </Typography>
            ) : (
              <Typography sx={{ textWrap: "nowrap", textAlign: "center" }}>
                <PhotoLibrary />
                <br />
                <br />
                {t("Drag_n_Drop_Or_click")}
              </Typography>
            )}
          </div>
          <aside>
            <h4>{t("Selected_File")}</h4>
            <ul>{filesCmp}</ul>
          </aside>
        </section>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          sx={{ textTransform: "none" }}
          onClick={handleClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          color="secondary"
          sx={{ textTransform: "none" }}
          onClick={handleSaveChangeProfPic}
          disabled={acceptedFiles.length === 0}
          variant="contained"
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
