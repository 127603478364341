import { ArrowForwardOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProductsCategoriesCmp = ({
  categories,
  loadMoreCategories,
  isFinishedCateg,
  queryViewAllCategories,
}) => {
  const stackViewMoreRef = useRef(null);
  const skeletonList = Array.from({ length: 15 });
  const { t, i18n } = useTranslation();

  // unique Prods
  const uniqueCategoriesList = categories.filter(
    (categ, index, self) => index === self.findIndex((c) => c.id === categ.id)
  );

  // Methods

  const scrollStackViewMore = () => {
    if (stackViewMoreRef.current) {
      stackViewMoreRef.current.scrollTo({
        left: stackViewMoreRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box mt={3}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          sx={{ fontSize: { xs: 16, sm: 20 } }}
          variant="h6"
          fontWeight="bold"
        >
          {t("Products_Categories")}
        </Typography>
        <Button
          variant="text"
          endIcon={<ArrowForwardOutlined fontSize="inherit" />}
          color="success"
          size="small"
          onClick={scrollStackViewMore}
          sx={{ textTransform: "none" }}
        >
          {t("View_More")}
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack
          ref={stackViewMoreRef}
          spacing={4}
          direction="row"
          sx={{
            paddingRight: 4,
            overflow: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {uniqueCategoriesList.map((category, index) => (
            <Link
              to={`/ViewCategoryProducts/${category.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              >
                <Avatar
                  sx={{
                    width: { xs: 60, sm: 70 },
                    height: { xs: 60, sm: 70 },
                    mx: "auto",
                    bgcolor: "#E6E6FA",
                    transition: "transform 0.5s ease",
                    transformOrigin: "center",
                  }}
                  src={category.image}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    // whiteSpace: "nowrap",
                    mt: 1,
                    fontSize: { xs: 10, sm: 13 },
                    textAlign: "center",
                    fontWeight: "medium",
                    width: { xs: 70, sm: 132 },
                  }}
                >
                  {i18n.language.slice(0, 2) === "fr"
                    ? category.nameFr
                    : category.name}
                </Typography>
              </Box>
            </Link>
          ))}

          {queryViewAllCategories.isPending && (
            <>
              {skeletonList.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Skeleton variant="circular" width={70} height={70} />
                  <Skeleton variant="text" width={70} />
                </Box>
              ))}
            </>
          )}

          <Box
            sx={{
              display: isFinishedCateg.current ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginRight: 3,
              "&:hover": { transform: "scale(1.05)" },
            }}
          >
            {!queryViewAllCategories.isPending && (
              <Button
                endIcon={<ArrowForwardOutlined />}
                onClick={loadMoreCategories}
                variant="outlined"
                sx={{ whiteSpace: "nowrap", textTransform: "none" }}
              >
                {t("Load_more")}
              </Button>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ProductsCategoriesCmp;
