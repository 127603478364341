import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AVideo = ({ video, goToViewProduct, goToViewCategoryProducts }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ marginBottom: 4 }}>
      <Link
        to={`/ViewCategoryProducts/${video.product?.categoryId}`}
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: "pointer",
            width: "fit-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "&:hover": {
              color: "secondary.main",
              textDecoration: "underline",
            },
          }}
        >
          {video.product?.category}
        </Typography>
      </Link>

      <Link
        to={`/viewProduct/${video.product?.id}`}
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <Typography
          variant="h6"
          fontSize={17}
          sx={{
            cursor: "pointer",
            width: "fit-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "&:hover": {
              color: "secondary.main",
              textDecoration: "underline",
            },
          }}
        >
          {video.product?.name}
        </Typography>
      </Link>

      <Typography
        sx={{
          width: "fit-content",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {video.content}
      </Typography>
      <Box
        sx={{
          border: "2px solid gray",
          backgroundColor: "gray",
          width: "95%",
          height: 500,
        }}
      >
        <video
          src={video.video}
          controls
          poster={video.thumb}
          width="100%"
          height="100%"
        >
          {t("You_need_to_update_your_browser_dude")} !!!
        </video>
      </Box>
    </Box>
  );
};
export default AVideo;
