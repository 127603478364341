import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useState } from "react";
import HomePage from "./pages/homePage/HomePage";
import PostsPage from "./pages/postsPage/PostsPage";
import VideosPage from "./pages/videosPage/VideosPage";
import ViewCategoryProducts from "./pages/viewCategoriesProducts/ViewCategoryProducts";
import ViewProductPage from "./pages/viewProductPage/ViewProductPage";
import ViewBoutiquePage from "./pages/viewBoutiquePage/ViewBoutiquePage";
import { Layout } from "./Layout";
import SignIn from "./pages/sign_In_Up/SignIn";
import SignUp from "./pages/sign_In_Up/SignUp";
import ProfilePage from "./pages/profilePage/ProfilePage";
import "./App.css";
import ViewDossierPage from "./pages/viewDossierPage/ViewDossierPage";
import SearchPage from "./pages/searchPage/SearchPage";
import { useTranslation } from "react-i18next";
import SEO from "./components/seo/SEO";
import { FRONT_URL, SHOP_LOGO_URL } from "./myHelpers/constants";

function App() {
  const [selectedMenuListIndex, setSelectedMenuListIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [navTo, setNavTo] = useState(0);
  const { t } = useTranslation();

  const [openModalUseMobileApp, setOpenModalUseMobileApp] = useState(false);
  const handleOpenModalUseMobileApp = () => setOpenModalUseMobileApp(true);
  const handleCloseModalUseMobileApp = () => setOpenModalUseMobileApp(false);

  return (
    <Router>
      {/* Global SEO Meta Tags */}
      <SEO
        title={t("seo_meta.title")}
        description={t("seo_meta.description")}
        image={SHOP_LOGO_URL}
        url={FRONT_URL}
      />
      <Routes>
        {/* signIn */}
        <Route
          path="/signIn"
          element={
            <>
              <SEO
                title={t("seo_meta_signIn_page.title")}
                description={t("seo_meta_signIn_page.description")}
                url={`${FRONT_URL}/signIn`}
              />
              <SignIn />
            </>
          }
        />

        {/* signUp */}
        <Route
          path="/signUp"
          element={
            <>
              <SEO
                title={t("seo_meta_signUp_page.title")}
                description={t("seo_meta_signUp_page.description")}
                url={`${FRONT_URL}/signUp`}
              />
              <SignUp />
            </>
          }
        />

        {/* All Pages */}
        <Route
          element={
            <Layout
              setOpenDrawer={setOpenDrawer}
              navTo={navTo}
              setNavTo={setNavTo}
              handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
              openModalUseMobileApp={openModalUseMobileApp}
              handleCloseModalUseMobileApp={handleCloseModalUseMobileApp}
              selectedMenuListIndex={selectedMenuListIndex}
              setSelectedMenuListIndex={setSelectedMenuListIndex}
              openDrawer={openDrawer}
            />
          }
        >
          {/* Home Page */}
          <Route
            path="/"
            element={
              <>
                <SEO
                  title={t("seo_meta_home_page.title")}
                  description={t("seo_meta_home_page.description")}
                />
                <HomePage
                  handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
                  navTo={navTo}
                  setNavTo={setNavTo}
                />
              </>
            }
          />

          {/* Posts Page */}
          <Route
            path="/Posts"
            element={
              <>
                <SEO
                  title={t("seo_meta_posts_page.title")}
                  description={t("seo_meta_posts_page.description")}
                  url={`${FRONT_URL}/Posts`}
                />
                <PostsPage navTo={navTo} setNavTo={setNavTo} />
              </>
            }
          />
          <Route
            path="/Videos"
            element={
              <>
                <SEO
                  title={t("seo_meta_videos_page.title")}
                  description={t("seo_meta_videos_page.description")}
                  url={`${FRONT_URL}/Videos`}
                />
                <VideosPage navTo={navTo} setNavTo={setNavTo} />
              </>
            }
          />
          <Route
            path="/ViewCategoryProducts/:categoryId"
            element={
              <>
                <SEO
                  title={t("seo_meta_categories_page.title")}
                  description={t("seo_meta_categories_page.description")}
                />
                <ViewCategoryProducts />
              </>
            }
          />
          <Route
            path="/ViewProduct/:productIdParam"
            element={
              <>
                <SEO
                  title={t("seo_meta_view_product_page.title")}
                  description={t("seo_meta_view_product_page.description")}
                />
                <ViewProductPage />
              </>
            }
          />
          <Route
            path="/ViewBoutique/:boutiqueIdParam"
            element={
              <>
                <SEO
                  title={t("seo_meta_view_boutique_page.title")}
                  description={t("seo_meta_view_boutique_page.description")}
                />
                <ViewBoutiquePage
                  handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
                />
              </>
            }
          />

          <Route
            path="/profile/*"
            element={
              <>
                <SEO
                  title={t("seo_meta_profile_page.title")}
                  description={t("seo_meta_profile_page.description")}
                />
                <ProfilePage />
              </>
            }
          />

          <Route
            path="/profile/myBoutique/:boutiqueIdParam"
            element={
              <ViewBoutiquePage
                handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
              />
            }
          />

          <Route
            path="/profile/myCatalogue/:dossierIdParam"
            element={<ViewDossierPage />}
          />

          <Route
            path="/search/:searchParam"
            element={
              <>
                <SEO
                  title={t("seo_meta_search_page.title")}
                  description={t("seo_meta_search_page.description")}
                />
                <SearchPage />
              </>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
