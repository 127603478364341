import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetRandomVideosRequest } from "../../requestsApis/productVideosRequest";
import VideosCmp from "./VideosCmp";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const VideosWrapper = () => {
  const [videosList, setVideosList] = useState([]);
  let pageSize = useRef(4);
  let videosIds = useRef([0]);
  let isFinishedVideos = useRef(false);

  const { t } = useTranslation();

  // queries
  const queryGetRandomVideos = useGetRandomVideosRequest(
    pageSize.current,
    videosIds.current
  );

  const loadMore = () => {
    if (!isFinishedVideos.current) {
      if (!queryGetRandomVideos.isLoading) {
        let newIds = videosList.map((video) => video.id);
        videosIds.current = newIds;
        queryGetRandomVideos.refetch();
      }
    }
  };

  //  ****  Effects
  useEffect(() => {
    if (queryGetRandomVideos.isError) {
      console.log(queryGetRandomVideos.error);
    }

    if (queryGetRandomVideos.isSuccess) {
      setVideosList((prevList) => [
        ...prevList,
        ...queryGetRandomVideos.data.data,
      ]);

      if (queryGetRandomVideos.data.data.length < pageSize.current) {
        isFinishedVideos.current = true;
      }
    }
  }, [queryGetRandomVideos.isPending]);

  return (
    <Box mt={3}>
      <VideosCmp videosList={videosList} setVideosList={setVideosList} />

      <Box
        sx={{
          marginY: 4,
          display: isFinishedVideos.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryGetRandomVideos.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMore}
            sx={{ whiteSpace: "nowrap", textTransform: "none" }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default VideosWrapper;
