import { Box } from "@mui/material";
import AnOrder from "../ui/AnOrder";

const MyOrdersCmp = ({ myOrdersList, setMyOrdersList }) => {
  // make sure that every order is rendered once only
  const uniqueOrders = myOrdersList.filter(
    (order, index, self) => index === self.findIndex((s) => s.id === order.id)
  );

  return (
    <Box>
      {uniqueOrders.map((order) => (
        <AnOrder order={order} key={order.id} />
      ))}
    </Box>
  );
};
export default MyOrdersCmp;
