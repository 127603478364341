import { FolderOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const EmptyDossiersCmp = ({ dossiers, handleSaveCart }) => {
  // unique Doss
  const uniqueDossiers = dossiers.filter(
    (dossier, index, self) =>
      index === self.findIndex((d) => d.id === dossier.id)
  );

  return (
    <>
      {uniqueDossiers.map((dossier) => (
        <AEmptyDossier
          dossier={dossier}
          key={dossier.id}
          handleSaveCart={handleSaveCart}
        />
      ))}
    </>
  );
};
export default EmptyDossiersCmp;

const AEmptyDossier = ({ dossier, handleSaveCart }) => {
  const handleDossierClicked = () => {
    handleSaveCart(dossier.id);
  };
  return (
    <Box
      onClick={handleDossierClicked}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        paddingY: 2,
        paddingRight: 1,
        borderBottom: "1px solid gray",
        "&:hover": {
          backgroundColor: "#e0e0e0",
          cursor: "pointer",
        },
      }}
    >
      <Typography>{dossier.name}</Typography>
      <FolderOutlined />
    </Box>
  );
};
