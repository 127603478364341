import { useEffect, useRef, useState } from "react";
import { useGetMyPostsRequest } from "../../requestsApis/postsRequests";
import { PRINCIPAL } from "../../myHelpers/constants";
import { Box, Typography } from "@mui/material";
import { LoadMoreBtnCmp } from "../../components/ui/LoadMoreBtnCmp";
import MyPostsCmp from "../../components/largeComponents/MyPostsCmp";
import PostInput from "../postsPage/PostInput";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const MyPostsPage = ({ setSelectedTab }) => {
  const [myPostsList, setMyPostsList] = useState([]);
  let pageNum = useRef(0);
  let pageSize = useRef(10);
  let isFinished = useRef(false);

  const { t } = useTranslation();

  const queryGetMyPosts = useGetMyPostsRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current
  );

  const loadMore = () => {
    if (!isFinished.current && !queryGetMyPosts.isLoading) {
      pageNum.current += 1;
      queryGetMyPosts.refetch();
    }
  };

  // ********************     Use Effects
  useEffect(() => {
    setSelectedTab(4);
  }, []);

  useEffect(() => {
    if (queryGetMyPosts.isError) {
      console.log(queryGetMyPosts.error);
    }

    if (queryGetMyPosts.isSuccess && queryGetMyPosts.data) {
      setMyPostsList((prevList) => [...prevList, ...queryGetMyPosts.data.data]);

      if (queryGetMyPosts.data.data.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetMyPosts.isPending]);

  return (
    <Box>
      <DialogLoginExpiredComponent />
      <Typography sx={{ marginLeft: 4, marginBottom: 2 }} variant="h6">
        {t("My_Posts")}
      </Typography>
      {/* Add Post */}
      <PostInput setPostsList={setMyPostsList} />
      <MyPostsCmp postsList={myPostsList} setMyPostsList={setMyPostsList} />
      <LoadMoreBtnCmp
        isFinished={isFinished}
        query={queryGetMyPosts}
        loadMore={loadMore}
      />
    </Box>
  );
};
export default MyPostsPage;
