import { useEffect, useState } from "react";
import { useRef } from "react";
import { useViewProductsOfCategoryRequest } from "../../requestsApis/productsRequests";
import { Box } from "@mui/material";
import ProductsCmp from "../homePage/ProductsCmp";

export const RelatedProducts = ({ categoryId }) => {
  let productsIds = useRef([0]);
  let pageSizeProd = useRef(12);
  let isFinishedProd = useRef(false);
  const [productsList, setProductsList] = useState([]);
  // query View Category Products
  const queryViewCategoryProducts = useViewProductsOfCategoryRequest(
    categoryId || 0,
    pageSizeProd.current,
    productsIds.current
  );

  //    *** Effects
  useEffect(() => {
    if (queryViewCategoryProducts.isSuccess && queryViewCategoryProducts.data) {
      const productsResponse = queryViewCategoryProducts.data.data?.products;

      if (productsResponse !== null) {
        setProductsList((prevProducts) => [
          ...prevProducts,
          ...productsResponse,
        ]);

        if (productsResponse.length < pageSizeProd.current) {
          isFinishedProd.current = true;
        }
      }
    }
  }, [queryViewCategoryProducts.isPending]);

  return (
    <Box>
      <ProductsCmp products={productsList} />
    </Box>
  );
};
