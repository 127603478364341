import {
  BlockOutlined,
  CameraAltOutlined,
  Delete,
  DeleteOutline,
  EditOutlined,
  Favorite,
  FavoriteBorderOutlined,
  LocationOnOutlined,
  MoreVert,
  OndemandVideoOutlined,
  OpenInNew,
  PushPinOutlined,
  ShareOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ModalEditProductCmp } from "../../components/largeComponents/ModalEditProductCmp";
import {
  useDeleteProductRequest,
  useMarkProductAvailabilityRequest,
  usePinUnpinProductRequest,
} from "../../requestsApis/productsRequests";
import { CustomSnackbar } from "../../components/ui/CustomSnackbar";
import { RWebShare } from "react-web-share";
import { FRONT_URL, PRINCIPAL } from "../../myHelpers/constants";
import { ModalEmptyDossiers } from "../../components/largeComponents/ModalEmptyDossiers";
import {
  useAddCartRequest,
  useDeleteCartFromDossierRequest,
} from "../../requestsApis/cartRequest";
import ModalAddProductImages from "../../components/largeComponents/ModalAddProductImages";
import ModalAddProductVideos from "../../components/largeComponents/ModalAddProductVideos";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useTranslation } from "react-i18next";

const ProductsCmp = ({
  products,
  setProductsList,
  urlIsMyBoutiqueAndImOwner,
  urlIsMyCatalogueAndImOwner,
  dossierId,
}) => {
  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  const navigate = useNavigate();
  // unique Prods
  const uniqueProductsList = products.filter(
    (product, index, self) =>
      index === self.findIndex((p) => p.id === product.id)
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  // Methods ****************************
  const goToViewProduct = (event, productId) => {
    if (event.target === event.currentTarget) {
      navigate(`/ViewProduct/${productId}`);
    }
  };

  const goToViewCategoryProducts = (categoryId) => {
    navigate(`/ViewCategoryProducts/${categoryId}`);
  };

  const goToViewBoutique = (boutiqueId) => {
    navigate(`/ViewBoutique/${boutiqueId}`);
  };

  // replace Edited Product with new one
  const handleReplaceAfterEditProduct = (newProduct) => {
    const newProductsList = products.map((product) => {
      if (newProduct.id === product.id) {
        return newProduct;
      } else {
        return product;
      }
    });

    setProductsList(newProductsList);
  };

  const handleRemoveAfterDeleted = (deletedProduct) => {
    setProductsList((prevList) =>
      prevList.filter((row) => row.id !== deletedProduct.id)
    );
  };

  return (
    <>
      <DialogLoginComponent />
      <Grid container spacing={4}>
        {uniqueProductsList.map((product) => (
          <Grid item key={product.id} xs={6} md={4} lg={3}>
            <AProduct
              product={product}
              urlIsMyBoutiqueAndImOwner={urlIsMyBoutiqueAndImOwner}
              handleReplaceAfterEditProduct={handleReplaceAfterEditProduct}
              handleRemoveAfterDeleted={handleRemoveAfterDeleted}
              showSnackBarMessage={showSnackBarMessage}
              urlIsMyCatalogueAndImOwner={urlIsMyCatalogueAndImOwner}
              dossierId={dossierId}
              goToViewProduct={goToViewProduct}
              goToViewCategoryProducts={goToViewCategoryProducts}
              goToViewBoutique={goToViewBoutique}
              handleDialogLoginOpen={handleDialogLoginOpen}
            />
          </Grid>
        ))}
      </Grid>

      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
    </>
  );
};

export default ProductsCmp;

const AProduct = ({
  product,
  urlIsMyBoutiqueAndImOwner,
  handleReplaceAfterEditProduct,
  handleRemoveAfterDeleted,
  showSnackBarMessage,
  urlIsMyCatalogueAndImOwner,
  dossierId,
  goToViewProduct,
  goToViewCategoryProducts,
  goToViewBoutique,
  handleDialogLoginOpen,
}) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const openMenu = Boolean(anchorElMenu);

  const [openModalEditProd, setOpenModalEditProd] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openModalEmptyDossiers, setOpenModalEmptyDossiers] = useState(false);
  const { t } = useTranslation();

  // modal add images
  const [openModalAddProductImages, setOpenModalAddProductImages] =
    useState(false);
  const handleOpenModalAddProductImages = () =>
    setOpenModalAddProductImages(true);
  const handleCloseModalAddProductImages = () =>
    setOpenModalAddProductImages(false);

  const [openModalAddProductVideos, setOpenModalAddProductVideos] =
    useState(false);
  const handleOpenModalAddProductVideos = () =>
    setOpenModalAddProductVideos(true);
  const handleCloseModalAddProductVideos = () =>
    setOpenModalAddProductVideos(false);

  // share prod vars
  const shareRef = useRef(null);

  //mutations
  const mutationDeleteProduct = useDeleteProductRequest(product.id);
  const mutationMarkProductAvailability = useMarkProductAvailabilityRequest(
    product.id,
    !product.available
  );
  const mutationPinProduct = usePinUnpinProductRequest(product.id, "pin");
  const mutationUnPinProduct = usePinUnpinProductRequest(product.id, "unpin");
  const mutationSaveCart = useAddCartRequest();
  const mutationRemoveCartFromDossier = useDeleteCartFromDossierRequest(
    product.id,
    dossierId
  );

  const handleClickIcons = (e) => {
    e.stopPropagation();
  };

  // menu
  const handleClickOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  // edit
  const handleOpenModalEditProd = () => {
    setOpenModalEditProd(true);
  };
  const handleCloseModalEditProd = () => {
    setOpenModalEditProd(false);
    setProductToEdit(null);
  };

  const handleEditClicked = (e, product) => {
    setProductToEdit(product);
    handleOpenModalEditProd();
  };

  // delete
  const handleOpenDialogDeleteProduct = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDeleteProduct = () => {
    setOpenDialogDelete(false);
  };

  const handleDeleteProduct = () => {
    handleCloseDialogDeleteProduct();
    showSnackBarMessage(t("Deleting_Product_3_dots"), "secondary");
    mutationDeleteProduct.mutate();
  };

  const markProductAvailability = () => {
    mutationMarkProductAvailability.mutate();
  };

  const handlePinUnpinProd = () => {
    if (product.pined) {
      mutationUnPinProduct.mutate();
    } else {
      mutationPinProduct.mutate();
    }
  };

  // share programmatic
  const handleProgrammaticShare = () => {
    shareRef.current?.click();
  };

  const handleOpenEmptyDossiers = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      setOpenModalEmptyDossiers(true);
    }
  };

  const handleCloseEmptyDossiers = () => {
    setOpenModalEmptyDossiers(false);
  };

  // add favorite
  const handleSaveCart = (dossierId) => {
    handleCloseEmptyDossiers();
    const cart = {
      personId: PRINCIPAL?.id || 0,
      productId: product?.id || 0,
      dossierCartId: dossierId || 0,
    };
    mutationSaveCart.mutate(cart);
  };

  const handleRemoveCartFromDossier = () => {
    mutationRemoveCartFromDossier.mutate();
  };

  //    **********    Effects

  useEffect(() => {
    if (mutationDeleteProduct.isError) {
      console.log(mutationDeleteProduct.error);
      showSnackBarMessage(t("Error_Deleting_Product"), "error");
    }

    if (mutationDeleteProduct.isSuccess) {
      const removedProduct = mutationDeleteProduct.data.data;
      showSnackBarMessage(t("Deleted_Product_Successfully"), "success");
      handleRemoveAfterDeleted(removedProduct);
    }
  }, [mutationDeleteProduct.isPending]);

  useEffect(() => {
    if (mutationMarkProductAvailability.isError) {
      console.log(mutationMarkProductAvailability.error);
      showSnackBarMessage(t("Error_occured_3_dots"), "error");
    }

    if (mutationMarkProductAvailability.isSuccess) {
      const editedProduct = { ...product, available: !product.available };
      handleReplaceAfterEditProduct(editedProduct);
      showSnackBarMessage(t("Changed_product_availability"), "success");
    }
  }, [mutationMarkProductAvailability.isPending]);

  useEffect(() => {
    if (mutationPinProduct.isError) {
      console.log(mutationPinProduct.error);
      showSnackBarMessage(t("Error_occured_3_dots"), "error");
    }

    if (mutationPinProduct.isSuccess) {
      const editedProduct = { ...product, pined: true };
      handleReplaceAfterEditProduct(editedProduct);
      showSnackBarMessage(t("Pined_product"), "success");
    }
  }, [mutationPinProduct.isPending]);

  useEffect(() => {
    if (mutationUnPinProduct.isError) {
      console.log(mutationUnPinProduct.error);
      showSnackBarMessage(t("Error_occured_3_dots"), "error");
    }

    if (mutationUnPinProduct.isSuccess) {
      const editedProduct = { ...product, pined: false };
      handleReplaceAfterEditProduct(editedProduct);
      showSnackBarMessage(t("UnPined_product"), "success");
    }
  }, [mutationUnPinProduct.isPending]);

  useEffect(() => {
    if (mutationSaveCart.isError) {
      if (mutationSaveCart.error.response.status === 302) {
        showSnackBarMessage(
          t("Product_already_exits_in_that_catalog"),
          "warning"
        );
      } else {
        console.log(mutationSaveCart.error);
        showSnackBarMessage(t("Error_occured_3_dots"), "error");
      }
    }

    if (mutationSaveCart.isSuccess) {
      showSnackBarMessage(t("Added_product_to_Favorites"), "success");
    }
  }, [mutationSaveCart.isPending]);

  useEffect(() => {
    if (mutationRemoveCartFromDossier.isError) {
      console.log(mutationRemoveCartFromDossier.error);
      showSnackBarMessage(t("Error_Removing_Product"), "error");
    }

    if (mutationRemoveCartFromDossier.isSuccess) {
      showSnackBarMessage(t("Removed_Product_from_Catalog"), "success");
      handleRemoveAfterDeleted(product);
    }
  }, [mutationRemoveCartFromDossier.isPending]);

  return (
    <>
      <Card
        elevation={0}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#E6E6FA",
        }}
      >
        <Box
          sx={{
            position: "relative",
            "&:hover img": { transform: "scale(1.05)" },
            "&:hover .icon-buttons": {
              opacity: 1,
            },
          }}
        >
          <Link to={`/ViewProduct/${product.id}`}>
            <CardMedia
              // onClick={(e) => goToViewProduct(e, product.id)}
              component="img"
              height="250"
              image={product.image}
              alt={product.name}
              sx={{
                width: "100%",
                transition: "transform 0.5s ease",
                transformOrigin: "center",
                cursor: "pointer",
                // objectFit: { xs: "contain", md: "cover" },
              }}
            />
          </Link>

          {/* Begin Icons */}
          <>
            <IconButton
              onClick={handleClickOpenMenu}
              aria-label="more-vert"
              color="inherit"
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                display: { xs: "flex", md: "none" },
              }}
            >
              <MoreVert />
            </IconButton>

            {(urlIsMyBoutiqueAndImOwner || urlIsMyCatalogueAndImOwner) && (
              <IconButton
                onClick={handleClickOpenMenu}
                aria-label="more-vert"
                color="inherit"
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <MoreVert />
              </IconButton>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorElMenu}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={(e) => {
                  handleCloseMenu();
                  window.open(`/viewProduct/${product.id}`, "_blank");
                }}
              >
                <OpenInNew /> {t("View")}
              </MenuItem>

              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={(e) => {
                  handleCloseMenu();
                  handleOpenEmptyDossiers();
                }}
              >
                <FavoriteBorderOutlined /> {t("Add_to_favorites")}
              </MenuItem>

              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
                onClick={(e) => {
                  handleCloseMenu();
                  handleProgrammaticShare();
                }}
              >
                <ShareOutlined /> {t("to_Share")}
              </MenuItem>

              {urlIsMyCatalogueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    handleRemoveCartFromDossier(product);
                  }}
                >
                  <Delete /> {t("to_Remove")}
                </MenuItem>
              )}

              {urlIsMyBoutiqueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    handlePinUnpinProd();
                  }}
                >
                  <PushPinOutlined />{" "}
                  {product.pined ? t("Unpin_product") : t("Pin_product")}
                </MenuItem>
              )}

              {urlIsMyBoutiqueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    markProductAvailability();
                  }}
                >
                  <BlockOutlined />
                  {product.available
                    ? t("Mark_UnAvailable")
                    : t("Mark_Available")}
                </MenuItem>
              )}

              {urlIsMyBoutiqueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    handleEditClicked(e, product);
                  }}
                >
                  <EditOutlined />
                  {t("Edit_Product")}
                </MenuItem>
              )}

              {urlIsMyBoutiqueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    handleOpenModalAddProductImages();
                  }}
                >
                  <CameraAltOutlined />
                  {t("Add_Images")}
                </MenuItem>
              )}

              {urlIsMyBoutiqueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    handleOpenModalAddProductVideos();
                  }}
                >
                  <OndemandVideoOutlined />
                  {t("Add_Videos")}
                </MenuItem>
              )}

              {urlIsMyBoutiqueAndImOwner && (
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                  onClick={(e) => {
                    handleCloseMenu();
                    handleOpenDialogDeleteProduct();
                  }}
                >
                  <Delete color="error" />
                  {t("Delete_Product")}
                </MenuItem>
              )}
            </Menu>
            <Box
              className="icon-buttons"
              sx={{
                position: "absolute",
                top: 55,
                right: 10,
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                gap: 1,
                opacity: 0,
                transition: "opacity 0.3s ease",
              }}
            >
              <IconButton color="inherit" size="small" aria-label="view">
                <Link
                  to={`/viewProduct/${product.id}`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <OpenInNew />
                </Link>
              </IconButton>

              <IconButton
                onClick={(e) => {
                  handleOpenEmptyDossiers();
                }}
                size="small"
                aria-label="add to favorites"
              >
                <Checkbox
                  icon={<FavoriteBorderOutlined sx={{ color: "black" }} />}
                  checkedIcon={<Favorite sx={{ color: "red" }} />}
                />
              </IconButton>

              <RWebShare
                data={{
                  text: "Product",
                  url: `${FRONT_URL}/ViewProduct/${product.id}`,
                  title: `${t("Share_Product")} "${FRONT_URL}/ViewProduct/${
                    product.id
                  }"`,
                }}
              >
                <IconButton
                  ref={shareRef}
                  color="inherit"
                  size="small"
                  aria-label="share"
                >
                  <Checkbox
                    size="small"
                    icon={<ShareOutlined sx={{ color: "black" }} />}
                    checkedIcon={<ShareOutlined sx={{ color: "black" }} />}
                  />
                </IconButton>
              </RWebShare>
            </Box>
            {/* End Icons */}
          </>
        </Box>

        <CardContent sx={{ paddingX: 1, mt: "auto" }}>
          <Typography
            onClick={() => goToViewCategoryProducts(product.categoryId)}
            variant="body2"
            color="text.secondary"
            fontSize={13}
            sx={{
              cursor: "pointer",
              width: "fit-content",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              "&:hover": {
                color: "secondary.main",
                textDecoration: "underline",
              },
            }}
          >
            {product.category}
          </Typography>
          <Typography
            onClick={(e) => goToViewProduct(e, product.id)}
            variant="h6"
            fontSize={14}
            sx={{
              cursor: "pointer",
              width: "fit-content",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {product.name}
          </Typography>
          <Typography
            onClick={(e) => goToViewProduct(e, product.id)}
            variant="h6"
            fontSize={14}
            sx={{
              cursor: "pointer",
              width: "fit-content",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {product.priceCode} {product.price}
          </Typography>

          {product.boutique && (
            <Typography
              onClick={() => goToViewBoutique(product.boutique.id)}
              variant="h6"
              fontSize={14}
              sx={{
                cursor: "pointer",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                "&:hover": {
                  color: "secondary.main",
                  textDecoration: "underline",
                },
              }}
            >
              <StoreOutlined fontSize="small" /> {product.boutique?.name}
            </Typography>
          )}
          {product.subAdminArea && (
            <Typography
              variant="h6"
              fontSize={14}
              sx={{
                cursor: "pointer",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <LocationOnOutlined fontSize="small" /> {product.subAdminArea}
            </Typography>
          )}
        </CardContent>
      </Card>

      {openModalEditProd && (
        <ModalEditProductCmp
          productToEdit={productToEdit}
          setProductToEdit={setProductToEdit}
          openModal={openModalEditProd}
          handleCloseModal={handleCloseModalEditProd}
          handleReplaceAfterEditProduct={handleReplaceAfterEditProduct}
          showSnackBarMessage={showSnackBarMessage}
        />
      )}

      {openDialogDelete && (
        <DialogDeleteProductCmp
          openDialog={openDialogDelete}
          handleCloseDialog={handleCloseDialogDeleteProduct}
          handleDelete={handleDeleteProduct}
        />
      )}

      {openModalEmptyDossiers && (
        <ModalEmptyDossiers
          product={product}
          open={openModalEmptyDossiers}
          handleClose={handleCloseEmptyDossiers}
          handleSaveCart={handleSaveCart}
          showSnackBarMessage={showSnackBarMessage}
        />
      )}

      {urlIsMyBoutiqueAndImOwner && openModalAddProductImages && (
        <ModalAddProductImages
          open={openModalAddProductImages}
          handleClose={handleCloseModalAddProductImages}
        />
      )}

      {urlIsMyBoutiqueAndImOwner && openModalAddProductVideos && (
        <ModalAddProductVideos
          open={openModalAddProductVideos}
          handleClose={handleCloseModalAddProductVideos}
        />
      )}
    </>
  );
};

// Cmp Dialog Delete Product
const DialogDeleteProductCmp = ({
  openDialog,
  handleCloseDialog,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" />
        {t("Delete_this_product")} ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}>
          {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialog}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("to_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
