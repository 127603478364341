import {
  CloseOutlined,
  LogoutOutlined,
  MenuOutlined,
  PersonOutline,
  SearchOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PRINCIPAL } from "../../myHelpers/constants";
import SearchViewCmp from "./SearchViewCmp";
import PopoverProdLocationNav from "./PopoverProdLocationNav";
import PopoverNotificationsCmp from "./PopoverNotificationsCmp";
import PopoverOrdersCmp from "./PopoverOrdersCmp";
import LanguageSelectCmp from "../ui/LanguageSelectCmp";
import { t } from "i18next";
import DeviceAndroidComponent from "../ui/DeviceAndroidComponent";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  backgroundColor: "background.default",
  alignItems: "center",
  justifyContent: "space-between",
  alignSelf: "center",
  width: "80%",
});

const StyledButton = styled(Button)({
  textTransform: "none",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 15,
  alignItems: "center",
}));

const IconsMini = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 1,
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const menuList = ["", "Posts", "Videos"];

const Navbar = ({
  setOpenDrawer,
  getUrl,
  navTo,
  setNavTo,
  handleOpenModalUseMobileApp,
}) => {
  const navigate = useNavigate();
  // menu Prof vars
  const [anchorElProf, setAnchorElProf] = useState(null);
  const openMenuProf = Boolean(anchorElProf);
  const [openSearchMobile, setOpenSearchMobile] = useState(false);

  const [openDialogLogout, setOpenDialogLogout] = useState(false);
  const [profileName, setProfileName] = useState(
    PRINCIPAL?.firstname.slice(0, 5) || "Log In"
  );

  // menu Profile logic
  const handleOpenMenuProf = (event) => {
    if (PRINCIPAL) {
      event.stopPropagation();
      setAnchorElProf(event.currentTarget);
    } else {
      window.location.href = "/signIn";
    }
  };

  const handleCloseMenuProf = (event) => {
    event.stopPropagation();
    setAnchorElProf(null);
  };

  const handleGoToProfile = (e) => {
    handleCloseMenuProf(e);
    navigate("/profile");
  };

  // menu nav logic
  const handleMenuClick = (index, menuItem) => {
    navigate(getUrl(menuItem));
    setNavTo(index);
  };

  const handleGoToProfileSettings = (e) => {
    handleCloseMenuProf(e);
    navigate("profile/about");
  };

  // openSearchMobile logic
  const handleOpenSearchMobile = () => {
    setOpenSearchMobile(true);
  };
  const handleCloseSearchMobile = () => {
    setOpenSearchMobile(false);
  };

  // logout
  const handleOpenDialogLogout = () => {
    setOpenDialogLogout(true);
  };

  const handleCloseDialogLogout = () => {
    setOpenDialogLogout(false);
  };

  const handleLogout = () => {
    handleCloseDialogLogout();
    localStorage.clear();
    window.location.href = "/signIn";
  };

  return (
    <AppBar position="sticky" color="inherit" elevation={1}>
      <DeviceAndroidComponent />
      <StyledToolbar
        sx={{ width: { xs: "97%", sm: "93%", md: "97%", lg: "80%" } }}
      >
        <IconButton
          color="inherit"
          sx={{ display: { sm: "block", md: "none" } }}
          onClick={() => setOpenDrawer(true)}
        >
          <MenuOutlined />
        </IconButton>

        <Link
          to={`/`}
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <img
            src="/images/logo_100_px.png"
            width="30"
            height="30"
            alt="Logo"
          />
          <Typography
            variant="h6"
            sx={{
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: { xs: 14, md: 16 },
              "&:hover": {
                color: "secondary.main",
              },
            }}
          >
            Shop itemz
          </Typography>
        </Link>

        <StyledStack direction="row">
          <Link to={`/`} style={{ textDecoration: "none", color: "inherit" }}>
            <StyledButton
              size="small"
              variant="text"
              // onClick={() => handleMenuClick(0, menuList[0])}
              color={navTo === 0 ? "secondary" : "inherit"}
            >
              {t("Home")}
            </StyledButton>
          </Link>

          <Link
            to={`/Posts`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledButton
              size="small"
              variant="text"
              // onClick={() => handleMenuClick(1, menuList[1])}
              color={navTo === 1 ? "secondary" : "inherit"}
            >
              {t("Posts")}
            </StyledButton>
          </Link>

          <Link
            to={`/Videos`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledButton
              size="small"
              variant="text"
              // onClick={() => handleMenuClick(2, menuList[2])}
              color={navTo === 2 ? "secondary" : "inherit"}
            >
              {t("Videos")}
            </StyledButton>
          </Link>
        </StyledStack>

        <SearchViewCmp handleCloseSearchMobile={handleCloseSearchMobile} />

        <Icons>
          {openSearchMobile ? (
            <IconButton
              onClick={handleCloseSearchMobile}
              color="inherit"
              size="small"
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              <CloseOutlined fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleOpenSearchMobile}
              color="inherit"
              size="small"
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              <SearchOutlined fontSize="inherit" />
            </IconButton>
          )}

          <PopoverProdLocationNav />

          <PopoverNotificationsCmp />

          {/* Messanger */}
          {/* <IconButton color="inherit" size="small">
            <MessengerIcon />
          </IconButton> */}

          <PopoverOrdersCmp />
        </Icons>

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <LanguageSelectCmp />
        </Box>

        {/* <IconButton
          color="inherit"
          sx={{
            fontSize: 14,
            border: "1px solid black",
            borderRadius: 0,
            padding: 0.2,
            display: { xs: "none", md: "flex" },
          }}
          size="small"
        >
          En
          <KeyboardArrowDownOutlined fontSize="small" />
        </IconButton> */}

        <Stack
          direction="row"
          onClick={handleOpenMenuProf}
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Avatar
            sx={{ width: 30, height: 30, border: "2px solid gray" }}
            src={PRINCIPAL?.profile}
          />
          <Typography
            variant="span"
            sx={{
              display: { xs: "none", md: "inline-block" },
            }}
          >
            {profileName}
          </Typography>
        </Stack>

        {/* Menu profile */}
        <Menu
          id="basic-menu"
          anchorEl={anchorElProf}
          open={openMenuProf}
          onClose={handleCloseMenuProf}
        >
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
            onClick={handleGoToProfile}
          >
            <PersonOutline fontSize="small" />
            {t("Profile")}
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
            onClick={handleGoToProfileSettings}
          >
            <SettingsOutlined fontSize="small" />
            {t("Settings")}
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
            onClick={(e) => {
              handleCloseMenuProf(e);
              handleOpenDialogLogout();
            }}
          >
            <LogoutOutlined fontSize="small" />
            {t("to_Log_out")}
          </MenuItem>
        </Menu>
      </StyledToolbar>
      {/* to show on mobile devices */}
      <SearchViewCmp
        mobile={true}
        openSearchMobile={openSearchMobile}
        handleCloseSearchMobile={handleCloseSearchMobile}
      />

      <DialogLogout
        openDialog={openDialogLogout}
        handleCloseDialog={handleCloseDialogLogout}
        handleLogout={handleLogout}
      />
    </AppBar>
  );
};
export default Navbar;

// Cmp Dialog Logout
const DialogLogout = ({ openDialog, handleCloseDialog, handleLogout }) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <LogoutOutlined />
        {t("to_Log_out")} ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}>
          {t("You_are_about_to_logout")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialog}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleLogout}
          variant="contained"
          color="error"
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("to_Log_out")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
