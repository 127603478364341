import { Box } from "@mui/material";
import { AStore } from "../ui/AStore";

export const MyStoresCmp = ({ storesList, setMyStoresList }) => {
  // make sure that every store is rendered once only
  const uniqueStores = storesList.filter(
    (store, index, self) => index === self.findIndex((s) => s.id === store.id)
  );

  // replace Edited Store with new one
  const handleReplaceAfterEditStore = (newBoutique) => {
    const newStoreList = storesList.map((store) => {
      if (newBoutique.id === store.id) {
        return newBoutique;
      } else {
        return store;
      }
    });

    setMyStoresList(newStoreList);
  };

  // remove deleted store from list
  const handleDeletedStore = (deletedStore) => {
    setMyStoresList((prevList) =>
      prevList.filter((row) => row.id !== deletedStore.id)
    );
  };

  return (
    <Box>
      {uniqueStores.map((store) => (
        <AStore
          store={store}
          key={store.id}
          handleReplaceAfterEditStore={handleReplaceAfterEditStore}
          handleDeletedStore={handleDeletedStore}
        />
      ))}
    </Box>
  );
};
