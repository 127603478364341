import { Add, AddOutlined, StoreOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCreateBoutiqueRequest } from "../../requestsApis/boutiqueRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useTranslation } from "react-i18next";

export const CreateStoreCmp = ({ setMyStoresList, pageSize }) => {
  const [open, setOpen] = useState(false);
  const [boutique, setBoutique] = useState({
    name: "",
    personId: PRINCIPAL?.id || 0,
  });

  const { t } = useTranslation();

  const mutationCreateStore = useCreateBoutiqueRequest(boutique);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBoutique({ ...boutique, name: "" });
  };

  const handleChange = (e) => {
    setBoutique({ ...boutique, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (boutique.name && boutique.name.trim().length > 2) {
      setOpen(false);
      mutationCreateStore.mutate();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && boutique.name.trim().length > 2) {
      handleSave();
    }
  };

  useEffect(() => {
    if (mutationCreateStore.isError) {
      console.log(mutationCreateStore.error);
    }
    if (mutationCreateStore.isSuccess && mutationCreateStore.data) {
      handleClose();
      setMyStoresList((prevList) => [
        mutationCreateStore.data.data,
        ...prevList,
      ]);
      pageSize.current += 1;
    }
  }, [mutationCreateStore.isPending]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">{t("My_Stores")}</Typography>
        <Button
          startIcon={<Add />}
          color="secondary"
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            textTransform: "none",
          }}
        >
          {t("Add_New_Store")}
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <StoreOutlined />
          <span>{t("Add_New_Store")}</span>
        </DialogTitle>
        <DialogContent>
          <TextField
            focused
            required
            margin="dense"
            id="name"
            name="name"
            label={t("Enter_a_name")}
            fullWidth
            variant="standard"
            color="secondary"
            value={boutique.name}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={{ textTransform: "none", paddingX: 5 }}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            autoFocus
            sx={{ textTransform: "none", paddingX: 5 }}
            disabled={boutique.name.trim().length < 3}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
