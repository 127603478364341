import { ShoppingCartOutlined } from "@mui/icons-material";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetMyOrdersRequest } from "../../requestsApis/myOrdersRequest";
import MyOrdersMiniCmp from "./MyOrdersMiniCmp";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useTranslation } from "react-i18next";

export default function PopoverOrdersCmp() {
  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  // Popover My Orders logic
  const [anchorElPopoverMyOrders, setAnchorElPopoverMyOrders] = useState(null);
  const openPopoverMyOrders = Boolean(anchorElPopoverMyOrders);
  const idPopoverMyOrders = openPopoverMyOrders ? "simple-popover" : undefined;

  // my orders logic
  const [myOrdersList, setMyOrdersList] = useState([]);
  let pageNum = useRef(0);
  let pageSize = useRef(10);
  let isFinished = useRef(false);
  const { t } = useTranslation();

  //  Queries && Mutations
  const queryGetMyOrders = useGetMyOrdersRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current
  );

  // Popover My Orders logic methods
  const handleClickPopoverMyOrders = (e) => {
    setAnchorElPopoverMyOrders(e.currentTarget);
  };
  const handleClosePopoverMyOrders = () => {
    setAnchorElPopoverMyOrders(null);
  };

  const loadMore = () => {
    if (!isFinished.current && !queryGetMyOrders.isLoading) {
      pageNum.current += 1;
      queryGetMyOrders.refetch();
    }
  };

  // *************     Use Effects
  useEffect(() => {
    if (!PRINCIPAL && openPopoverMyOrders) {
      handleDialogLoginOpen();
    }
  }, [openPopoverMyOrders]);

  useEffect(() => {
    if (queryGetMyOrders.isError) {
      console.log(queryGetMyOrders.error);
    }

    if (queryGetMyOrders.isSuccess && queryGetMyOrders.data) {
      setMyOrdersList((prevList) => [
        ...prevList,
        ...queryGetMyOrders.data.data,
      ]);

      if (queryGetMyOrders.data.data.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetMyOrders.isPending]);

  return (
    <>
      <DialogLoginComponent />

      <IconButton
        onClick={handleClickPopoverMyOrders}
        color="inherit"
        size="small"
        sx={{
          backgroundColor: openPopoverMyOrders ? "#E6E6FA" : "inherit",
        }}
      >
        <ShoppingCartOutlined fontSize="inherit" />
      </IconButton>
      <Popover
        sx={{ marginTop: 2, marginBottom: 4 }}
        id={idPopoverMyOrders}
        open={openPopoverMyOrders}
        anchorEl={anchorElPopoverMyOrders}
        onClose={handleClosePopoverMyOrders}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            padding: 2,
            width: { xs: "83vw", sm: 400 },
            minHeight: 450,
          }}
        >
          <Typography variant="h6" mb={2} fontWeight="bold" textAlign="center">
            {t("My_sent_orders")}
          </Typography>

          <MyOrdersMiniCmp
            myOrdersList={myOrdersList}
            isFinished={isFinished}
            queryGetMyOrders={queryGetMyOrders}
            loadMore={loadMore}
          />
        </Box>
      </Popover>
    </>
  );
}
