import { useQuery } from "@tanstack/react-query";
import { BACK_URL } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/productVideo";

// get Random Videos
export const useGetRandomVideosRequest = (pageSize, videosIds) => {
  const queryParams = {
    pageSize: pageSize,
    videosIds: videosIds,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/random/videos?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
  });
};

// get Product Videos
export const useGetProductVideosRequest = (pageNum, pageSize, productId) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/productVideos/${productId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
  });
};
