import {
  Delete,
  DeleteOutline,
  EditOutlined,
  MoreVertOutlined,
  ShareOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import React, { useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import {
  useDeleteBoutiqueRequest,
  useEditBoutiqueRequest,
} from "../../requestsApis/boutiqueRequest";
import { RWebShare } from "react-web-share";
import { FRONT_URL } from "../../myHelpers/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AStore = ({
  store,
  handleReplaceAfterEditStore,
  handleDeletedStore,
}) => {
  const navigate = useNavigate();
  // menu vars
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // edit boutique vars
  const [openEditStore, setOpenEditStore] = useState(false);
  // keep old boutique details before editing name of boutique
  const [newBoutique, setNewBoutique] = useState({
    id: store.id,
    name: store.name,
  });

  // delete boutique vars
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  // share boutique vars
  const shareRef = useRef(null);
  const { t } = useTranslation();

  // mutation edit boutique name
  const mutationEditStore = useEditBoutiqueRequest(newBoutique);
  // mutation delete boutique
  const mutationDeleteStore = useDeleteBoutiqueRequest(store.id);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // edit boutique logic
  const handleOpenEditStore = () => {
    setOpenEditStore(true);
  };

  const handleCloseEditStore = () => {
    setOpenEditStore(false);
  };

  // save edited boutique name
  const handleSave = () => {
    handleCloseEditStore();
    mutationEditStore.mutate();
  };

  // delete boutique logic
  const handleOpenDialogDeleteStore = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDeleteStore = () => {
    setOpenDialogDelete(false);
  };

  const handleDeleteStore = () => {
    handleCloseDialogDeleteStore();
    mutationDeleteStore.mutate();
  };

  // share programmatic
  const handleProgrammaticShare = () => {
    shareRef.current?.click();
  };

  // View My Boutique
  const handleViewMyBoutique = (event) => {
    if (event.target === event.currentTarget) {
      navigateToViewBoutique();
    } else {
    }
  };

  // navigate to View My Boutique after Paper Clicked, or Store, Name, CreatedAt Clicked
  const navigateToViewBoutique = () => {
    navigate(`/profile/myBoutique/${store.id}`);
  };

  // edit effect
  useEffect(() => {
    if (mutationEditStore.isError) {
      console.log(mutationEditStore.error);
    }
    if (mutationEditStore.isSuccess) {
      handleReplaceAfterEditStore(mutationEditStore.data.data);
    }
  }, [mutationEditStore.isPending]);

  // delete effect
  useEffect(() => {
    if (mutationDeleteStore.isError) {
    }

    if (mutationDeleteStore.isSuccess) {
      handleDeletedStore(mutationDeleteStore.data.data);
    }
  }, [mutationDeleteStore.isPending]);

  return (
    <>
      <Paper
        variant="outlined"
        onClick={handleViewMyBoutique}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
          marginBottom: 2,
          borderRadius: 0,
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        }}
      >
        <Box
          onClick={navigateToViewBoutique}
          sx={{
            textAlign: "center",
            width: { xs: "25%", md: "15%" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              cursor: "default",
              width: 40,
              height: 40,
              border: "1px solid gray",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {store.baniere && store.baniere.length > 20 ? (
              <Box
                component="img"
                src={store.baniere}
                alt="Boutique img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <StoreOutlined
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </Box>
        </Box>

        {openEditStore ? (
          <EditStoreCmp
            store={store}
            handleCloseEditStore={handleCloseEditStore}
            newBoutique={newBoutique}
            setNewBoutique={setNewBoutique}
            handleSave={handleSave}
          />
        ) : (
          <>
            <Typography
              onClick={navigateToViewBoutique}
              sx={{
                textAlign: "center",
                width: { xs: "50%", md: "40%" },
                cursor: "default",
                fontSize: 15,
              }}
            >
              {mutationEditStore.isPending ? (
                <CircularProgress size={20} />
              ) : (
                store.name
              )}
            </Typography>
            <Typography
              variant="body2"
              onClick={navigateToViewBoutique}
              color="textSecondary"
              sx={{
                textAlign: "center",
                width: "30%",
                display: { xs: "none", md: "inline-block" },
                cursor: "default",
              }}
            >
              {myFormatDate(store.createdAt)}
            </Typography>

            {/* Actions Buttons */}
            <Box
              sx={{
                textAlign: "center",
                width: { xs: "25%", md: "15%" },
                display: "flex",
                gap: 1,
                justifyContent: "center",
              }}
            >
              {mutationDeleteStore.isPending ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
                    <IconButton
                      onClick={handleOpenEditStore}
                      sx={{
                        color: "#348781",
                        backgroundColor: "#CFECEC",
                        borderRadius: 2,
                      }}
                    >
                      <FiEdit fontSize="medium" />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={handleOpenDialogDeleteStore}
                      sx={{
                        backgroundColor: "pink",
                        borderRadius: 2,
                      }}
                    >
                      <FaRegTrashCan fontSize="medium" />
                    </IconButton>
                    <RWebShare
                      data={{
                        text: store.name,
                        url: `${FRONT_URL}/boutique/view/boutique?boutiqueId=${store.id}&personId=0`,
                        title: `${t("to_Share")} (${
                          store.name
                        }) "${FRONT_URL}/boutique/view/boutique?boutiqueId=${
                          store.id
                        }&personId=0"`,
                      }}
                    >
                      <IconButton color="inherit" ref={shareRef}>
                        <ShareOutlined fontSize="small" />
                      </IconButton>
                    </RWebShare>
                  </Box>
                  <IconButton
                    color="inherit"
                    onClick={(event) => handleOpenMenu(event)}
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <MoreVertOutlined fontSize="small" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                      onClick={(e) => {
                        handleCloseMenu(e);
                        handleProgrammaticShare();
                      }}
                    >
                      <ShareOutlined /> {t("to_Share")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                      onClick={(e) => {
                        handleCloseMenu(e);
                        handleOpenEditStore();
                      }}
                    >
                      <EditOutlined />
                      {t("Edit_name")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                      onClick={(e) => {
                        handleCloseMenu(e);
                        handleOpenDialogDeleteStore();
                      }}
                    >
                      <Delete color="error" />
                      {t("to_Delete")}
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </>
        )}
      </Paper>
      {/* Dialog Delete Boutique ? */}
      <DialogDeleteBoutiqueCmp
        openDialogDelete={openDialogDelete}
        handleCloseDialogDeleteStore={handleCloseDialogDeleteStore}
        store={store}
        handleDeleteStore={handleDeleteStore}
      />
    </>
  );
};

// Cmp Dialog Delete Boutique
const DialogDeleteBoutiqueCmp = ({
  openDialogDelete,
  handleCloseDialogDeleteStore,
  store,
  handleDeleteStore,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openDialogDelete}
      onClose={handleCloseDialogDeleteStore}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" />
        <span>
          {t("to_Delete")}{" "}
          <i>
            <u>"{store.name}"</u>
          </i>{" "}
          {t("Store")} ?
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}>
          {t("You_are_about_to_delete")}{" "}
          <i>
            <u>"{store.name}"</u>
          </i>{" "}
          {t("Store_and_all_the_Products_included")} !
          <br /> {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialogDeleteStore}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteStore}
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Cmp Edit Name
const EditStoreCmp = ({
  store,
  handleCloseEditStore,
  newBoutique,
  setNewBoutique,
  handleSave,
}) => {
  const handleChange = (e) => {
    setNewBoutique({ ...newBoutique, name: e.target.value });
  };

  const { t } = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        store.name.trim() === newBoutique.name.trim() ||
        newBoutique.name.trim().length < 3
      ) {
        // give warning
      } else {
        handleSave();
      }
    }
  };

  return (
    <>
      <TextField
        autoFocus
        color="secondary"
        size="small"
        label={t("Enter_a_name")}
        variant="standard"
        defaultValue={store.name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: { xs: 1, md: 2 } }}
      >
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={handleCloseEditStore}
        >
          {t("Cancel")}
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleSave}
          disabled={
            store.name.trim() === newBoutique.name.trim() ||
            newBoutique.name.trim().length < 3
          }
        >
          {t("Save")}
        </Button>
      </Box>
    </>
  );
};
