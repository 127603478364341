import { useQuery } from "@tanstack/react-query";
import { BACK_URL } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/currencyCode";

// View All Currencies Request
export const useViewAllCurrenciesRequest = (enabled) => {
  const fullUrl = `${BACK_URL}${basePath}/view/allCurrencyCode`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};
