import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CreateFavoritesFolderCmp } from "../../components/largeComponents/CreateFavoritesFolderCmp";
import { useGetDossiersRequest } from "../../requestsApis/dossiersRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import MyDossiersCmp from "../../components/largeComponents/MyDossiersCmp";
import { LoadMoreBtnCmp } from "../../components/ui/LoadMoreBtnCmp";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const FavoritesPage = ({ setSelectedTab }) => {
  const [dossierList, setDossierList] = useState([]);
  let pageNum = useRef(0);
  let pageSize = useRef(10);
  let isFinished = useRef(false);

  const queryGetDossiers = useGetDossiersRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current
  );

  const loadMore = () => {
    if (!isFinished.current && !queryGetDossiers.isLoading) {
      pageNum.current += 1;
      queryGetDossiers.refetch();
    }
  };

  // Use Effects
  useEffect(() => {
    setSelectedTab(1);
  }, []);

  useEffect(() => {
    if (queryGetDossiers.isError) {
      console.log(queryGetDossiers.error);
    }

    if (queryGetDossiers.isSuccess && queryGetDossiers.data) {
      setDossierList((prevList) => [
        ...prevList,
        ...queryGetDossiers.data.data,
      ]);

      if (queryGetDossiers.data.data.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetDossiers.isPending]);

  return (
    <Box>
      <DialogLoginExpiredComponent />
      <CreateFavoritesFolderCmp
        setDossierList={setDossierList}
        pageSize={pageSize}
      />

      <TableauHeaderFavoritesCmp />

      <MyDossiersCmp
        dossierList={dossierList}
        setDossierList={setDossierList}
      />

      <LoadMoreBtnCmp
        isFinished={isFinished}
        query={queryGetDossiers}
        loadMore={loadMore}
      />
    </Box>
  );
};
export default FavoritesPage;

// Tableau Header
const TableauHeaderFavoritesCmp = () => {
  const { t } = useTranslation();

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
        backgroundColor: "#e0e0e0",
        borderRadius: 0,
      }}
    >
      <Typography sx={{ textAlign: "center", width: { xs: "20%", md: "15%" } }}>
        {t("Catalog")}
      </Typography>

      <Typography sx={{ textAlign: "center", width: { xs: "60%", md: "25%" } }}>
        {t("Name")}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          width: "20%",
          display: { xs: "none", md: "inline-block" },
        }}
      >
        {t("Total_products")}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          width: "25%",
          display: { xs: "none", md: "inline-block" },
        }}
      >
        {t("Date")}
      </Typography>

      <Typography sx={{ textAlign: "center", width: { xs: "20%", md: "15%" } }}>
        {t("Actions")}
      </Typography>
    </Paper>
  );
};
