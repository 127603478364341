import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  AddCircleOutline,
  AddShoppingCartOutlined,
  Close,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { Avatar, Card, IconButton, TextField } from "@mui/material";
import { PRINCIPAL } from "../../myHelpers/constants";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useState } from "react";
import { useAddMyOrderRequest } from "../../requestsApis/myOrdersRequest";
import { useEffect } from "react";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useViewAddressOfPersonRequest } from "../../requestsApis/myAddressRequest";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "80%", md: "60%", lg: "40%" },
  // height: 650,
  height: "77vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function ModalAddOrderCmp({
  product,
  boutique,
  showSnackBarMessage,
}) {
  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  const [open, setOpen] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [subtotal, setSubTotal] = useState(product.price);
  const [fee, setFee] = useState(0.0);
  const [total, setTotal] = useState(product.price + fee);
  // place order logic
  const [description, setDescription] = useState("");
  const [idPerson, setIdPerson] = useState(0);
  const [deliveryAddressOfPerson, setDeliveryAddressOfPerson] = useState(null);
  const { t } = useTranslation();

  // mutations
  const mutationAddOrder = useAddMyOrderRequest();
  const queryViewAddressOfPerson = useViewAddressOfPersonRequest(idPerson);

  const handleOpen = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      setOpen(true);

      // fetch Address on Open, id != 0
      if (!deliveryAddressOfPerson) {
        setIdPerson(PRINCIPAL.id);
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
    setQuantity(1);
    setSubTotal(product.price);
    setTotal(product.price + fee);
  };

  const handleAddQuantity = () => {
    let newQuantity = quantity + 1;
    setQuantity(newQuantity);

    let newSubtotal = product.price * newQuantity;
    setSubTotal(newSubtotal);
    setTotal(newSubtotal + fee);
  };

  const handleReduceQuantity = () => {
    if (quantity > 1) {
      let newQuantity = quantity - 1;
      setQuantity(newQuantity);

      let newSubtotal = product.price * newQuantity;
      setSubTotal(newSubtotal);
      setTotal(newSubtotal + fee);
    }
  };

  const handleChangeComment = (e) => {
    setDescription(e.target.value);
  };

  const handlePlaceOrder = () => {
    let productToAdd = {
      ...product,
      createdAt: null,
      modifiedAt: null,
      boutique: null,
    };
    let productOfOrder = {
      productId: product.id,
      creatorId: PRINCIPAL?.id || 0,
      quantity: quantity,
      product: productToAdd,
    };

    let productOfOrderList = [productOfOrder];

    let myOrder = {
      senderId: PRINCIPAL?.id || 0,
      receiverId: boutique?.person?.id || 0,
      boutiqueId: product.boutiqueId,
      deliveryAddressId: 0,
      description: description,
      productOfOrderList: productOfOrderList,
      totalPrice: total,
      totalProducts: productOfOrderList.length,
      paid: false,
    };

    mutationAddOrder.mutate(myOrder);
    handleClose();
  };

  // useEffect
  useEffect(() => {
    if (mutationAddOrder.isError) {
      console.log(mutationAddOrder.error);
      showSnackBarMessage(t("Error"), "error");
    }

    if (mutationAddOrder.isSuccess) {
      showSnackBarMessage(t("Order_Sent"), "success");
      handleClose();
    }
  }, [mutationAddOrder.isPending]);

  useEffect(() => {
    if (queryViewAddressOfPerson.isError) {
      console.log(queryViewAddressOfPerson.error);
    }

    if (queryViewAddressOfPerson.isSuccess && queryViewAddressOfPerson.data) {
      setDeliveryAddressOfPerson(queryViewAddressOfPerson.data.data);
    }
  }, [queryViewAddressOfPerson.isPending]);

  return (
    <>
      <DialogLoginComponent />
      <Button
        onClick={handleOpen}
        variant="contained"
        color="secondary"
        sx={{ width: "50%", textTransform: "none" }}
        endIcon={<AddShoppingCartOutlined />}
        size="small"
      >
        {t("Order_Now")}
      </Button>

      {PRINCIPAL && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* Top Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
                borderBottom: "1px solid gray",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("Place_Order")}
              </Typography>
              <Button
                onClick={handleClose}
                size="small"
                color="error"
                variant="outlined"
                startIcon={<Close />}
                sx={{ textTransform: "none" }}
              >
                {t("Close")}
              </Button>
            </Box>
            {/* Main conaint */}
            <Box
              sx={{
                position: "relative",
                height: "79%",
                width: "100%",
                overflow: "auto",
                marginRight: 1,
              }}
            >
              <Box sx={{ padding: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Avatar
                    src={PRINCIPAL.profile}
                    sx={{
                      width: 40,
                      height: 40,
                      mr: 2,
                      border: "2px solid gray",
                    }}
                  />

                  <Typography sx={{ wordBreak: "break-word" }}>
                    {PRINCIPAL.firstname} {PRINCIPAL.lastname}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 1,
                  }}
                >
                  <Box>
                    <Typography fontWeight="bold">
                      {t("Attached_Address")} :
                    </Typography>

                    {deliveryAddressOfPerson && (
                      <Typography variant="body2" fontSize={13}>
                        {deliveryAddressOfPerson.addressLines
                          ? deliveryAddressOfPerson.addressLines
                          : `${deliveryAddressOfPerson.locality}, ${deliveryAddressOfPerson.subAdminArea}, ${deliveryAddressOfPerson.adminArea}, ${deliveryAddressOfPerson.countryName}`}
                      </Typography>
                    )}

                    {!deliveryAddressOfPerson && (
                      <Typography variant="body2" fontSize={13}>
                        {t("No_Address_Yet")} !
                      </Typography>
                    )}
                  </Box>

                  <Link to="/profile/about">
                    <Button
                      startIcon={<FiEdit fontSize="medium" />}
                      variant="text"
                      sx={{
                        textTransform: "none",
                        color: "secondary.main",
                      }}
                    >
                      <u>{t("Edit")}</u>
                    </Button>
                  </Link>
                </Box>

                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 3,
                    padding: 1,
                    marginBottom: 2,
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 75,
                      height: 75,
                      border: "1px solid gray",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={product.image}
                      alt="Order img"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "50%", sm: "70%", md: "80%", lg: "90%" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      sx={{
                        cursor: "pointer",
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {product.name}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontSize={13}
                      sx={{
                        cursor: "pointer",
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {product.priceCode} {product.price}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight="bold"
                      sx={{
                        cursor: "pointer",
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {t("TOTAL")} : {product.priceCode} {subtotal}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{t("Qty")}</Typography>
                    <IconButton
                      onClick={handleAddQuantity}
                      size="small"
                      color="inherit"
                    >
                      <AddCircleOutline />
                    </IconButton>
                    <Typography>{quantity}</Typography>
                    <IconButton
                      onClick={handleReduceQuantity}
                      size="small"
                      color="inherit"
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </Box>
                </Card>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  }}
                >
                  <Typography>{t("Subtotal")}</Typography>

                  <Typography>
                    {product.priceCode} {subtotal}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  }}
                >
                  <Typography>{t("Fee")}</Typography>

                  <Typography>
                    {product.priceCode} {fee}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  }}
                >
                  <Typography fontWeight="bold" variant="h6">
                    {t("TOTAL")}
                  </Typography>

                  <Typography fontWeight="bold" variant="h6">
                    {product.priceCode} {total}
                  </Typography>
                </Box>

                <TextField
                  fullWidth
                  placeholder={t("Leave_a_comment_with_the_order")}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  value={description}
                  onChange={handleChangeComment}
                />
              </Box>
            </Box>

            {/* Footer */}
            <Box
              sx={{
                borderTop: "1px solid gray",
                paddingTop: 1,
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={handleClose}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    minWidth: 100,
                  }}
                >
                  {t("Cancel")}
                </Button>

                <Button
                  onClick={handlePlaceOrder}
                  size="small"
                  color="secondary"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    minWidth: 100,
                  }}
                >
                  {t("Order")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
