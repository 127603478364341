import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "50%", lg: "40%" },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function ModalViewProductVideo({
  open,
  handleClose,
  selectedProductVideo,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {selectedProductVideo && (
          <Box
            sx={{
              position: "relative",
              border: "2px solid gray",
              backgroundColor: "gray",
              width: "99%",
              height: "80vh",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: -20,
                top: -20,
                color: "white",
                backgroundColor: "darkgray",
              }}
            >
              <Close />
            </IconButton>
            <video
              src={selectedProductVideo.video}
              controls
              poster={selectedProductVideo.thumb}
              width="100%"
              height="100%"
            >
              {t("You_need_to_update_your_browser_dude")}
            </video>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
