import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/postComment";

// Delete PostComment Request
export const useDeletePostCommentRequest = () => {
  return useMutation({
    mutationFn: async (postCommentId) => {
      const queryParams = {
        postCommentId: postCommentId,
      };
      const queryString = new URLSearchParams(queryParams).toString();

      const fullUrl = `${BACK_URL}${basePath}/deletePostComment?${queryString}`;

      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// View Recent PostComments Request
export const useViewRecentPostCommentsRequest = (
  postId,
  pageNum,
  pageSize,
  enabled
) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/recentPostComments/${postId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

// Add Post Comment Request
export const useAddPostCommentRequest = () => {
  return useMutation({
    mutationFn: async (postComment) => {
      const fullUrl = `${BACK_URL}${basePath}/savePostComment`;

      return await axios.post(fullUrl, postComment, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};
