import { Add, AddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useCreateDossiersRequest } from "../../requestsApis/dossiersRequest";
import { useTranslation } from "react-i18next";

export const CreateFavoritesFolderCmp = ({
  setDossierList,
  pageSize,
  whereFrom,
}) => {
  const [open, setOpen] = useState(false);
  const [dossier, setDossier] = useState({
    name: "",
    creatorId: PRINCIPAL?.id || 0,
  });
  const { t } = useTranslation();

  const mutationCreateDossier = useCreateDossiersRequest(dossier);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDossier({ ...dossier, name: "" });
  };

  const handleChange = (e) => {
    setDossier({ ...dossier, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (dossier.name && dossier.name.trim().length > 2) {
      setOpen(false);
      mutationCreateDossier.mutate();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && dossier.name.trim().length > 2) {
      handleSave();
    }
  };

  useEffect(() => {
    if (mutationCreateDossier.isError) {
      console.log(mutationCreateDossier.error);
    }
    if (mutationCreateDossier.isSuccess && mutationCreateDossier.data) {
      handleClose();
      setDossierList((prevList) => [
        mutationCreateDossier.data.data,
        ...prevList,
      ]);
      pageSize.current += 1;
    }
  }, [mutationCreateDossier.isPending]);

  return (
    <>
      {whereFrom === "emptyDossier" ? (
        <Button
          onClick={handleClickOpen}
          startIcon={<Add />}
          color="secondary"
          variant="text"
          sx={{
            textTransform: "none",
            marginBottom: 1,
          }}
        >
          {t("New_catalog")}
        </Button>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">{t("Favorites")}</Typography>
          <Button
            startIcon={<Add />}
            color="secondary"
            variant="outlined"
            onClick={handleClickOpen}
            sx={{
              textTransform: "none",
            }}
          >
            {t("Add_New_Catalog")}
          </Button>
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <AddOutlined />
          <span>{t("Add_New_Catalog")}</span>
        </DialogTitle>
        <DialogContent>
          <TextField
            focused
            required
            margin="dense"
            id="name"
            name="name"
            label={t("Enter_a_name")}
            fullWidth
            variant="standard"
            color="secondary"
            value={dossier.name}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={{ textTransform: "none", paddingX: 5 }}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            autoFocus
            sx={{ textTransform: "none", paddingX: 5 }}
            disabled={dossier.name.trim().length < 3}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
