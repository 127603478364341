import { Box } from "@mui/material";
import AFollowingStore from "../ui/AFollowingStore";

const MyFollowingStoresCmp = ({
  followingStoresList,
  setFollowingStoresList,
}) => {
  // make sure every followed store is rendered once only
  const uniqueFollowings = followingStoresList.filter(
    (following, index, self) =>
      index === self.findIndex((f) => f.id === following.id)
  );

  // remove deleted following from list
  const handleDeletedFollowing = (deletedFollowing) => {
    setFollowingStoresList((prevList) =>
      prevList.filter((row) => row.id !== deletedFollowing.id)
    );
  };

  return (
    <Box>
      {uniqueFollowings.map((following) => (
        <AFollowingStore
          boutique={following}
          key={following.id}
          handleDeletedFollowing={handleDeletedFollowing}
        />
      ))}
    </Box>
  );
};
export default MyFollowingStoresCmp;
