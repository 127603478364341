import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useDialogLogin = () => {
  const [isDialogLoginOpen, setIsDialogLoginOpen] = useState(false);
  const { t } = useTranslation();

  const handleDialogLoginOpen = () => {
    setIsDialogLoginOpen(true);
  };

  const handleDialogLoginClose = () => {
    setIsDialogLoginOpen(false);
  };

  const DialogLoginComponent = () => (
    <Dialog open={isDialogLoginOpen} onClose={handleDialogLoginClose}>
      <DialogTitle>{t("Login_Required")} !</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "You_need_to_log_in_to_access_this_feature_Please_log_in_to_continue"
          )}{" "}
          !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDialogLoginClose}
          variant="outlined"
          color="secondary"
          sx={{
            textTransform: "none",
            width: { xs: "inherit", sm: "7rem" },
            marginLeft: 5,
          }}
        >
          {t("Close")}
        </Button>
        <Button
          onClick={() => {
            handleDialogLoginClose();
            window.location.href = "/signIn";
          }}
          color="secondary"
          variant="contained"
          sx={{
            textTransform: "none",
            width: { xs: "inherit", sm: "7rem" },
            marginLeft: 5,
            whiteSpace: "nowrap",
          }}
        >
          {t("To_Login")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  };
};
