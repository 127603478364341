import { Grid } from "@mui/material";
import APost from "../ui/APost";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { CustomSnackbar } from "../ui/CustomSnackbar";

const MyPostsCmp = ({ postsList, setMyPostsList }) => {
  // make sure that every post is rendered once only
  const uniquePosts = postsList.filter(
    (post, index, self) => index === self.findIndex((p) => p.id === post.id)
  );

  const urlLocation = useLocation();
  const [urlIsMyPosts, setUrlIsMyPosts] = useState(
    urlLocation.pathname.includes(`profile/myPosts`)
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  // remove deleted Post from list
  const handleDeletedPost = (deletedPost) => {
    setMyPostsList((prevList) =>
      prevList.filter((row) => row.id !== deletedPost.id)
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {uniquePosts.map((post) => (
          <Grid
            item
            xs={12}
            md={6}
            key={post.id}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <APost
              post={post}
              handleDeletedPost={handleDeletedPost}
              urlIsMyPosts={urlIsMyPosts}
              showSnackBarMessage={showSnackBarMessage}
            />
          </Grid>
        ))}
      </Grid>
      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
    </>
  );
};
export default MyPostsCmp;
