import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSearchRequest } from "../../requestsApis/mainRequests";
import { useRef } from "react";
import {
  ExpandMoreOutlined,
  LocationOnOutlined,
  PersonOutline,
  StoreOutlined,
} from "@mui/icons-material";
import ProductsCmp from "../homePage/ProductsCmp";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

export default function SearchPage() {
  const { searchParam } = useParams();
  const BOUTIQUES_STRING = "boutiques";
  const PRODUCTS_STRING = "products";

  const [typeSearch, setTypeSearch] = useState(PRODUCTS_STRING);
  //   const [query, setQuery] = useState(searchParam || "");
  const [sort, setSort] = useState("recent");
  let pageNum = useRef(0);
  let pageSize = useRef(24);
  let isFinishedProd = useRef(false);
  let isFinishedBout = useRef(false);
  const [productIds, setProductIds] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [boutiquesList, setBoutiquesList] = useState([]);

  const { t } = useTranslation();

  const querySearch = useSearchRequest(
    searchParam,
    typeSearch,
    sort,
    pageNum.current,
    pageSize.current,
    productIds
  );

  //    ****    Methods
  const handleChangeType = (event, newTypeSearch) => {
    if (newTypeSearch !== null) {
      setTypeSearch(newTypeSearch);
    }
  };

  const loadMore = () => {
    if (typeSearch === PRODUCTS_STRING) {
      if (!isFinishedProd.current) {
        if (!querySearch.isLoading) {
          if (sort === "random") {
          } else {
            pageNum.current = pageNum.current + 1;
          }
          querySearch.refetch();
        }
      }
    } else if (typeSearch === BOUTIQUES_STRING) {
      if (!isFinishedBout.current) {
        if (!querySearch.isLoading) {
          if (sort === "random") {
          } else {
            pageNum.current = pageNum.current + 1;
          }
          querySearch.refetch();
        }
      }
    }
  };

  //    ****    Effects
  useEffect(() => {
    pageNum.current = 0;
    isFinishedProd.current = false;
    isFinishedBout.current = false;

    setProductIds([]);
    setProductsList([]);
    setBoutiquesList([]);
    querySearch.refetch();
  }, [searchParam]);

  useEffect(() => {
    if (querySearch.isError) {
      console.log(querySearch.error);
    }

    if (querySearch.isSuccess) {
      if (typeSearch === PRODUCTS_STRING) {
        const productsResponse = querySearch.data.data.products;
        console.log(productsResponse);
        setProductsList((prevList) => [...prevList, ...productsResponse]);

        if (productsResponse.length < pageSize.current) {
          isFinishedProd.current = true;
        }
      } else if (typeSearch === BOUTIQUES_STRING) {
        const boutiquesResponse = querySearch.data.data.boutiques;
        console.log(boutiquesResponse);
        setBoutiquesList((prevList) => [...prevList, ...boutiquesResponse]);

        if (boutiquesResponse.length < pageSize.current) {
          isFinishedBout.current = true;
        }
      }
    }
  }, [querySearch.isPending]);

  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />
      <Box>
        <ToggleButtonGroup
          color="secondary"
          value={typeSearch}
          exclusive
          onChange={handleChangeType}
          aria-label="Type Search"
          size="small"
          sx={{ marginY: 2 }}
        >
          {/* <ToggleButton value="all" sx={{ textTransform: "none" }}>
          All
        </ToggleButton> */}
          <ToggleButton value={PRODUCTS_STRING} sx={{ textTransform: "none" }}>
            {t("Products")}
          </ToggleButton>
          <ToggleButton value={BOUTIQUES_STRING} sx={{ textTransform: "none" }}>
            {t("Stores")}
          </ToggleButton>
        </ToggleButtonGroup>

        <Box sx={{ marginBottom: 4 }}>
          {typeSearch === BOUTIQUES_STRING ? (
            <StoresResultCmp
              searchParam={searchParam}
              isFinishedBout={isFinishedBout}
              querySearch={querySearch}
              loadMore={loadMore}
              boutiquesList={boutiquesList}
            />
          ) : (
            <ProductsResultCmp
              searchParam={searchParam}
              isFinishedProd={isFinishedProd}
              querySearch={querySearch}
              loadMore={loadMore}
              productsList={productsList}
            />
          )}
        </Box>

        <Typography textAlign="center" my={4}>
          {t("If_you_are_having_trouble_getting_results")}
        </Typography>
      </Box>
    </ContainerizedBox>
  );
}

const ProductsResultCmp = ({
  searchParam,
  isFinishedProd,
  querySearch,
  loadMore,
  productsList,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography mb={2}>
        {t("Products_Result_for")}:{" "}
        <Box component="span" sx={{ color: "secondary.main" }}>
          "{searchParam}"
        </Box>
      </Typography>

      <ProductsCmp products={productsList} />

      {/* Progress bar */}
      <Box
        sx={{
          margin: 4,
          display: isFinishedProd.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {querySearch.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMore}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
            }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const StoresResultCmp = ({
  searchParam,
  isFinishedBout,
  querySearch,
  loadMore,
  boutiquesList,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // make sure that every product is rendered once only
  const uniqueBoutiques = boutiquesList.filter(
    (store, index, self) => index === self.findIndex((p) => p.id === store.id)
  );

  const goToViewBoutique = (boutiqueId) => {
    navigate(`/ViewBoutique/${boutiqueId}`);
  };

  return (
    <Box>
      <Typography mb={2}>
        {t("Stores_Results_for")}:{" "}
        <Box component="span" sx={{ color: "secondary.main" }}>
          "{searchParam}"
        </Box>
      </Typography>

      <Box>
        {uniqueBoutiques.map((boutique) => (
          <ABoutique
            key={boutique.id}
            boutique={boutique}
            goToViewBoutique={goToViewBoutique}
          />
        ))}
      </Box>

      {/* Progress bar */}
      <Box
        sx={{
          margin: 4,
          display: isFinishedBout.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {querySearch.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMore}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
            }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const ABoutique = ({ boutique, goToViewBoutique }) => {
  const { t } = useTranslation();
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: { xs: 1, md: 4 },
        marginBottom: 2,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "whitesmoke",
        },
      }}
      onClick={() => goToViewBoutique(boutique.id)}
    >
      <Card sx={{ maxWidth: 100 }}>
        {/* Card Media with a Placeholder if Image is Not Available */}
        <CardMedia
          sx={{
            height: 100,
            width: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Placeholder background color
          }}
        >
          {boutique?.baniere && boutique.baniere !== "baniere" ? (
            <Box
              component="img"
              src={boutique.baniere}
              alt={boutique.name}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <StoreOutlined sx={{ fontSize: 70 }} /> // Placeholder Icon
          )}
        </CardMedia>
      </Card>

      <Box>
        <Typography
          variant="h6"
          fontSize={17}
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <StoreOutlined />
          {boutique.name}
        </Typography>

        <Typography
          variant="h6"
          fontSize={17}
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PersonOutline />
          {boutique.person?.firstname} {boutique.person?.lastname}
        </Typography>

        <Typography
          variant="h6"
          fontSize={17}
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LocationOnOutlined />
          {boutique.secondAddress?.addressLines
            ? boutique.secondAddress?.addressLines
            : t("Business_Address")}
        </Typography>
      </Box>
    </Card>
  );
};
