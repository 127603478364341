import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LOGIN_EXPIRATION_STRING, PRINCIPAL } from "../../myHelpers/constants";
import { useTranslation } from "react-i18next";

export const DialogLoginExpiredComponent = () => {
  const [isDialogLoginOpen, setIsDialogLoginOpen] = useState(false);
  const { t } = useTranslation();

  const handleDialogLoginExpiredOpen = () => {
    setIsDialogLoginOpen(true);
  };

  const handleDialogLoginExpiredClose = () => {
    setIsDialogLoginOpen(false);
  };

  // every time they ask for JWT_TOKEN,
  // if principal exists & time expired,
  // then openSessionExpired Dialog

  useEffect(() => {
    const currentTime = new Date().getTime();
    const loginExpirationTime = localStorage.getItem(LOGIN_EXPIRATION_STRING);

    if (PRINCIPAL !== null && currentTime > loginExpirationTime) {
      localStorage.clear();
      handleDialogLoginExpiredOpen();
    }
  }, []);

  return (
    <Dialog open={isDialogLoginOpen} onClose={handleDialogLoginExpiredClose}>
      <DialogTitle>{t("Session_Expired")} !</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("You_need_to_log_back_in_to_unlock_more_feature")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDialogLoginExpiredClose}
          variant="outlined"
          color="secondary"
          sx={{
            textTransform: "none",
            width: { xs: "inherit", sm: "7rem" },
            marginLeft: 5,
          }}
        >
          {t("Close")}
        </Button>
        <Button
          onClick={() => {
            handleDialogLoginExpiredClose();
            window.location.href = "/signIn";
          }}
          color="secondary"
          variant="contained"
          sx={{
            textTransform: "none",
            width: { xs: "inherit", sm: "7rem" },
            marginLeft: 5,
            whiteSpace: "nowrap",
          }}
        >
          {t("To_Login")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
