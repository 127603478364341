import { useEffect, useRef, useState } from "react";
import { useGetFollowingBoutiqueRequest } from "../../requestsApis/boutiqueRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { Box, Paper, Typography } from "@mui/material";
import { LoadMoreBtnCmp } from "../../components/ui/LoadMoreBtnCmp";
import MyFollowingStoresCmp from "../../components/largeComponents/MyFollowingStoresCmp";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const FollowingPage = ({ setSelectedTab }) => {
  const [followingStoresList, setFollowingStoresList] = useState([]);
  let pageNum = useRef(0);
  let pageSize = useRef(10);
  let isFinished = useRef(false);

  const queryGetFollowingStores = useGetFollowingBoutiqueRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current
  );

  const loadMore = () => {
    if (!isFinished.current && !queryGetFollowingStores.isLoading) {
      pageNum.current += 1;
      queryGetFollowingStores.refetch();
    }
  };

  // ********************     Use Effects
  useEffect(() => {
    setSelectedTab(3);
  }, []);

  useEffect(() => {
    if (queryGetFollowingStores.isError) {
      console.log(queryGetFollowingStores.error);
    }

    if (queryGetFollowingStores.isSuccess && queryGetFollowingStores.data) {
      setFollowingStoresList((prevList) => [
        ...prevList,
        ...queryGetFollowingStores.data.data,
      ]);

      if (queryGetFollowingStores.data.data.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetFollowingStores.isPending]);

  return (
    <Box>
      <DialogLoginExpiredComponent />
      <TableauHeaderCmp />

      <MyFollowingStoresCmp
        followingStoresList={followingStoresList}
        setFollowingStoresList={setFollowingStoresList}
      />

      <LoadMoreBtnCmp
        isFinished={isFinished}
        query={queryGetFollowingStores}
        loadMore={loadMore}
      />
    </Box>
  );
};
export default FollowingPage;

// Tableau Header
const TableauHeaderCmp = () => {
  const { t } = useTranslation();

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
        backgroundColor: "#e0e0e0",
        borderRadius: 0,
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          width: "25%",
        }}
      >
        {t("Following")}
      </Typography>

      <Typography sx={{ textAlign: "center", width: "50%" }}>
        {t("Store_Name")}
      </Typography>

      <Typography sx={{ textAlign: "center", width: "25%" }}>
        {t("Actions")}
      </Typography>
    </Paper>
  );
};
