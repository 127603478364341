import { Box } from "@mui/material";
import APostComment from "../ui/APostComment";

const PostCommentsWrapperCmp = ({
  postCommentsList,
  handleDeletePostComment,
}) => {
  // Unique comment
  const uniquePostComments = postCommentsList.filter(
    (comment, index, self) =>
      index === self.findIndex((c) => c.id === comment.id)
  );
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      {uniquePostComments.map((comment) => (
        <APostComment
          comment={comment}
          key={comment.id}
          handleDeletePostComment={handleDeletePostComment}
        />
      ))}
    </Box>
  );
};
export default PostCommentsWrapperCmp;
