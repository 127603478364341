import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/boutique";

// Delete Boutique Baniere Request
export const useDeleteBoutiqueBaniereRequest = (boutiqueId) => {
  const queryParams = {
    boutiqueId: boutiqueId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/delete_boutique_baniere?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Modify Boutique Baniere Request
export const useChangeBoutiqueBaniereRequest = (file, boutiqueId) => {
  const queryParams = {
    boutiqueId: boutiqueId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/change_boutique_baniere?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      // form data
      const form = new FormData();

      form.append("file", file);

      return await axios.put(fullUrl, form, {
        headers: {
          Authorization: JWT_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};

// GET Following Boutique Request
export const useGetFollowingBoutiqueRequest = (userId, pageNum, pageSize) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getFollowingBoutique/${userId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: userId !== 0,
  });
};

// Delete Boutique Request
export const useDeleteBoutiqueRequest = (boutiqueId) => {
  const queryParams = {
    boutiqueId: boutiqueId,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/delete_boutique?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Edit Boutique Request
export const useEditBoutiqueRequest = (boutique) => {
  return useMutation({
    mutationFn: async () => {
      const fullUrl = `${BACK_URL}${basePath}/edit_boutique`;

      return await axios.put(fullUrl, boutique, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Create Boutique Request
export const useCreateBoutiqueRequest = (boutique) => {
  return useMutation({
    mutationFn: async () => {
      const fullUrl = `${BACK_URL}${basePath}/add_boutique`;

      return await axios.post(fullUrl, boutique, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// GET My Boutique Request
export const useGetMyBoutiqueRequest = (userId, pageNum, pageSize) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getMyBoutiques/${userId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: userId !== 0,
  });
};

// view Boutique Request
export const useViewBoutiqueRequest = (boutiqueId, personId) => {
  const queryParams = {
    boutiqueId: boutiqueId,
    personId: personId,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/view/boutique?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: boutiqueId !== 0,
  });
};
