import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useViewBoutiqueRequest } from "../../requestsApis/boutiqueRequest";
import { ExpandMoreOutlined } from "@mui/icons-material";
import HeaderViewBoutique from "./HeaderViewBoutique";
import { useViewBoutiquesProductsRequest } from "../../requestsApis/productsRequests";
import ProductsViewBoutique from "./ProductsViewBoutique";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { PRINCIPAL } from "../../myHelpers/constants";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const ViewBoutiquePage = ({ handleOpenModalUseMobileApp }) => {
  const { boutiqueIdParam } = useParams();
  const boutiqueId = Number(boutiqueIdParam);
  const [boutiqueToView, setBoutiqueToView] = useState(null);
  let pageNumProd = useRef(0);
  let pageSizeProd = useRef(24);
  let isFinishedProd = useRef(false);
  const [productsList, setProductsList] = useState([]);
  const urlLocation = useLocation();
  const [urlIsMyBoutique, setUrlIsMyBoutique] = useState(
    urlLocation.pathname.includes(`profile/myBoutique/${boutiqueId}`)
  );
  const [urlIsMyBoutiqueAndImOwner, setUrlIsMyBoutiqueAndImOwner] =
    useState(false);
  const [errorPage, setErrorPage] = useState("");

  const { t } = useTranslation();

  // query View Boutique
  const queryViewBoutique = useViewBoutiqueRequest(
    boutiqueId || 0,
    PRINCIPAL?.id || 0
  );

  // query View Boutique Products
  const queryViewBoutiqueProducts = useViewBoutiquesProductsRequest(
    boutiqueId || 0,
    pageNumProd.current,
    pageSizeProd.current
  );

  const loadMoreProducts = () => {
    if (!isFinishedProd.current) {
      if (!queryViewBoutiqueProducts.isLoading) {
        pageNumProd.current++;
        queryViewBoutiqueProducts.refetch();
      }
    }
  };

  // **********     Effects
  useEffect(() => {
    if (queryViewBoutique.isError) {
      console.log(queryViewBoutique.error);
      setErrorPage(t("Error_404_Page_not_found"));
    }
    if (queryViewBoutique.isSuccess && queryViewBoutique.data) {
      const boutiqueResponse = queryViewBoutique.data.data;

      // if view my boutique from profile
      if (urlIsMyBoutique) {
        if (boutiqueResponse.personId === PRINCIPAL?.id) {
          setBoutiqueToView(boutiqueResponse);
          setUrlIsMyBoutiqueAndImOwner(true);
        } else {
          setErrorPage(t("Error_401_we_could_not_execute_this_request"));
        }
      } // if just view boutique
      else {
        if (boutiqueResponse) {
          setBoutiqueToView(boutiqueResponse);
        } else {
          setErrorPage(t("Error_404_Page_not_found"));
        }
      }
    }
  }, [queryViewBoutique.isPending]);

  useEffect(() => {
    if (queryViewBoutiqueProducts.isSuccess && queryViewBoutiqueProducts.data) {
      setProductsList((prevProducts) => [
        ...prevProducts,
        ...queryViewBoutiqueProducts.data.data,
      ]);

      if (queryViewBoutiqueProducts.data.data.length < pageSizeProd.current) {
        isFinishedProd.current = true;
      }
    }
  }, [queryViewBoutiqueProducts.isPending]);

  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />

      {boutiqueToView ? (
        <>
          <HeaderViewBoutique
            boutiqueToView={boutiqueToView}
            setBoutiqueToView={setBoutiqueToView}
            handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
            urlIsMyBoutique={urlIsMyBoutique}
          />
          <ProductsViewBoutique
            productsList={productsList}
            setProductsList={setProductsList}
            boutiqueToView={boutiqueToView}
            handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
            urlIsMyBoutiqueAndImOwner={urlIsMyBoutiqueAndImOwner}
          />
        </>
      ) : (
        <>
          <Typography variant="h6" fontWeight="bold" fontSize={40}>
            {errorPage}
          </Typography>
        </>
      )}

      {/* Progress bar */}
      <Box
        sx={{
          margin: 4,
          display: isFinishedProd.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryViewBoutiqueProducts.isFetching ? (
          <CircularProgress />
        ) : queryViewBoutique.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMoreProducts}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
            }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </ContainerizedBox>
  );
};
export default ViewBoutiquePage;
