import {
  Badge,
  Box,
  IconButton,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useEffect, useState } from "react";
import { NotificationsOutlined } from "@mui/icons-material";
import { useGetCountMyNotificationsRequest } from "../../requestsApis/personRequest";
import { useGetNotificationsRequest } from "../../requestsApis/notificationsRequest";
import { useRef } from "react";
import NotificationsCmp from "./NotificationsCmp";
import { useGetReceivedOrdersRequest } from "../../requestsApis/myOrdersRequest";
import ReceivedOrdersCmp from "./ReceivedOrdersCmp";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useTranslation } from "react-i18next";

export default function PopoverNotificationsCmp() {
  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  // Popover Notifications logic
  const [anchorElNotificationsPopover, setAnchorElNotificationsPopover] =
    useState(null);
  const openNotificationsPopover = Boolean(anchorElNotificationsPopover);
  const idNotificationsPopover = openNotificationsPopover
    ? "simple-popover"
    : undefined;
  const ACTIVITIES_STRING = "activities";
  const RECEIVED_ORDERS_STRING = "received_orders";
  const [alignmentNotificationsType, setAlignmentNotificationsType] =
    useState(ACTIVITIES_STRING);

  const [notificationsCount, setNotificationsCount] = useState({
    activities: 0,
    ordersReceived: 0,
  });
  const [totalNotifications, setTotalNotifications] = useState(0);

  // activities logic
  const [notificationsList, setNotificationsList] = useState([]);
  let pageNumNot = useRef(0);
  let pageSizeNot = useRef(10);
  let isFinishedNot = useRef(false);

  // received orders logic
  const [receivedOrdersList, setReceivedOrdersList] = useState([]);
  let pageNumReceivedOrders = useRef(0);
  let pageSizeReceivedOrders = useRef(10);
  let isFinishedReceivedOrders = useRef(false);

  const { t } = useTranslation();

  // Queries
  const queryGetCountMyNotifications = useGetCountMyNotificationsRequest(
    PRINCIPAL?.id || 0
  );
  const queryGetNotifications = useGetNotificationsRequest(
    PRINCIPAL?.id || 0,
    pageNumNot.current,
    pageSizeNot.current
  );
  const queryGetReceivedOrders = useGetReceivedOrdersRequest(
    PRINCIPAL?.id || 0,
    pageNumReceivedOrders.current,
    pageSizeReceivedOrders.current
  );

  // Notifications Popover logic methods
  const handleClickNotificationsPopover = (e) => {
    setAnchorElNotificationsPopover(e.currentTarget);
  };
  const handleCloseNotificationsPopover = () => {
    setAnchorElNotificationsPopover(null);
  };

  const handleChangeNotificationsType = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignmentNotificationsType(newAlignment);
    }
  };

  const loadMoreNot = () => {
    if (!isFinishedNot.current) {
      if (!queryGetNotifications.isLoading) {
        pageNumNot.current++;
        queryGetNotifications.refetch();
      }
    }
  };

  const loadMoreReceivedOrders = () => {
    if (!isFinishedReceivedOrders.current) {
      if (!queryGetReceivedOrders.isLoading) {
        pageNumReceivedOrders.current++;
        queryGetReceivedOrders.refetch();
      }
    }
  };

  // useEffect
  useEffect(() => {
    if (!PRINCIPAL && openNotificationsPopover) {
      handleDialogLoginOpen();
    }
  }, [openNotificationsPopover]);

  useEffect(() => {
    if (queryGetCountMyNotifications.isError) {
      console.log(queryGetCountMyNotifications.error);
    }

    if (queryGetCountMyNotifications.isSuccess) {
      let notResponse = queryGetCountMyNotifications.data.data;
      setNotificationsCount(notResponse);
      let total = notResponse.activities + notResponse.ordersReceived;
      setTotalNotifications(total);
    }
  }, [queryGetCountMyNotifications.isPending]);

  useEffect(() => {
    if (queryGetNotifications.isError) {
      console.log(queryGetNotifications.error);
    }

    if (queryGetNotifications.isSuccess) {
      setNotificationsList((prevList) => [
        ...prevList,
        ...queryGetNotifications.data.data,
      ]);

      if (queryGetNotifications.data.data.length < pageSizeNot.current) {
        isFinishedNot.current = true;
      }
    }
  }, [queryGetNotifications.isPending]);

  useEffect(() => {
    if (queryGetReceivedOrders.isError) {
      console.log(queryGetReceivedOrders.error);
    }

    if (queryGetReceivedOrders.isSuccess) {
      setReceivedOrdersList((prevList) => [
        ...prevList,
        ...queryGetReceivedOrders.data.data,
      ]);

      if (
        queryGetReceivedOrders.data.data.length < pageSizeReceivedOrders.current
      ) {
        isFinishedReceivedOrders.current = true;
      }
    }
  }, [queryGetReceivedOrders.isPending]);

  return (
    <>
      <DialogLoginComponent />
      <Badge badgeContent={totalNotifications} color="error">
        <IconButton
          onClick={handleClickNotificationsPopover}
          color="inherit"
          size="small"
          sx={{
            backgroundColor: openNotificationsPopover ? "#E6E6FA" : "inherit",
          }}
        >
          <NotificationsOutlined fontSize="inherit" />
        </IconButton>
      </Badge>
      <Popover
        sx={{ marginTop: 2, marginBottom: 4 }}
        id={idNotificationsPopover}
        open={openNotificationsPopover}
        anchorEl={anchorElNotificationsPopover}
        onClose={handleCloseNotificationsPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            padding: 2,
            width: { xs: "83vw", sm: 400 },
            minHeight: 450,
          }}
        >
          <Typography variant="h6" mb={1} textAlign="center" fontWeight="bold">
            {t("Notifications")}
          </Typography>
          <Box mb={1} textAlign="center">
            <ToggleButtonGroup
              color="secondary"
              value={alignmentNotificationsType}
              exclusive
              onChange={handleChangeNotificationsType}
              aria-label="Platform"
              size="small"
            >
              <Badge badgeContent={notificationsCount.activities} color="error">
                <ToggleButton
                  value={ACTIVITIES_STRING}
                  sx={{ textTransform: "none" }}
                >
                  {t("Activities")}
                </ToggleButton>
              </Badge>

              <Badge
                badgeContent={notificationsCount.ordersReceived}
                color="error"
              >
                <ToggleButton
                  value={RECEIVED_ORDERS_STRING}
                  sx={{ textTransform: "none" }}
                >
                  {t("Received_Orders")}
                </ToggleButton>
              </Badge>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {alignmentNotificationsType === ACTIVITIES_STRING && (
              <NotificationsCmp
                notificationsList={notificationsList}
                isFinished={isFinishedNot}
                queryGetNotifications={queryGetNotifications}
                loadMore={loadMoreNot}
                notificationsCount={notificationsCount}
                setNotificationsCount={setNotificationsCount}
                handleCloseNotificationsPopover={
                  handleCloseNotificationsPopover
                }
              />
            )}
            {alignmentNotificationsType === RECEIVED_ORDERS_STRING && (
              <ReceivedOrdersCmp
                receivedOrdersList={receivedOrdersList}
                isFinished={isFinishedReceivedOrders}
                queryGetReceivedOrders={queryGetReceivedOrders}
                loadMore={loadMoreReceivedOrders}
                notificationsCount={notificationsCount}
                setNotificationsCount={setNotificationsCount}
              />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
}
