import React, { useState } from "react";
import { Box, TextField, Avatar, Button, Grid, Card } from "@mui/material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { PRINCIPAL } from "../../myHelpers/constants";
import ModalAddAPostCmp from "../../components/largeComponents/ModalAddAPostCmp";
import { CustomSnackbar } from "../../components/ui/CustomSnackbar";
import { useDialogLogin } from "../../hooks/useDialogLogin";
import { useTranslation } from "react-i18next";

const PostInput = ({ setPostsList }) => {
  const {
    DialogLoginComponent,
    handleDialogLoginOpen,
    handleDialogLoginClose,
  } = useDialogLogin();

  const [openModalAddAPost, setOpenModalAddAPost] = useState(false);
  const handleOpenModalAddAPost = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      setOpenModalAddAPost(true);
    }
  };
  const handleCloseModalAddAPost = () => setOpenModalAddAPost(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  const { t } = useTranslation();

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  return (
    <>
      <Card
        onClick={handleOpenModalAddAPost}
        variant="outlined"
        sx={{
          padding: "10px",
          maxWidth: 400,
          marginX: "auto",
          marginBottom: 2,
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        {/* Top Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {/* Profile Image */}
          <Avatar
            alt={t("Profile_Picture")}
            src={PRINCIPAL?.profile} // "https://via.placeholder.com/40"
            sx={{ marginRight: "10px" }}
          />
          {/* Input Field */}
          <TextField
            fullWidth
            placeholder={t("Looking_for_something_post_here")}
            variant="outlined"
            size="small"
            sx={{
              borderRadius: 10,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#e0e0e0",
                },
                "&:hover fieldset": {
                  borderColor: "#bdbdbd",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#757575",
                },
              },
            }}
          />
        </Box>

        {/* Action Buttons */}
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item>
            <Button
              startIcon={<PhotoLibraryIcon sx={{ color: "#45bd62" }} />}
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: 14,
                color: "black",
              }}
            >
              {t("Photo")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<EmojiEmotionsIcon sx={{ color: "#f7b928" }} />}
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: 14,
                color: "black",
              }}
            >
              {t("Items")}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <ModalAddAPostCmp
        open={openModalAddAPost}
        handleClose={handleCloseModalAddAPost}
        showSnackBarMessage={showSnackBarMessage}
        setPostsList={setPostsList}
      />

      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
    </>
  );
};

export default PostInput;
