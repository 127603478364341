import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const NewsWrapper = () => {
  return (
    <Box flex={3} sx={{ display: { xs: "none", md: "block" } }}>
      <Box position="fixed" width={300}>
        <Typography variant="h6" fontWeight={100}>
          Online Friends
        </Typography>
        <AvatarGroup max={7}>
          <Avatar
            alt="Remy Sharp"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u7/74f8eb56-cde9-4257-ae5a-fbd0f722da2d_1715113357574.jpg"
          />
          <Avatar
            alt="Travis Howard"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u8/bdf1e031-695f-4b12-a89f-ec85fc658da3_1715798732173.jpg"
          />
          <Avatar alt="Cindy Baker" src="" />
          <Avatar
            alt="Agnes Walker"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u7/67e7a51d-3289-41ed-a1ae-cc5ef4be1ca9_1715475166774.jpg"
          />
          <Avatar
            alt="Trevor Henderson"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u7/238b7932-be6b-45b4-b6a8-90275be6c862_1715113858091.jpg"
          />
          <Avatar
            alt="Cindy Baker"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u7/23d17d8d-df0d-4e66-b8e3-9eed88a07d04_1715131938551.jpg"
          />
          <Avatar
            alt="Agnes Walker"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u7/a2f11c45-d691-4cef-96f3-1e8390aceb96_1715132934306.jpg"
          />
          <Avatar
            alt="Trevor Henderson"
            src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u8/e400c4c1-20ba-412e-8a5e-291a3363c068_1715800377096.jpg"
          />
        </AvatarGroup>

        <Typography variant="h6" fontWeight={100} mt={2} mb={2}>
          Latest Photos
        </Typography>
        <ImageList cols={3} rowHeight={100} gap={5}>
          <ImageListItem>
            <img
              src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u5/07d2bd4c-37c3-4d33-af83-ce7407227756_1715111829271.jpg"
              alt=""
            />
          </ImageListItem>

          <ImageListItem>
            <img
              src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u8/bdf1e031-695f-4b12-a89f-ec85fc658da3_1715798732173.jpg"
              alt=""
            />
          </ImageListItem>

          <ImageListItem>
            <img
              src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u3/2a2d2f8f-4870-45be-80a6-3aa1d267c786_1715110320520.jpg"
              alt=""
            />
          </ImageListItem>
        </ImageList>

        <Typography variant="h6" fontWeight={100} mt={2} mb={2}>
          Latest Conversations
        </Typography>

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt="Remy Sharp"
                src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u7/74f8eb56-cde9-4257-ae5a-fbd0f722da2d_1715113357574.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  {" — I'll be in your neighborhood doing errands this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt="Travis Howard"
                src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u8/bdf1e031-695f-4b12-a89f-ec85fc658da3_1715798732173.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  {" — Wish I could come, but I'm out of town this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt="Cindy Baker"
                src="https://shopitemz-aws-bucket.s3.amazonaws.com/images/u3/2a2d2f8f-4870-45be-80a6-3aa1d267c786_1715110320520.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  {" — Do you have Paris recommendations? Have you ever…"}
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default NewsWrapper;
