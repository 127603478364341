import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetMyOrdersRequest } from "../../requestsApis/myOrdersRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import MyOrdersCmp from "../../components/largeComponents/MyOrdersCmp";
import { LoadMoreBtnCmp } from "../../components/ui/LoadMoreBtnCmp";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const OrdersSentPage = ({ setSelectedTab }) => {
  const [myOrdersList, setMyOrdersList] = useState([]);
  let pageNum = useRef(0);
  let pageSize = useRef(10);
  let isFinished = useRef(false);

  const queryGetMyOrders = useGetMyOrdersRequest(
    PRINCIPAL?.id || 0,
    pageNum.current,
    pageSize.current
  );

  const loadMore = () => {
    if (!isFinished.current && !queryGetMyOrders.isLoading) {
      pageNum.current += 1;
      queryGetMyOrders.refetch();
    }
  };

  // ********************     Use Effects
  useEffect(() => {
    setSelectedTab(2);
  }, []);

  useEffect(() => {
    if (queryGetMyOrders.isError) {
      console.log(queryGetMyOrders.error);
    }

    if (queryGetMyOrders.isSuccess && queryGetMyOrders.data) {
      setMyOrdersList((prevList) => [
        ...prevList,
        ...queryGetMyOrders.data.data,
      ]);

      if (queryGetMyOrders.data.data.length < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryGetMyOrders.isPending]);

  return (
    <Box>
      <DialogLoginExpiredComponent />
      <TableauHeaderFavoritesCmp />
      <MyOrdersCmp
        myOrdersList={myOrdersList}
        setMyOrdersList={setMyOrdersList}
      />
      <LoadMoreBtnCmp
        isFinished={isFinished}
        query={queryGetMyOrders}
        loadMore={loadMore}
      />
    </Box>
  );
};
export default OrdersSentPage;

// Tableau Header
const TableauHeaderFavoritesCmp = () => {
  const { t } = useTranslation();
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: { xs: 2, md: 0 },
        padding: 1,
        backgroundColor: "#e0e0e0",
        borderRadius: 0,
      }}
    >
      <Typography sx={{ textAlign: "center", width: { xs: "30%", md: "15%" } }}>
        {t("My_Orders")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: { xs: "70%", md: "85%" },
        }}
      >
        <Typography
          sx={{
            display: { xs: "inline-block", md: "none" },
          }}
        >
          {t("Details")}
        </Typography>
        <Typography
          sx={{
            display: { xs: "none", md: "inline-block" },
            textAlign: "center",
            width: { xs: "0%", md: "25%" },
          }}
        >
          {t("Total_price")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            width: "35%",
            display: { xs: "none", md: "inline-block" },
          }}
        >
          {t("Store")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            width: "15%",
            display: { xs: "none", md: "inline-block" },
          }}
        >
          {t("Payment")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            width: "25%",
            display: { xs: "none", md: "inline-block" },
          }}
        >
          {t("Date")}
        </Typography>
      </Box>
    </Paper>
  );
};
