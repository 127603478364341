import { Alert, Snackbar } from "@mui/material";

export const CustomSnackbar = ({
  message,
  severity,
  openSnackbar,
  setOpenSnackbar,
}) => {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={10000}
      onClose={() => setOpenSnackbar(false)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={() => setOpenSnackbar(false)}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
