import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const StyledBanner = styled(Box)(({ theme }) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  color: "white",
  width: "100%",
}));

function SamplePrevArrow(props) {
  return <></>;
}

const MyBanner = ({ homePromotes }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <SamplePrevArrow />,
  };

  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {homePromotes.map((homePromote) => (
          <StyledBanner
            key={homePromote.id}
            sx={{ backgroundImage: `url(${homePromote.image})` }}
          >
            <Link
              to={`/ViewBoutique/${homePromote.boutiqueId}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Container
                  sx={{
                    width: { xs: "100%", sm: "78%", md: "79%", lg: "74%" },
                    paddingY: { xs: 7, md: 10, lg: 12 },
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    fontSize={isMediumOrLarger ? 16 : 13}
                    fontWeight="bold"
                  >
                    {t("Get_All")} <br />
                    {t("THE_GOOD_STUFFS")}
                  </Typography>
                  <Button
                    variant="contained"
                    size={isMediumOrLarger ? "large" : "small"}
                    color="secondary"
                    sx={{ mt: 2 }}
                    endIcon={<ArrowForward />}
                  >
                    {t("CHECK_OUT")}
                  </Button>
                </Container>
              </Box>
            </Link>
          </StyledBanner>
        ))}
      </Slider>
    </div>
  );
};
export default MyBanner;
