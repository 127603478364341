import { Add, Close, Delete, PhotoLibrary } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useViewAllCategoriesRequest } from "../../requestsApis/categoriesRequests";
import { useViewAllCurrenciesRequest } from "../../requestsApis/currenciesRequest";
import {
  CURRENCIES,
  CURRENCIES_STRING,
  PRINCIPAL,
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_STRING,
} from "../../myHelpers/constants";
import {
  useAddProductImageRequest,
  useAddProductRequest,
} from "../../requestsApis/productsRequests";
import { useAddAllProductImagesRequest } from "../../requestsApis/productImagesRequests";
import { CustomSnackbar } from "../ui/CustomSnackbar";
import { useTranslation } from "react-i18next";

// Drop zone Style
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "10rem",
  width: "100%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  marginTop: 16,
  overflow: "auto",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const CreateProductCmp = ({ boutiqueToView, setProductsList }) => {
  //   *************   Drop Zone    ************************************
  const [files, setFiles] = useState([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      const newFilesSelected = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles((prevFiles) => {
        const remainingSlots = 10 - prevFiles.length;

        if (remainingSlots <= 0) {
          return prevFiles;
        }

        return [...prevFiles, ...newFilesSelected.slice(0, remainingSlots)];
      });
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <IconButton
          onClick={() => handleRemoveFile(index)}
          size="small"
          color="error"
          sx={{ position: "absolute", top: -5, right: -5 }}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  ));

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((row, i) => i !== index));
  };

  //   *************  End   Drop Zone    ************************************

  // modal logic
  const [openModal, setOpenModal] = useState(false);
  const blankProduct = {
    name: "",
    boutiqueId: boutiqueToView?.id,
    price: 0,
    priceCode: "$ USD",
    category: "General",
    description: "",
    quantity: 1,
  };
  const [product, setProduct] = useState(blankProduct);
  const [categoriesList, setCategoriesLis] = useState([]);
  const [queryCategEnabled, setQueryCategEnabled] = useState(false);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [queryCurrencyEnabled, setQueryCurrencyEnabled] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  const { t, i18n } = useTranslation();

  const sortedCategoriesList = categoriesList.sort((a, b) =>
    i18n.language.slice(0, 2) === "fr"
      ? a.nameFr.localeCompare(b.nameFr)
      : a.name.localeCompare(b.name)
  );

  //    Queries
  const queryViewCategories = useViewAllCategoriesRequest(
    0,
    200,
    queryCategEnabled
  );
  const queryViewCurrencies = useViewAllCurrenciesRequest(queryCurrencyEnabled);

  const mutationAddProduct = useAddProductRequest(product);
  const mutationAddProductImage = useAddProductImageRequest(files[0]);
  const mutationAddAllProductImages = useAddAllProductImagesRequest(
    files,
    PRINCIPAL?.id || 0
  );

  //    *****************     Methods
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setFiles([]);
    setProduct(blankProduct);
    setOpenModal(false);
  };

  const handleChange = (event) => {
    setProduct({ ...product, [event.target.name]: event.target.value });
  };

  const handlePost = () => {
    if (files.length >= 1) {
      // check categ Eng
      const selectedCategory = sortedCategoriesList.filter(
        (category) => category.name === product.category
      );
      if (selectedCategory[0]) {
        setProduct({ ...product, categoryId: selectedCategory[0].id });
      } // check categ Fr
      else {
        const selectedCategory = sortedCategoriesList.filter(
          (category) => category.nameFr === product.category
        );
        if (selectedCategory[0]) {
          setProduct({ ...product, categoryId: selectedCategory[0].id });
        }
      }

      mutationAddProduct.mutate();
    }
  };

  const showSnackBarPostSuccess = () => {
    handleCloseModal();
    setMessageSnackbar(t("Added_Product_Successfully"));
    setSeveritySnackbar("success");
    setOpenSnackbar(true);
  };

  const showSnackBarPostError = () => {
    setMessageSnackbar(t("Error_Adding_Product"));
    setSeveritySnackbar("error");
    setOpenSnackbar(true);
  };

  const handleChangeCategory = (category) => {
    setProduct({ ...product, category: category });
  };

  //    Effects

  useEffect(() => {
    if (categoriesList.length < 1) {
      if (PRODUCT_CATEGORIES === null || PRODUCT_CATEGORIES.length < 1) {
        setQueryCategEnabled(true);
      } else {
        setCategoriesLis(PRODUCT_CATEGORIES);
        setQueryCategEnabled(false);
      }
    }
  }, [categoriesList.length]);

  useEffect(() => {
    if (currenciesList.length < 1) {
      if (CURRENCIES === null || CURRENCIES.length < 1) {
        setQueryCurrencyEnabled(true);
      } else {
        setCurrenciesList(CURRENCIES);
        setQueryCurrencyEnabled(false);
      }
    }
  }, [currenciesList.length]);

  useEffect(() => {
    if (queryViewCategories.isError) {
      console.log(queryViewCategories.error);
    }

    if (queryViewCategories.isSuccess) {
      setCategoriesLis(queryViewCategories.data.data);
      localStorage.setItem(
        PRODUCT_CATEGORIES_STRING,
        JSON.stringify(queryViewCategories.data.data)
      );
    }
  }, [queryViewCategories.isPending]);

  useEffect(() => {
    if (queryViewCurrencies.isError) {
      console.log(queryViewCurrencies.error);
    }

    if (queryViewCurrencies.isSuccess) {
      setCurrenciesList(queryViewCurrencies.data.data);
      localStorage.setItem(
        CURRENCIES_STRING,
        JSON.stringify(queryViewCurrencies.data.data)
      );
    }
  }, [queryViewCurrencies.isPending]);

  // effect add Product
  useEffect(() => {
    if (mutationAddProduct.isError) {
      console.log(mutationAddProduct.error);
      showSnackBarPostError();
    }

    if (mutationAddProduct.isSuccess) {
      const registeredProduct = mutationAddProduct.data.data;
      if (files.length >= 1) {
        mutationAddProductImage.mutate(registeredProduct.id);
      }
    }
  }, [mutationAddProduct.isPending]);

  // effect add Product Image
  useEffect(() => {
    if (mutationAddProductImage.isError) {
      console.log(mutationAddProductImage.error);
      showSnackBarPostError();
    }

    if (mutationAddProductImage.isSuccess) {
      const registeredProduct = mutationAddProductImage.data.data;
      setProduct(registeredProduct);

      setProductsList((prevList) => [registeredProduct, ...prevList]);

      if (files.length > 1) {
        mutationAddAllProductImages.mutate(registeredProduct.id);
      } else {
        showSnackBarPostSuccess();
      }
    }
  }, [mutationAddProductImage.isPending]);

  // effect Add All Images
  useEffect(() => {
    if (mutationAddAllProductImages.isError) {
      console.log(mutationAddAllProductImages.error);
      showSnackBarPostError();
    }

    if (mutationAddAllProductImages.isSuccess) {
      showSnackBarPostSuccess();
    }
  }, [mutationAddAllProductImages.isPending]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "inherit", md: "center" },
          justifyContent: { xs: "inherit", md: "space-between" },
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">{t("Store_Products")}</Typography>
        <Button
          onClick={handleOpenModal}
          startIcon={<Add />}
          color="secondary"
          variant="contained"
          sx={{
            textTransform: "none",
            marginBottom: 1,
            minWidth: { xs: "100%", md: 300 },
          }}
        >
          {t("Add_Product")}
        </Button>
      </Box>

      <Modal
        open={openModal}
        // onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "70%" },
            // height: 650,
            height: "90vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}
        >
          {/* Top Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              borderBottom: "1px solid gray",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Add_Product_Details")}
            </Typography>
            <Button
              onClick={handleCloseModal}
              size="small"
              color="error"
              variant="outlined"
              startIcon={<Close />}
              sx={{ textTransform: "none" }}
              disabled={
                mutationAddProduct.isPending ||
                mutationAddProductImage.isPending ||
                mutationAddAllProductImages.isPending
              }
            >
              {t("Close")}
            </Button>
          </Box>

          {/* Main conaint */}
          <Box
            sx={{
              position: "relative",
              height: "80%",
              width: "100%",
              overflow: "auto",
              marginRight: 1,
            }}
          >
            {/* Drop Zone */}
            <Box sx={{ marginX: 2 }}>
              <section className="container">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  {files.length > 0 ? (
                    <Typography
                      sx={{ textWrap: "nowrap", textAlign: "center" }}
                    >
                      {files.length < 2
                        ? `${files.length} File selected`
                        : `${files.length} Files selected`}
                    </Typography>
                  ) : (
                    <Typography sx={{ textAlign: "center" }}>
                      <PhotoLibrary />
                      <br />
                      <br />
                      {t("Drag_n_Drop_Or_click")}
                    </Typography>
                  )}
                </div>
                <aside style={thumbsContainer}>{thumbs}</aside>
                <aside>
                  <Typography>
                    {t("Maximum_10_image_files_allowed_per_product")}
                  </Typography>
                </aside>
              </section>
            </Box>

            <Box sx={{ paddingBottom: 4, paddingX: 2 }}>
              <Grid container sx={{ marginTop: 2 }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label={t("Product_Name")}
                    name="name"
                    type="text"
                    color="secondary"
                    required
                    value={product.name}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label={t("Category")}
                    name="category"
                    type="text"
                    color="secondary"
                    value={product.category}
                    helperText={t("Select_the_product_Category")}
                    sx={{
                      width: "100%",
                    }}
                    slotProps={{
                      select: {
                        renderValue: (selected) => {
                          const selectedCategory = sortedCategoriesList.find(
                            (category) => category.name === selected
                          );
                          return selectedCategory
                            ? i18n.language.slice(0, 2) === "fr"
                              ? selectedCategory.nameFr
                              : selectedCategory.name
                            : "";
                        },
                      },
                    }}
                  >
                    {sortedCategoriesList.map((category) => (
                      <MenuItem
                        key={category.id}
                        value={category.name}
                        onClick={() => handleChangeCategory(category.name)}
                      >
                        <ListItemIcon sx={{ marginRight: 1 }}>
                          <Avatar
                            sx={{
                              width: 50,
                              height: 50,
                              mx: "auto",
                              bgcolor: "#E6E6FA",
                            }}
                            src={category.image}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {i18n.language.slice(0, 2) === "fr"
                            ? category.nameFr
                            : category.name}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: 2 }} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    label={t("Price")}
                    name="price"
                    type="number"
                    color="secondary"
                    value={product.price}
                    variant="outlined"
                    helperText={`${t("Default")} 0`}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label={`$ ${t("Currency")}`}
                    name="priceCode"
                    type="text"
                    color="secondary"
                    value={product.priceCode}
                    helperText={t("Select_the_Currency")}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                  >
                    {currenciesList.map((currency) => (
                      <MenuItem key={currency.code} value={currency.code}>
                        {currency.code}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: 2 }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label={`${t("Descriptions")}`}
                    name="description"
                    type="text"
                    color="secondary"
                    value={product.description}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label={t("Quantity")}
                    name="quantity"
                    type="number"
                    color="secondary"
                    value={product.quantity}
                    variant="outlined"
                    helperText={`${t("Default")} 1`}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Footer */}
          <Box
            sx={{
              borderTop: "1px solid gray",
              paddingTop: 1,
              paddingLeft: 2,
              paddingRight: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {mutationAddProduct.isPending ||
            mutationAddProductImage.isPending ||
            mutationAddAllProductImages.isPending ? (
              <Typography>
                {t("Uploading_product_Do_not_refresh_the_page_please_wait")}
                <CircularProgress size={15} />
              </Typography>
            ) : (
              ""
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "space-between", md: "right" },
              }}
            >
              <Button
                onClick={handleCloseModal}
                size="small"
                color="secondary"
                variant="outlined"
                sx={{
                  textTransform: "none",
                  minWidth: 100,
                }}
                disabled={
                  mutationAddProduct.isPending ||
                  mutationAddProductImage.isPending ||
                  mutationAddAllProductImages.isPending
                }
              >
                {t("Cancel")}
              </Button>

              <Button
                onClick={handlePost}
                size="small"
                color="secondary"
                variant="contained"
                sx={{
                  textTransform: "none",
                  marginLeft: { xs: 5, md: 10 },
                  minWidth: 100,
                }}
                disabled={
                  files.length < 1 ||
                  product.name.length < 3 ||
                  mutationAddProduct.isPending ||
                  mutationAddProductImage.isPending ||
                  mutationAddAllProductImages.isPending
                }
              >
                {mutationAddProduct.isPending ||
                mutationAddProductImage.isPending ||
                mutationAddAllProductImages.isPending ? (
                  <CircularProgress size={15} />
                ) : (
                  t("Post")
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
    </>
  );
};
