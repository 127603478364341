import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const LanguageSelectCmp = () => {
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl variant="standard" size="small">
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={
          i18n.language.slice(0, 2) === "fr" ||
          i18n.language.slice(0, 2) === "en"
            ? i18n.language.slice(0, 2)
            : "fr"
        }
        onChange={handleChange}
        label="Language"
        // startAdornment={<Language fontSize="small" />}
      >
        <MenuItem value={"fr"}>Français</MenuItem>
        <MenuItem value={"en"}>English</MenuItem>
      </Select>
    </FormControl>
  );
};
export default LanguageSelectCmp;
