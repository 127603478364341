import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  ArrowBack,
  CameraFront,
  CancelOutlined,
  CheckCircleOutline,
  Close,
  Delete,
  PhotoLibrary,
  WarningAmberOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { PRINCIPAL } from "../../myHelpers/constants";

import { useState } from "react";
import { useEffect } from "react";
import { FaPassport } from "react-icons/fa6";
import { HiIdentification } from "react-icons/hi2";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import { useAddAccountVerificationRequest } from "../../requestsApis/accountVerificationRequest";
import { RxIdCard } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "80%", md: "60%", lg: "40%" },
  // height: 650,
  height: "77vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function ModalVerifyMyAccountCmp({
  showSnackBarMessage,
  principal,
  setPrincipal,
  openClickBtnAddressFromVerify,
  verifyFromAboutPage,
  closeVerifyFromAboutPage,
}) {
  const [open, setOpen] = useState(false);
  const [addressCorrect, setAddressCorrect] = useState(false);
  const [documentCorrect, setDocumentCorrect] = useState(false);
  const { t } = useTranslation();

  const [stepVerification, setStepVerification] = useState(1);
  // 1: main;    2: Choosed a document;   3
  const [documentType, setDocumentType] = useState("");
  // "Government ID";    "Driver Licence";   "Passport";    "Residence Permit"

  // files
  const [filesDocumentFront, setFilesDocumentFront] = useState([]);
  const [filesDocumentBack, setFilesDocumentBack] = useState([]);
  const [filesSelfie, setFilesSelfie] = useState([]);

  // mutations
  const mutationAddAccountVerification = useAddAccountVerificationRequest(
    PRINCIPAL?.id || 0,
    documentType,
    filesDocumentFront[0],
    filesDocumentBack[0],
    filesSelfie[0]
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (verifyFromAboutPage) {
      closeVerifyFromAboutPage();
    }
  };

  const handleSendVerification = () => {
    if (addressCorrect && documentCorrect) {
      mutationAddAccountVerification.mutate();
      handleClose();
      showSnackBarMessage(t("Uploading_Verification_details"), "secondary");
    } else {
      showSnackBarMessage(t("Provide_all_the_inormations_needed"), "error");
    }
  };

  useEffect(() => {
    if (verifyFromAboutPage) {
      handleOpen();
    }
  }, [verifyFromAboutPage]);

  useEffect(() => {
    setAddressCorrect(principal?.secondAddress !== null);
  }, [principal]);

  useEffect(() => {
    setDocumentCorrect(
      filesDocumentFront.length === 1 &&
        filesDocumentBack.length === 1 &&
        filesSelfie.length === 1
    );
  }, [filesDocumentFront, filesDocumentBack, filesSelfie]);

  useEffect(() => {
    if (mutationAddAccountVerification.isError) {
      console.log(mutationAddAccountVerification.error);
      showSnackBarMessage(t("Error_Sending_Verification_details"), "error");
    }

    if (mutationAddAccountVerification.isSuccess) {
      showSnackBarMessage(
        t("Sent_Verification_details_successfully"),
        "success"
      );
      const savedPrincipal = { ...PRINCIPAL, accountInVerification: true };
      localStorage.setItem("principal", JSON.stringify(savedPrincipal));
      setPrincipal(savedPrincipal);
    }
  }, [mutationAddAccountVerification.isPending]);

  return (
    <>
      {!principal.accountInVerification && (
        <Button
          onClick={handleOpen}
          startIcon={<WarningAmberOutlined />}
          variant="outlined"
          color="error"
          size="small"
          sx={{ textTransform: "none" }}
        >
          {t("Verify_My_Account")}
        </Button>
      )}

      {PRINCIPAL && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* Top Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
                borderBottom: "1px solid gray",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("Verify_My_Account")}
              </Typography>
              <Button
                onClick={handleClose}
                size="small"
                color="error"
                variant="outlined"
                startIcon={<Close />}
                sx={{ textTransform: "none" }}
              >
                {t("Close")}
              </Button>
            </Box>
            {/* Main conaint */}
            <Box
              sx={{
                position: "relative",
                height: "79%",
                width: "100%",
                overflow: "auto",
                marginRight: 1,
              }}
            >
              <Box sx={{ padding: 2 }}>
                <MainContentCmp
                  addressCorrect={addressCorrect}
                  setAddressCorrect={setAddressCorrect}
                  documentCorrect={documentCorrect}
                  setDocumentCorrect={setDocumentCorrect}
                  stepVerification={stepVerification}
                  setStepVerification={setStepVerification}
                  documentType={documentType}
                  setDocumentType={setDocumentType}
                  filesDocumentFront={filesDocumentFront}
                  setFilesDocumentFront={setFilesDocumentFront}
                  filesDocumentBack={filesDocumentBack}
                  setFilesDocumentBack={setFilesDocumentBack}
                  filesSelfie={filesSelfie}
                  setFilesSelfie={setFilesSelfie}
                  openClickBtnAddressFromVerify={openClickBtnAddressFromVerify}
                  principal={principal}
                />
              </Box>
            </Box>

            {/* Footer */}
            <Box
              sx={{
                borderTop: "1px solid gray",
                paddingTop: 1,
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={handleClose}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    minWidth: 100,
                  }}
                >
                  {t("Cancel")}
                </Button>

                <Button
                  onClick={handleSendVerification}
                  size="small"
                  color="secondary"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    minWidth: 100,
                  }}
                  disabled={!addressCorrect || !documentCorrect}
                >
                  {t("Send_to_verify")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

const MainContentCmp = ({
  addressCorrect,
  setAddressCorrect,
  documentCorrect,
  setDocumentCorrect,
  stepVerification,
  setStepVerification,
  documentType,
  setDocumentType,
  filesDocumentFront,
  setFilesDocumentFront,
  filesDocumentBack,
  setFilesDocumentBack,
  filesSelfie,
  setFilesSelfie,
  openClickBtnAddressFromVerify,
  principal,
}) => {
  const paperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 1,
    marginBottom: 2,
    cursor: "pointer",
    "&:hover": {
      bgcolor: "#e0e0e0",
    },
  };

  const { t } = useTranslation();

  const handlePreviousDocumentType = () => {
    let newStep = stepVerification - 1;
    setStepVerification(newStep);
  };

  return (
    <Box>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
          marginBottom: 2,
          cursor: "pointer",
          "&:hover": {
            bgcolor: "#e0e0e0",
          },
        }}
        onClick={openClickBtnAddressFromVerify}
      >
        <Box>
          <Typography variant="h6">1. {t("Address")}</Typography>
          <Typography variant="body2" fontSize={14}>
            {t("Add_your_address_to_your_account")}
          </Typography>
        </Box>

        {addressCorrect ? (
          <CheckCircleOutline color="success" />
        ) : (
          <CancelOutlined color="error" />
        )}
      </Paper>

      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
          marginBottom: 2,
        }}
      >
        <Box>
          <Typography variant="h6">2. {t("Documents")}</Typography>
          <Typography variant="body2" fontSize={14}>
            {t("Choose_a_type_of_document_to_verify_your_account")}
          </Typography>
        </Box>

        {documentCorrect ? (
          <CheckCircleOutline color="success" />
        ) : (
          <CancelOutlined color="error" />
        )}
      </Paper>

      {stepVerification === 1 && (
        <ChooseDocumentTypeCmp
          setDocumentType={setDocumentType}
          setStepVerification={setStepVerification}
        />
      )}

      {stepVerification === 2 && (
        <>
          <Button
            onClick={handlePreviousDocumentType}
            startIcon={<ArrowBack />}
            size="small"
            sx={{ textTransform: "none" }}
          >
            {documentType}
          </Button>

          <DocumentImagesCmp
            filesDocumentFront={filesDocumentFront}
            setFilesDocumentFront={setFilesDocumentFront}
            filesDocumentBack={filesDocumentBack}
            setFilesDocumentBack={setFilesDocumentBack}
            filesSelfie={filesSelfie}
            setFilesSelfie={setFilesSelfie}
          />
        </>
      )}
    </Box>
  );
};

const ChooseDocumentTypeCmp = ({ setDocumentType, setStepVerification }) => {
  const handleSelectedDocument = (selectedDocumentType) => {
    setDocumentType(selectedDocumentType);
    setStepVerification(2);
  };

  // "Government ID";    "Driver Licence";   "Passport";    "Residence Permit"
  return (
    <Box>
      <List disablePadding>
        <ListItem onClick={() => handleSelectedDocument("Government ID")}>
          <ListItemButton>
            <ListItemIcon>
              <HiIdentification fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={t("Government_ID")} />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => handleSelectedDocument("Driver Licence")}>
          <ListItemButton>
            <ListItemIcon>
              <HiIdentification fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={t("Driver_Licence")} />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => handleSelectedDocument("Passport")}>
          <ListItemButton>
            <ListItemIcon>
              <FaPassport fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={t("Passport")} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

const DocumentImagesCmp = ({
  filesDocumentFront,
  setFilesDocumentFront,
  filesDocumentBack,
  setFilesDocumentBack,
  filesSelfie,
  setFilesSelfie,
}) => {
  return (
    <Box>
      <Box sx={{ marginY: 1, marginX: 3 }}>
        <ADropZoneDocumentImage
          files={filesDocumentFront}
          setFiles={setFilesDocumentFront}
          title={t("Document_Front_image")}
          icon={<HiIdentification fontSize="large" />}
        />
        <ADropZoneDocumentImage
          files={filesDocumentBack}
          setFiles={setFilesDocumentBack}
          title={t("Document_Back_image")}
          icon={<RxIdCard fontSize="large" />}
        />
        <ADropZoneDocumentImage
          files={filesSelfie}
          setFiles={setFilesSelfie}
          title={t("Selfie_image_of_you")}
          icon={<CameraFront />}
        />
      </Box>
    </Box>
  );
};

const ADropZoneDocumentImage = ({ files, setFiles, title, icon }) => {
  //   *************   Drop Zone    ************************************

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <IconButton
          onClick={() => handleRemoveFile(index)}
          size="small"
          color="error"
          sx={{ position: "absolute", top: -5, right: -5 }}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  ));

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((row, i) => i !== index));
  };

  //   *************  End   Drop Zone    ************************************

  return (
    <Box>
      <Typography sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {icon} {title}
      </Typography>
      {/* Drop Zone */}
      <Box>
        <section className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
              <Typography sx={{ textWrap: "nowrap", textAlign: "center" }}>
                {files.length < 2
                  ? `${files.length} ${t("File_selected")}`
                  : `${files.length} ${t("Files_selected")}`}
              </Typography>
            ) : (
              <Typography sx={{ textWrap: "nowrap", textAlign: "center" }}>
                <PhotoLibrary />
                <br />
                {t("Drag_n_Drop_Or_click")}
              </Typography>
            )}
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
        </section>
      </Box>
    </Box>
  );
};

// Drop zone Style ************     *******************
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "5rem",
  width: "100%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  marginTop: 16,
  overflow: "auto",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
