import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import MyBanner from "./MyBanner";
import NewArrivalsCmp from "./NewArrivalsCmp";
import ProductsCmp from "./ProductsCmp";
import ProductsCategoriesCmp from "./ProductsCategoriesCmp";
import { useFetchHomePromote } from "../../requestsApis/mainRequests";
import { useViewAllCategoriesRequest } from "../../requestsApis/categoriesRequests";
import { useEffect, useState, useRef } from "react";
import {
  useGetRandomProductsByCountryRequest,
  useGetRandomProductsRequest,
} from "../../requestsApis/productsRequests";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { PRODUCTS_LOCATION_COUNTRY } from "../../myHelpers/constants";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const HomePage = ({ handleOpenModalUseMobileApp, navTo, setNavTo }) => {
  const queryHomePromote = useFetchHomePromote();
  const [homePromotes, setHomePromotes] = useState([]);

  let pageNumCateg = useRef(0);
  let pageSizeCateg = useRef(15);
  let isFinishedCateg = useRef(false);
  const [categoriesList, setCategoriesList] = useState([]);

  let pageSizeProd = useRef(24);
  let productsIds = useRef([0]);
  let isFinishedProd = useRef(false);
  const [productsList, setProductsList] = useState([]);
  const { t } = useTranslation();

  // get All Categ        ************************************************
  const queryViewAllCategories = useViewAllCategoriesRequest(
    pageNumCateg.current,
    pageSizeCateg.current,
    true
  );

  // get Random Products
  const queryGetRandomProducts = useGetRandomProductsRequest(
    pageSizeProd.current,
    productsIds.current
  );
  // get Random Products By Country
  const queryGetRandomProductsByCountry = useGetRandomProductsByCountryRequest(
    PRODUCTS_LOCATION_COUNTRY?.code || "",
    pageSizeProd.current,
    productsIds.current
  );

  // load more categ
  const loadMoreCategories = () => {
    if (!isFinishedCateg.current) {
      if (!queryViewAllCategories.isLoading) {
        pageNumCateg.current = pageNumCateg.current + 1;
        queryViewAllCategories.refetch();
      } else {
      }
    } else {
    }
  };

  const loadMoreProducts = () => {
    if (PRODUCTS_LOCATION_COUNTRY !== null) {
      loadMoreProductsByCountry();
    } else {
      if (!isFinishedProd.current) {
        if (!queryGetRandomProducts.isLoading) {
          let newIds = productsList.map((product) => product.id);
          productsIds.current = newIds;
          queryGetRandomProducts.refetch();
        }
      }
    }
  };

  const loadMoreProductsByCountry = () => {
    if (!isFinishedProd.current) {
      if (!queryGetRandomProductsByCountry.isLoading) {
        let newIds = productsList.map((product) => product.id);
        productsIds.current = newIds;
        queryGetRandomProductsByCountry.refetch();
      }
    }
  };

  useEffect(() => {
    setNavTo(0);
  }, []);

  //  query HomePromote
  useEffect(() => {
    if (queryHomePromote.isPending) {
    }
    if (queryHomePromote.isError) {
    }
    if (queryHomePromote.isSuccess) {
      setHomePromotes(queryHomePromote.data?.data);
    }
  }, [queryHomePromote.isPending]);

  // query Categories
  useEffect(() => {
    if (queryViewAllCategories.isSuccess && queryViewAllCategories.data) {
      setCategoriesList((prevCategories) => [
        ...prevCategories,
        ...queryViewAllCategories.data.data,
      ]);

      if (queryViewAllCategories.data.data.length < pageSizeCateg.current) {
        isFinishedCateg.current = true;
      }
    }
  }, [queryViewAllCategories.isPending]);

  // Random Products
  useEffect(() => {
    if (queryGetRandomProducts.isSuccess && queryGetRandomProducts.data) {
      setProductsList((prevProducts) => [
        ...prevProducts,
        ...queryGetRandomProducts.data.data.products,
      ]);

      if (
        queryGetRandomProducts.data.data.products.length < pageSizeProd.current
      ) {
        isFinishedProd.current = true;
      }
    }
  }, [queryGetRandomProducts.isPending]);

  // Random Products By Country
  useEffect(() => {
    if (
      queryGetRandomProductsByCountry.isSuccess &&
      queryGetRandomProductsByCountry.data
    ) {
      setProductsList((prevProducts) => [
        ...prevProducts,
        ...queryGetRandomProductsByCountry.data.data.products,
      ]);

      if (
        queryGetRandomProductsByCountry.data.data.products.length <
        pageSizeProd.current
      ) {
        isFinishedProd.current = true;
      }
    }
  }, [queryGetRandomProductsByCountry.isPending]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {queryHomePromote.isPending ? (
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: { xs: 235, md: 280, lg: 315 },
            }}
          />
        ) : (
          <MyBanner homePromotes={homePromotes} />
        )}
      </Box>

      <ContainerizedBox>
        <ProductsCategoriesCmp
          categories={categoriesList}
          loadMoreCategories={loadMoreCategories}
          isFinishedCateg={isFinishedCateg}
          queryViewAllCategories={queryViewAllCategories}
          pageNumCateg={pageNumCateg}
        />
        <NewArrivalsCmp />
        <ProductsCmp
          products={productsList}
          handleOpenModalUseMobileApp={handleOpenModalUseMobileApp}
        />
      </ContainerizedBox>

      <Box
        sx={{
          margin: 4,
          display: isFinishedProd.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryGetRandomProducts.isFetching ||
        queryGetRandomProductsByCountry.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMoreProducts}
            sx={{ whiteSpace: "nowrap", textTransform: "none" }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </>
  );
};

export default HomePage;
