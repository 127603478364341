import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Close, PhotoLibrary } from "@mui/icons-material";
import { CircularProgress, Modal, TextField } from "@mui/material";
import { useDropzone } from "react-dropzone";
import {
  useAddAPostRequest,
  useSavePostRequest,
} from "../../requestsApis/postsRequests";
import { PRINCIPAL } from "../../myHelpers/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Drop zone Style
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "10rem",
  width: "100%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const ModalAddAPostCmp = ({
  open,
  handleClose,
  showSnackBarMessage,
  setPostsList,
}) => {
  //   *************   Drop Zone    ************************************
  const [files, setFiles] = React.useState([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const newFilesSelected = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(newFilesSelected);
    },
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  //   *************  End   Drop Zone    ************************************

  const [postComment, setPostComment] = React.useState("");

  // mutations
  const mutationAddAPost = useAddAPostRequest(
    files[0],
    PRINCIPAL?.id || 0,
    postComment
  );

  const mutationSavePost = useSavePostRequest();
  const { t } = useTranslation();

  // methods

  const handleChange = (e) => {
    setPostComment(e.target.value);
  };

  const handlePost = () => {
    if (postComment.length > 0) {
      if (files[0]) {
        mutationAddAPost.mutate();
        showSnackBarMessage(t("Posting_3_dots"), "secondary");
      } else {
        const post = {
          personId: PRINCIPAL?.id || 0,
          comment: postComment,
        };
        mutationSavePost.mutate(post);
        showSnackBarMessage(t("Posting_3_dots"), "secondary");
      }
    }
  };

  //  Effects

  useEffect(() => {
    if (open) {
      setFiles([]);
      setPostComment("");
    }
  }, [open]);

  React.useEffect(() => {
    if (mutationAddAPost.isError) {
      console.log(mutationAddAPost.error);
      showSnackBarMessage(t("Error_uploading_post"), "error");
    }

    if (mutationAddAPost.isSuccess) {
      showSnackBarMessage(t("Posted_Successfully"), "success");
      setPostsList((prevList) => [mutationAddAPost.data.data, ...prevList]);
    }
  }, [mutationAddAPost.isPending]);

  React.useEffect(() => {
    if (mutationSavePost.isError) {
      console.log(mutationSavePost.error);
      showSnackBarMessage(t("Error_uploading_post"), "error");
    }

    if (mutationSavePost.isSuccess) {
      showSnackBarMessage(t("Posted_Successfully"), "success");
      setPostsList((prevList) => [mutationSavePost.data.data, ...prevList]);
    }
  }, [mutationSavePost.isPending]);

  return (
    <>
      {PRINCIPAL && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", md: "50%" },
              // height: 650,
              height: "70vh",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
            }}
          >
            {/* Top Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
                borderBottom: "1px solid gray",
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("Add_a_Post")}
              </Typography>
              <Button
                onClick={handleClose}
                size="small"
                color="error"
                variant="outlined"
                startIcon={<Close />}
                sx={{ textTransform: "none" }}
                disabled={
                  mutationAddAPost.isPending || mutationSavePost.isPending
                }
              >
                {t("Close")}
              </Button>
            </Box>

            {/* Main conaint */}
            <Box
              sx={{
                position: "relative",
                height: "75%",
                width: "100%",
                overflow: "auto",
                marginRight: 1,
              }}
            >
              {/* Drop Zone */}
              <Box sx={{ marginX: 2 }}>
                <section className="container">
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {files.length > 0 ? (
                      <Typography
                        sx={{ textWrap: "nowrap", textAlign: "center" }}
                      >
                        {t("File_selected")}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ textWrap: "nowrap", textAlign: "center" }}
                      >
                        <PhotoLibrary />
                        <br />
                        <br />
                        {t("Drag_n_Drop_Or_click")}
                      </Typography>
                    )}
                  </div>

                  <aside>
                    <Typography>{t("Maximum_1_image_file_allowed")}</Typography>
                  </aside>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                </section>
              </Box>

              <Box sx={{ paddingBottom: 4, paddingX: 2 }}>
                <TextField
                  id="outlined-basic"
                  label={t("Enter_a_comment")}
                  name="name"
                  type="text"
                  color="secondary"
                  multiline
                  required
                  maxRows={5}
                  value={postComment}
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                />
              </Box>
            </Box>

            {/* Footer */}
            <Box
              sx={{
                borderTop: "1px solid gray",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "space-between", md: "right" },
                }}
              >
                <Button
                  onClick={handleClose}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    minWidth: 100,
                  }}
                  disabled={
                    mutationAddAPost.isPending || mutationSavePost.isPending
                  }
                >
                  {t("Cancel")}
                </Button>

                <Button
                  onClick={(e) => {
                    handlePost();
                    handleClose();
                  }}
                  size="small"
                  color="secondary"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    marginLeft: { xs: 5, md: 10 },
                    minWidth: 100,
                  }}
                  disabled={
                    postComment.length < 1 ||
                    mutationAddAPost.isPending ||
                    mutationSavePost.isPending
                  }
                >
                  {mutationAddAPost.isPending || mutationSavePost.isPending ? (
                    <CircularProgress size={15} />
                  ) : (
                    t("Post")
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default ModalAddAPostCmp;
