// formatMyDate
const geti18nLocal = localStorage.getItem("i18nextLng") || "fr";
export const myFormatDate = (originDate) => {
  if (originDate !== null && originDate !== "") {
    let date = new Date(originDate);
    let goodDate = date.toLocaleDateString(geti18nLocal, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return goodDate;
  }
  return "";
};
