import { useEffect, useState } from "react";
import { useRef } from "react";
import { useViewBoutiquesProductsRequest } from "../../requestsApis/productsRequests";
import { Box, CircularProgress } from "@mui/material";
import ProductsCmp from "../homePage/ProductsCmp";

export const ProductsOfSameStore = ({ boutiqueId }) => {
  let pageNumProd = useRef(0);
  let pageSizeProd = useRef(12);
  let isFinishedProd = useRef(false);
  const [productsList, setProductsList] = useState([]);
  // query View Boutique Products
  const queryViewBoutiqueProducts = useViewBoutiquesProductsRequest(
    boutiqueId || 0,
    pageNumProd.current,
    pageSizeProd.current
  );

  //    *** Effects
  useEffect(() => {
    if (queryViewBoutiqueProducts.isSuccess && queryViewBoutiqueProducts.data) {
      setProductsList((prevProducts) => [
        ...prevProducts,
        ...queryViewBoutiqueProducts.data.data,
      ]);

      if (queryViewBoutiqueProducts.data.data.length < pageSizeProd.current) {
        isFinishedProd.current = true;
      }
    }
  }, [queryViewBoutiqueProducts.isPending]);

  return (
    <Box>
      <ProductsCmp products={productsList} />
      <Box
        sx={{
          margin: 4,
          display: isFinishedProd.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryViewBoutiqueProducts.isPending && <CircularProgress />}
      </Box>
    </Box>
  );
};
