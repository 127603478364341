import {
  ArticleOutlined,
  PersonOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import { Box, Card, Typography } from "@mui/material";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { useTranslation } from "react-i18next";

const OrderMini = ({ order, viewOrder, type }) => {
  const { t } = useTranslation();

  return (
    <Card
      onClick={() => viewOrder(order, type)}
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
        padding: 1,
        marginBottom: 2,
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
    >
      <Box
        sx={{
          width: 75,
          height: 75,
          border: "1px solid gray",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {order.productOfOrderList?.[0]?.product?.image?.length > 20 ? (
          <Box
            component="img"
            src={order.productOfOrderList?.[0]?.product?.image}
            alt="Order img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <ArticleOutlined
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: "bold",
          }}
        >
          {order.productOfOrderList?.[0]?.product?.priceCode} {order.totalPrice}
        </Typography>

        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 13,
          }}
        >
          <StoreOutlined fontSize="small" />
          {order.boutique?.name}
        </Typography>

        {order.senderPerson && (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: 13,
            }}
          >
            <PersonOutlined fontSize="small" />
            {t("By")} @{order.senderPerson.firstname}{" "}
            {order.senderPerson.lastname}
          </Typography>
        )}

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontSize: 12,
          }}
        >
          {myFormatDate(order.createdAt)}
        </Typography>
      </Box>
    </Card>
  );
};
export default OrderMini;
