import { Box, Grid } from "@mui/material";
import AVideo from "../../components/ui/AVideo";
import { useNavigate } from "react-router-dom";

const VideosCmp = ({ videosList, setVideosList }) => {
  const uniqueVideos = videosList.filter(
    (video, index, self) => index === self.findIndex((v) => v.id === video.id)
  );

  const navigate = useNavigate();

  // Methods ****************************
  const goToViewProduct = (productId) => {
    navigate(`/ViewProduct/${productId}`);
  };

  const goToViewCategoryProducts = (categoryId) => {
    navigate(`/ViewCategoryProducts/${categoryId}`);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {uniqueVideos.map((video) => (
          <Grid item xs={12} md={6} key={video.id} sx={{ marginX: "auto" }}>
            <AVideo
              video={video}
              goToViewProduct={goToViewProduct}
              goToViewCategoryProducts={goToViewCategoryProducts}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default VideosCmp;
