import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NewArrivalsCmp = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        paddingRight: { xs: 0, sm: 5, md: 10 },
        mt: 2,
        mb: 3,
        paddingLeft: { xs: 0, sm: 5, md: 10 },
      }}
    >
      <Typography
        variant="h6"
        textAlign="center"
        sx={{ fontSize: { xs: 14, sm: 20 } }}
      >
        {t("New_Arrivals")}
      </Typography>
      <Typography
        sx={{ fontSize: { xs: 12, sm: 15 } }}
        variant="body2"
        textAlign="center"
        color="text.secondary"
      >
        {t("Check_out_our_new_furniture_collection")}
      </Typography>
    </Box>
  );
};

export default NewArrivalsCmp;
