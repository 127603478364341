import {
  Delete,
  DeleteOutline,
  EditOutlined,
  FolderOutlined,
  MoreVertOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { RWebShare } from "react-web-share";
import { useEffect, useRef, useState } from "react";
import {
  useDeleteDossierRequest,
  useEditDossierRequest,
} from "../../requestsApis/dossiersRequest";
import { FRONT_URL } from "../../myHelpers/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ADossier = ({
  dossier,
  handleReplaceAfterEditDossier,
  handleDeletedDossier,
}) => {
  const navigate = useNavigate();

  // menu vars
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // edit Dossier vars
  const [openEditDossier, setOpenEditDossier] = useState(false);
  // keep old Dossier details before editing name of Dossier
  const [newDossier, setNewDossier] = useState({
    id: dossier.id,
    name: dossier.name,
  });

  // delete dossier vars
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const { t } = useTranslation();

  // share boutique vars
  const shareRef = useRef(null);

  // mutation edit Dossier name
  const mutationEditDossier = useEditDossierRequest(newDossier);

  // mutation delete Dossier
  const mutationDeleteDossier = useDeleteDossierRequest(dossier.id);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // edit Dossier logic
  const handleOpenEditDossier = () => {
    setOpenEditDossier(true);
  };

  const handleCloseEditDossier = () => {
    setOpenEditDossier(false);
  };

  // save edited Dossier name
  const handleSave = () => {
    handleCloseEditDossier();
    mutationEditDossier.mutate();
  };

  // delete Dossier logic
  const handleOpenDialogDeleteDossier = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDeleteDossier = () => {
    setOpenDialogDelete(false);
  };

  const handleDeleteDossier = () => {
    handleCloseDialogDeleteDossier();
    mutationDeleteDossier.mutate();
  };

  // share programmatic
  const handleProgrammaticShare = () => {
    shareRef.current?.click();
  };

  // View My Dossier
  const handleViewMyDossier = (event) => {
    if (event.target === event.currentTarget) {
      navigateToViewDossier();
    } else {
    }
  };

  // navigate to View My Dossier after Paper Clicked, or Dossier, Name, CreatedAt Clicked
  const navigateToViewDossier = () => {
    navigate(`/profile/myCatalogue/${dossier.id}`);
  };

  // edit effect
  useEffect(() => {
    if (mutationEditDossier.isError) {
      console.log(mutationEditDossier.error);
    }
    if (mutationEditDossier.isSuccess) {
      console.log(mutationEditDossier.data.data);
      handleReplaceAfterEditDossier(mutationEditDossier.data.data);
    }
  }, [mutationEditDossier.isPending]);

  // delete effect
  useEffect(() => {
    if (mutationDeleteDossier.isError) {
    }

    if (mutationDeleteDossier.isSuccess) {
      handleDeletedDossier(dossier);
    }
  }, [mutationDeleteDossier.isPending]);

  return (
    <Paper
      variant="outlined"
      onClick={handleViewMyDossier}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
        marginBottom: 2,
        borderRadius: 0,
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          width: { xs: "20%", md: "15%" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          onClick={navigateToViewDossier}
          sx={{
            textAlign: "center",
            cursor: "default",
            width: 40,
            height: 40,
            border: "1px solid gray",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {dossier.productList &&
          dossier.productList.length > 0 &&
          dossier.productList[0].image &&
          dossier.productList[0].image.length > 20 ? (
            <Box
              component="img"
              src={dossier.productList[0].image}
              alt="Catalogue img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <FolderOutlined
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </Box>
      </Box>

      {openEditDossier ? (
        <EditDossierCmp
          dossier={dossier}
          handleCloseEditDossier={handleCloseEditDossier}
          newDossier={newDossier}
          setNewDossier={setNewDossier}
          handleSave={handleSave}
        />
      ) : (
        <>
          <Typography
            onClick={navigateToViewDossier}
            sx={{
              textAlign: "center",
              width: { xs: "60%", md: "25%" },
              cursor: "default",
              fontSize: 15,
            }}
          >
            {mutationEditDossier.isPending ? (
              <CircularProgress size={20} />
            ) : (
              dossier.name
            )}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              textAlign: "center",
              width: "20%",
              display: { xs: "none", md: "inline-block" },
            }}
            onClick={navigateToViewDossier}
          >
            {dossier.totalProducts} {t("Products")}
          </Typography>
          <Typography
            variant="body2"
            onClick={navigateToViewDossier}
            color="textSecondary"
            sx={{
              textAlign: "center",
              width: "25%",
              display: { xs: "none", md: "inline-block" },
              cursor: "default",
            }}
          >
            {myFormatDate(dossier.createdAt)}
          </Typography>

          {/* Actions Buttons */}

          <Box
            sx={{
              textAlign: "center",
              width: { xs: "20%", md: "15%" },
              display: "flex",
              gap: 1,
              justifyContent: "center",
            }}
          >
            {mutationDeleteDossier.isPending ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
                  <IconButton
                    onClick={handleOpenEditDossier}
                    sx={{
                      color: "#348781",
                      backgroundColor: "#CFECEC",
                      borderRadius: 2,
                    }}
                  >
                    <FiEdit fontSize="medium" />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={handleOpenDialogDeleteDossier}
                    sx={{
                      backgroundColor: "pink",
                      borderRadius: 2,
                    }}
                  >
                    <FaRegTrashCan fontSize="medium" />
                  </IconButton>
                  <RWebShare
                    data={{
                      text: dossier.name,
                      url: `${FRONT_URL}/ViewDossierCart/${dossier.id}`,
                      title: `Share (${dossier.name}) "${FRONT_URL}/ViewDossierCart/${dossier.id}"`,
                    }}
                  >
                    <IconButton color="inherit" ref={shareRef}>
                      <ShareOutlined fontSize="small" />
                    </IconButton>
                  </RWebShare>
                </Box>
                <IconButton
                  color="inherit"
                  onClick={handleOpenMenu}
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <MoreVertOutlined fontSize="small" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                    onClick={(e) => {
                      handleCloseMenu(e);
                      handleProgrammaticShare();
                    }}
                  >
                    <ShareOutlined />
                    {t("to_Share")}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                    onClick={(e) => {
                      handleCloseMenu(e);
                      handleOpenEditDossier();
                    }}
                  >
                    <EditOutlined />
                    {t("Edit_name")}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                    onClick={(e) => {
                      handleCloseMenu(e);
                      handleOpenDialogDeleteDossier();
                    }}
                  >
                    <Delete color="error" />
                    {t("to_Delete")}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </>
      )}

      {/* Dialog Delete Dossier ? */}
      <DialogDeleteDossierCmp
        openDialogDelete={openDialogDelete}
        handleCloseDialogDeleteDossier={handleCloseDialogDeleteDossier}
        dossier={dossier}
        handleDeleteDossier={handleDeleteDossier}
      />
    </Paper>
  );
};
export default ADossier;

// Cmp Dialog Delete Dossier
const DialogDeleteDossierCmp = ({
  openDialogDelete,
  handleCloseDialogDeleteDossier,
  dossier,
  handleDeleteDossier,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={openDialogDelete}
      onClose={handleCloseDialogDeleteDossier}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" />
        <span>
          {t("to_Delete")}{" "}
          <i>
            <u>"{dossier.name}"</u>
          </i>{" "}
          {t("Catalog")} ?
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}>
          {t("You_are_about_to_delete")}{" "}
          <i>
            <u>"{dossier.name}"</u>
          </i>{" "}
          {t("Catalog")} !
          <br /> {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialogDeleteDossier}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteDossier}
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Cmp Edit Name

const EditDossierCmp = ({
  dossier,
  handleCloseEditDossier,
  newDossier,
  setNewDossier,
  handleSave,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setNewDossier({ ...newDossier, name: e.target.value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        dossier.name.trim() === newDossier.name.trim() ||
        newDossier.name.trim().length < 3
      ) {
        // give warning
      } else {
        handleSave();
      }
    }
  };

  return (
    <>
      <TextField
        autoFocus
        color="secondary"
        size="small"
        label={t("Enter_a_name")}
        variant="standard"
        defaultValue={dossier.name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={handleCloseEditDossier}
        >
          {t("Cancel")}
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleSave}
          disabled={
            dossier.name.trim() === newDossier.name.trim() ||
            newDossier.name.trim().length < 3
          }
        >
          {t("Save")}
        </Button>
      </Box>
    </>
  );
};
