import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLoginRequest } from "../../requestsApis/personRequest";
import { MuiTelInput } from "mui-tel-input";
import { LOGIN_EXPIRATION_STRING } from "../../myHelpers/constants";
import { useTranslation } from "react-i18next";
import SignUpInHeader from "../../components/ui/SignUpInHeader";
import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import DeviceAndroidComponent from "../../components/ui/DeviceAndroidComponent";

export default function SignIn() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [loginError, setLoginError] = useState(" ");
  const [loginType, setLoginType] = useState("email");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [passwordHelperText, setPasswordHelper] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [signInWithPhoneNum, setSignInWithPhoneNum] = useState(false);
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);

  const { t, i18n } = useTranslation();

  //  mutation Login
  const mutation = useLoginRequest(loginType, user);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneChange = (newPhone) => {
    setUser({
      ...user,
      phoneNumber: newPhone,
    });
  };

  const validateEmail = () => {
    if (user.email.length < 5) {
      setEmailHelperText(t("Enter_a_valid_email"));
      setErrorEmail(true);
      return false;
    } else {
      setEmailHelperText("");
      setErrorEmail(false);
      return true;
    }
  };

  const validatePhoneNumber = () => {
    if (user.phoneNumber.length < 9) {
      setPhoneNumberHelperText(t("Enter_a_valid_phone_number"));
      setErrorPhoneNumber(true);
      return false;
    } else {
      setPhoneNumberHelperText("");
      setErrorPhoneNumber(false);
      return true;
    }
  };

  const validatePassword = () => {
    if (user.password.length < 4) {
      setPasswordHelper(t("At_least_4_characters"));
      setErrorPassword(true);
      return false;
    } else {
      setPasswordHelper("");
      setErrorPassword(false);
      return true;
    }
  };

  // Login ()
  const login = () => {
    validatePassword();

    if (loginType === "email") {
      validateEmail();
      if (validateEmail() && validatePassword()) {
        mutation.mutate();
      }
    }

    if (loginType === "phone") {
      validatePhoneNumber();
      if (validatePhoneNumber() && validatePassword()) {
        mutation.mutate();
      }
    }
  };

  const handleSignInWithPhoneNum = () => {
    setSignInWithPhoneNum(true);
    setLoginType("phone");
  };

  const handleSignInWithEmail = () => {
    setSignInWithPhoneNum(false);
    setLoginType("email");
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  // Use Effects ************     *************
  useEffect(() => {
    if (mutation.isPending) {
      setLoginError("");
    }

    if (mutation.isError) {
      const error = mutation.error;
      setLoginError(
        error?.response?.data?.body
          ? error.response.data.body
          : t("Email_or_password_wrong")
      );
    }
    if (mutation.isSuccess) {
      localStorage.clear();

      const response = mutation.data;

      const jwtToken = response.headers.authorization;

      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem(
        "principal",
        JSON.stringify(response.data.principal)
      );

      // store expiration time
      const expirationTimestamp = new Date().getTime() + 23 * 60 * 60 * 1000;
      localStorage.setItem(LOGIN_EXPIRATION_STRING, expirationTimestamp);

      window.location.href = "/";
    }
  }, [mutation.isPending]);

  return (
    <>
      <DeviceAndroidComponent />
      <ContainerizedBox>
        <SignUpInHeader />
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: 24,
              fontWeight: "bold",
              marginTop: 5,
              marginBottom: 3,
            }}
          >
            {t("Login")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {signInWithPhoneNum ? (
              <Typography
                onClick={handleSignInWithEmail}
                color="primary"
                sx={{
                  cursor: "pointer",
                  mb: 2,
                  textAlign: "right",
                  width: { xs: "90%", sm: "25rem" },
                }}
              >
                {t("Sign_In_with_Email_questionMark")}
              </Typography>
            ) : (
              <Typography
                onClick={handleSignInWithPhoneNum}
                color="primary"
                sx={{
                  cursor: "pointer",
                  mb: 2,
                  textAlign: "right",
                  width: { xs: "90%", sm: "25rem" },
                }}
              >
                {t("Sign_In_with_Phone_Number_questionMark")}
              </Typography>
            )}
          </Box>

          <Typography variant="p" color="error">
            {loginError}
          </Typography>

          <Stack direction="column" spacing={3} alignItems="center">
            {signInWithPhoneNum ? (
              <MuiTelInput
                color="secondary"
                sx={{ width: { xs: "90%", sm: "25rem" } }}
                value={user.phoneNumber}
                onChange={handlePhoneChange}
                defaultCountry="US"
                forceCallingCode
                focusOnSelectCountry
                disableFormatting
                langOfCountryName={
                  i18n.language.slice(0, 2) === "fr" ||
                  i18n.language.slice(0, 2) === "en"
                    ? i18n.language.slice(0, 2)
                    : "fr"
                }
                error={errorPhoneNumber}
                helperText={phoneNumberHelperText}
                placeholder="000000000"
              />
            ) : (
              <TextField
                name="email"
                label={t("Email")}
                onChange={handleChange}
                required
                sx={{ width: { xs: "90%", sm: "25rem" } }}
                color="secondary"
                error={errorEmail}
                helperText={emailHelperText}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}

            <TextField
              type="password"
              name="password"
              label={t("Password")}
              onChange={handleChange}
              required
              sx={{ width: { xs: "90%", sm: "25rem" } }}
              color="secondary"
              error={errorPassword}
              helperText={passwordHelperText}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                },
              }}
            />

            <Button
              disabled={mutation.isPending}
              variant="contained"
              size="large"
              color="secondary"
              onClick={login}
              sx={{
                textTransform: "none",
                width: { xs: "90%", sm: "25rem" },
              }}
            >
              {mutation.isPending ? (
                <CircularProgress size={26} color="white" />
              ) : (
                t("To_Login")
              )}
            </Button>

            <Stack
              spacing={1}
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography>{t("Dont_have_an_account_questionMark")}</Typography>
              <Link to="/signUp">
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                >
                  {t("Sign_Up")}
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="https://podosoft.netlify.app/shopitemz-privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none", whiteSpace: "nowrap" }}
            >
              {t("Terms_of_Use")}
            </Button>
          </Link>{" "}
          |
          <Link
            to="https://podosoft.netlify.app/shopitemz-privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="text"
              color="secondary"
              sx={{ textTransform: "none", whiteSpace: "nowrap" }}
            >
              {t("Privacy_Policy")}
            </Button>
          </Link>
        </Box>
      </ContainerizedBox>
    </>
  );
}
