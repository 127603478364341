import { useQuery } from "@tanstack/react-query";
import { BACK_URL } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/main";

// Search Request
export const useSearchRequest = (
  query,
  type,
  sort,
  pageNum,
  pageSize,
  productIds
) => {
  const queryParams = {
    query: query,
    type: type,
    sort: sort,
    pageNum: pageNum,
    pageSize: pageSize,
    productIds: productIds,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/search?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
    refetchOnWindowFocus: false,
    enabled: query.length > 0,
  });
};

// HomePromote fetch
export const useFetchHomePromote = () => {
  const fullUrl = `${BACK_URL}/homePromote/view/homePromotes`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl);
    },
  });
};
