import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/dossierCart";

// Get Products From Dossier Request
export const useGetProductsFromDossierRequest = (
  dossierCartId,
  pageNum,
  pageSize
) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getProductsFromDossier/${dossierCartId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
  });
};

// Get Empty Dossiers Request
export const useGetEmptyDossiersRequest = (
  personId,
  pageNum,
  pageSize,
  enabled
) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getEmptyDossierCartsOfPerson/${personId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

// Delete Dossier Request
export const useDeleteDossierRequest = (dossierId) => {
  const queryParams = {
    id: dossierId,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/deleteDossierCart?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Edit Dossier Request
export const useEditDossierRequest = (dossier) => {
  return useMutation({
    mutationFn: async () => {
      const fullUrl = `${BACK_URL}${basePath}/editDossierCart`;

      return await axios.put(fullUrl, dossier, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Create Dossiers Request
export const useCreateDossiersRequest = (dossier) => {
  return useMutation({
    mutationFn: async () => {
      const fullUrl = `${BACK_URL}${basePath}/saveDossierCart`;

      return await axios.post(fullUrl, dossier, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Get Dossiers Request
export const useGetDossiersRequest = (personId, pageNum, pageSize) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getDossierCartsOfPerson/${personId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: personId !== 0,
  });
};
