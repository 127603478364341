import VideosWrapper from "./VideosWrapper";
import { useEffect } from "react";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";

const VideosPage = ({ navTo, setNavTo }) => {
  useEffect(() => {
    setNavTo(2);
  }, []);

  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />
      <VideosWrapper />
    </ContainerizedBox>
  );
};

export default VideosPage;
