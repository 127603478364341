import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ProductsCmp from "../homePage/ProductsCmp";
import { useViewProductsOfCategoryRequest } from "../../requestsApis/productsRequests";
import { useEffect, useRef, useState } from "react";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const ViewCategoryProducts = () => {
  const { categoryId } = useParams();
  let productIds = useRef([0]);
  const [productsList, setProductsList] = useState([]);
  const [category, setCategory] = useState(null);
  let pageSize = useRef(24);
  let isFinished = useRef(false);

  const { t, i18n } = useTranslation();

  const queryViewProductsOfCategory = useViewProductsOfCategoryRequest(
    categoryId,
    pageSize.current,
    productIds.current
  );

  const loadMoreProducts = () => {
    if (!isFinished.current) {
      if (!queryViewProductsOfCategory.isLoading) {
        let newIds = productsList.map((product) => product.id);
        productIds.current = newIds;
        queryViewProductsOfCategory.refetch();
      }
    }
  };

  useEffect(() => {
    if (queryViewProductsOfCategory.isError) {
      // console.log(queryViewProductsOfCategory.error);
    }

    if (
      queryViewProductsOfCategory.isSuccess &&
      queryViewProductsOfCategory.data
    ) {
      setProductsList((prevProducts) => [
        ...prevProducts,
        ...queryViewProductsOfCategory.data.data.products,
      ]);

      if (category === null) {
        setCategory(queryViewProductsOfCategory.data.data);
      }

      if (queryViewProductsOfCategory.data.data.products < pageSize.current) {
        isFinished.current = true;
      }
    }
  }, [queryViewProductsOfCategory.isFetching]);

  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />
      <Box sx={{ width: "100%" }}>
        <Typography variant="body2" color="text.secondary" sx={{ marginY: 1 }}>
          {t("Products_Category")} &gt;
          {category && (
            <Box
              component="span"
              sx={{ color: "secondary.main", marginLeft: 1 }}
            >
              {i18n.language.slice(0, 2) === "fr"
                ? category.nameFr
                : category.name}
            </Box>
          )}
        </Typography>

        <Divider sx={{ marginBottom: 4 }} />
      </Box>

      <ProductsCmp products={productsList} />

      <Box
        sx={{
          margin: 4,
          display: isFinished.current ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        {queryViewProductsOfCategory.isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            endIcon={<ExpandMoreOutlined />}
            onClick={loadMoreProducts}
            sx={{ whiteSpace: "nowrap", textTransform: "none" }}
          >
            {t("Load_more")}
          </Button>
        )}
      </Box>
    </ContainerizedBox>
  );
};

export default ViewCategoryProducts;
