import { Box, Card, CardMedia, Grid } from "@mui/material";

export const AProductVideo = ({
  productVideo,
  handleOpenModalViewProductVideo,
}) => {
  return (
    <Grid
      onClick={() => handleOpenModalViewProductVideo(productVideo)}
      item
      key={productVideo.id}
      sx={{ cursor: "pointer" }}
    >
      <Card elevation={0} sx={{ maxWidth: 50 }}>
        <CardMedia
          sx={{
            height: 50,
            width: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Placeholder background color
          }}
        >
          <Box
            component="img"
            src={productVideo.thumb}
            alt="video"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </CardMedia>
      </Card>
    </Grid>
  );
};
