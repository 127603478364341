import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Close, LocationOnOutlined, Refresh } from "@mui/icons-material";

import { G_Map_cle, PRINCIPAL } from "../../myHelpers/constants";

import { useState } from "react";
import { useEffect } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { useAddAddressOfPersonRequest } from "../../requestsApis/personRequest";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "88%", md: "70%", lg: "60%" },
  height: "87vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function ModalAddAddressLocation({
  showSnackBarMessage,
  principal,
  setPrincipal,
  clickBtnAddressFromVerify,
  openClickBtnAddressFromVerify,
  closeClickBtnAddressFromVerify,
  addLocationFromAboutPage,
  closeAddLocationFromAboutPage,
  queryProfile,
}) {
  const [openMap, setOpenMap] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [watchId, setWatchId] = useState(null);
  const [myAddress, setMyAddress] = useState({
    featureName: "",
    countryCode: "",
    countryName: "",
    addressLines: "",
    adminArea: "",
    subAdminArea: "",
    locality: "",
    thoroughfare: "",
    subThoroughfare: "",
    postalCode: "",
    latitude: null,
    longitude: null,
  });
  let countWatch = 1;
  const [typeAddress, setTypeAddress] = useState("second");
  const { t } = useTranslation();

  // mutations
  const mutationAddAddressOfPerson = useAddAddressOfPersonRequest(
    PRINCIPAL?.id || 0,
    typeAddress,
    myAddress
  );

  const handleOpenMap = () => {
    setOpenMap(true);
  };
  const handleCloseMap = () => {
    setOpenMap(false);
    if (clickBtnAddressFromVerify) {
      closeClickBtnAddressFromVerify();
    }

    if (addLocationFromAboutPage) {
      closeAddLocationFromAboutPage();
    }
  };

  // define the function that finds the users geolocation
  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      const idWatch = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          fetchAddressFromCoordinates(latitude, longitude);
        },

        (error) => {
          console.error("ERROR getting user location: ", error);
          setLoading(false);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000,
        }
      );

      setWatchId(idWatch);
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchAddressFromCoordinates = async (latitude, longitude) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat: latitude, lng: longitude };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const address = results[0];
        const addressComponents = address.address_components;

        const getAddressComponent = (type) =>
          addressComponents.find((comp) => comp.types.includes(type))
            ?.long_name || "";

        const getShortAddressComponent = (type) =>
          addressComponents.find((comp) => comp.types.includes(type))
            ?.short_name || "";

        countWatch = countWatch + 1;

        console.log(countWatch);

        if (countWatch < 3) {
          setMyAddress({
            featureName: getAddressComponent("plus_code"),
            countryCode: getShortAddressComponent("country"),
            countryName: getAddressComponent("country"),
            addressLines: address.formatted_address,
            adminArea: getAddressComponent("administrative_area_level_1"),
            subAdminArea: getAddressComponent("administrative_area_level_2"),
            locality: getAddressComponent("locality"),
            thoroughfare: getAddressComponent("route"),
            subThoroughfare: getAddressComponent("street_number"),
            postalCode: getAddressComponent("postal_code"),
            latitude,
            longitude,
          });
        }
      } else {
        console.error("Geocoder failed due to: ", status);
      }
      setLoading(false);
    });
  };

  const handleInputChange = (field, value) => {
    setMyAddress({ ...myAddress, [field]: value });
  };

  const handleSaveAddress = () => {
    mutationAddAddressOfPerson.mutate();
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (addLocationFromAboutPage) {
      handleOpenMap();
    }
  }, [addLocationFromAboutPage]);

  useEffect(() => {
    if (clickBtnAddressFromVerify) {
      handleOpenMap();
    }
  }, [clickBtnAddressFromVerify]);

  useEffect(() => {
    if (openMap) {
      getUserLocation();
    } else {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    }
  }, [openMap]);

  useEffect(() => {
    if (mutationAddAddressOfPerson.isError) {
      console.log(mutationAddAddressOfPerson.error);
      showSnackBarMessage(t("Error_Saving_Address"), "error");
    }

    if (mutationAddAddressOfPerson.isSuccess) {
      console.log(mutationAddAddressOfPerson.data.data);
      showSnackBarMessage(t("Saved_Address_Successfully"), "success");
      handleCloseMap();
      queryProfile.refetch();
    }
  }, [mutationAddAddressOfPerson.isPending]);

  return (
    <>
      {!principal.secondAddress && (
        <Button
          onClick={handleOpenMap}
          startIcon={<LocationOnOutlined />}
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ textTransform: "none" }}
        >
          {t("Add_Location")}
        </Button>
      )}

      {PRINCIPAL && (
        <APIProvider apiKey={G_Map_cle}>
          <Modal
            open={openMap}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {/* Top Header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 2,
                  py: 1,
                  borderBottom: "1px solid gray",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    fontSize={14}
                    fontWeight="bold"
                  >
                    {t("Add_Your_Address")}
                  </Typography>
                  {loading && <CircularProgress size={20} />}
                  {!loading && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={getUserLocation}
                    >
                      <Refresh />
                    </IconButton>
                  )}
                </Box>

                <Button
                  onClick={handleCloseMap}
                  size="small"
                  color="error"
                  variant="outlined"
                  startIcon={<Close />}
                  sx={{ textTransform: "none" }}
                >
                  {t("Close")}
                </Button>
              </Box>

              {/* Main containt */}
              <Box
                sx={{
                  position: "relative",
                  height: "83%",
                  width: "100%",
                  overflow: "auto",
                  marginRight: 1,
                }}
              >
                <Box sx={{ padding: 2 }}>
                  <Typography>
                    {t("Current_Location")}: {myAddress.addressLines}
                  </Typography>
                  <Box sx={{ height: 200, marginBottom: 3 }}>
                    <Map
                      zoom={15}
                      initialCenter={currentLocation}
                      center={currentLocation}
                    >
                      <Marker position={currentLocation} />
                    </Map>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("Country_Name")}
                        color="secondary"
                        fullWidth
                        value={myAddress.countryName}
                        onChange={(e) =>
                          handleInputChange("countryName", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("District")}
                        color="secondary"
                        fullWidth
                        value={myAddress.adminArea}
                        onChange={(e) =>
                          handleInputChange("adminArea", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("City")}
                        color="secondary"
                        fullWidth
                        value={myAddress.locality}
                        onChange={(e) =>
                          handleInputChange("locality", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("Street")}
                        color="secondary"
                        fullWidth
                        value={myAddress.thoroughfare}
                        onChange={(e) =>
                          handleInputChange("thoroughfare", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("Building")}
                        color="secondary"
                        fullWidth
                        value={myAddress.subThoroughfare}
                        onChange={(e) =>
                          handleInputChange("subThoroughfare", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("Postal_Code")}
                        color="secondary"
                        fullWidth
                        value={myAddress.postalCode}
                        onChange={(e) =>
                          handleInputChange("postalCode", e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Footer */}
              <Box
                sx={{
                  borderTop: "1px solid gray",
                  paddingTop: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={handleCloseMap}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      minWidth: 100,
                    }}
                  >
                    {t("Cancel")}
                  </Button>

                  <Button
                    onClick={handleSaveAddress}
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      minWidth: 100,
                    }}
                    disabled={
                      loading ||
                      mutationAddAddressOfPerson.isPending ||
                      myAddress === null ||
                      myAddress?.countryName === null ||
                      myAddress?.countryName === "" ||
                      myAddress?.adminArea === null ||
                      myAddress?.adminArea === "" ||
                      myAddress?.locality === null ||
                      myAddress?.locality === ""
                    }
                  >
                    {mutationAddAddressOfPerson.isPending ? (
                      <CircularProgress size={20} />
                    ) : (
                      t("Save")
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </APIProvider>
      )}
    </>
  );
}
