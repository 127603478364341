import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Close,
  ContentCopyRounded,
  OpenInNew,
  PhoneOutlined,
  StoreOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { Avatar, Card, IconButton } from "@mui/material";
import { PRINCIPAL } from "../myHelpers/constants";
import Link from "@mui/material/Link";
import { useState } from "react";
import { useEffect } from "react";
import { myFormatDate } from "../myHelpers/helperFunctions";
import copy from "copy-to-clipboard";
import { CustomSnackbar } from "../components/ui/CustomSnackbar";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "80%", md: "60%", lg: "50%" },
  // height: 650,
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export const useModalViewOrderCmp = () => {
  const [openModalViewOrder, setOpenModalViewOrder] = useState(false);
  const [orderToView, setOrderToView] = useState(null);
  // possible values: sent, received
  const [typeViewOrder, setTypeViewOrder] = useState(null);
  const [senderPerson, setSenderPerson] = useState(null);
  const [fee, setFee] = useState(0.0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  const { t } = useTranslation();

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  //  *****
  const handleOpenModalViewOrder = () => {
    setOpenModalViewOrder(true);
  };

  const handleCloseModalViewOrder = () => {
    setOpenModalViewOrder(false);
  };

  const handleCopyToClipboard = (text) => {
    copy(text);
    showSnackBarMessage(t("Copied_exclamative"), "success");
  };

  //  ******    Effects

  useEffect(() => {
    // possible values: sent, received
    if (typeViewOrder && typeViewOrder === "received") {
      setSenderPerson(orderToView.senderPerson);
    } else if (typeViewOrder && typeViewOrder === "sent") {
      setSenderPerson(PRINCIPAL);
    }
  }, [typeViewOrder, orderToView]);

  const ModalViewOrderComponent = () => (
    <Modal
      open={openModalViewOrder}
      onClose={handleCloseModalViewOrder}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* Top Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
            borderBottom: "1px solid gray",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Order_Details")}
          </Typography>
          <Button
            onClick={handleCloseModalViewOrder}
            size="small"
            color="error"
            variant="outlined"
            startIcon={<Close />}
            sx={{ textTransform: "none" }}
          >
            {t("Close")}
          </Button>
        </Box>

        {/* Main conaint */}
        {orderToView && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "90%",
              overflow: "auto",
              marginRight: 1,
            }}
          >
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                }}
              >
                <Avatar
                  src={senderPerson?.profile}
                  sx={{
                    width: 40,
                    height: 40,
                    mr: 2,
                    border: "2px solid gray",
                  }}
                />

                <Typography sx={{ wordBreak: "break-word" }}>
                  {senderPerson?.firstname} {senderPerson?.lastname}
                </Typography>
              </Box>

              <Box>
                <Typography fontWeight="bold">
                  {t("Attached_Address")} :
                </Typography>

                {orderToView.deliveryAddress && (
                  <Typography variant="body2" fontSize={13}>
                    {orderToView.deliveryAddress.addressLines
                      ? orderToView.deliveryAddress.addressLines
                      : `${orderToView.deliveryAddress.locality}, ${orderToView.deliveryAddress.subAdminArea}, ${orderToView.deliveryAddress.adminArea}, ${orderToView.deliveryAddress.countryName}`}
                  </Typography>
                )}

                {!orderToView.deliveryAddress && (
                  <Typography variant="body2" fontSize={13}>
                    {t("No_Address_Attached")} !
                  </Typography>
                )}
              </Box>

              <Box>
                {senderPerson?.phoneNumber && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mb: 1,
                    }}
                  >
                    <PhoneOutlined fontSize="small" />
                    <Typography sx={{ wordBreak: "break-all" }}>
                      {senderPerson.phoneNumber}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleCopyToClipboard(senderPerson.phoneNumber)
                      }
                      size="small"
                      sx={{
                        color: "#348781",
                        backgroundColor: "#CFECEC",
                        borderRadius: 2,
                      }}
                    >
                      <ContentCopyRounded fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}

                {senderPerson?.whatsapp && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <WhatsApp fontSize="small" color="success" />
                    <Typography sx={{ wordBreak: "break-all" }}>
                      {senderPerson.whatsapp}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleCopyToClipboard(senderPerson.whatsapp)
                      }
                      size="small"
                      sx={{
                        color: "#348781",
                        backgroundColor: "#CFECEC",
                        borderRadius: 2,
                      }}
                    >
                      <ContentCopyRounded fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
              </Box>

              <Link
                href={`/ViewBoutique/${orderToView.boutiqueId}`}
                target="_blank"
                color="inherit"
                underline="none"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 1,
                    marginTop: 3,
                    gap: 2,
                    "&:hover": {
                      color: "secondary.main",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StoreOutlined />
                    <Typography
                      sx={{
                        wordBreak: "break-word",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      {orderToView.boutique?.name}
                    </Typography>
                  </Box>

                  <OpenInNew />
                </Box>
              </Link>

              <Link
                href={`/ViewProduct/${orderToView.productOfOrderList[0]?.productId}`}
                target="_blank"
                color="inherit"
                underline="none"
              >
                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 3,
                    padding: 1,
                    marginBottom: 2,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 75,
                      height: 75,
                      border: "1px solid gray",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={orderToView.productOfOrderList[0]?.product?.image}
                      alt="Order img"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "50%", sm: "70%", md: "80%", lg: "90%" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={14}
                      sx={{
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {orderToView.productOfOrderList[0]?.product?.name}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontSize={15}
                      sx={{
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                      }}
                    >
                      {orderToView.productOfOrderList[0]?.product?.priceCode}{" "}
                      {orderToView.productOfOrderList[0]?.product?.price}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontSize={14}
                      fontWeight="bold"
                      sx={{
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {t("TOTAL")} :{" "}
                      {orderToView.productOfOrderList[0]?.product?.priceCode}{" "}
                      {orderToView.totalPrice}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "secondary.main",
                    }}
                  >
                    <Typography variant="body2">{t("Qty")}</Typography>
                    <Typography>
                      {orderToView.productOfOrderList[0]?.quantity}
                    </Typography>

                    <Typography>.</Typography>
                  </Box>
                </Card>
              </Link>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography>{t("Subtotal")}</Typography>

                <Typography>
                  {orderToView.productOfOrderList[0]?.product?.priceCode}{" "}
                  {orderToView.totalPrice}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography>{t("Fee")}</Typography>

                <Typography>
                  {orderToView.productOfOrderList[0]?.product?.priceCode} {fee}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 2,
                }}
              >
                <Typography fontWeight="bold" variant="h6">
                  {t("TOTAL")}
                </Typography>

                <Typography fontWeight="bold" variant="h6">
                  {orderToView.productOfOrderList[0]?.product?.priceCode}{" "}
                  {orderToView.totalPrice}
                </Typography>
              </Box>

              <Typography fontWeight="bold">{t("Comment")}:</Typography>
              <Typography>{orderToView.description}</Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontSize: 12,
                  textAlign: "right",
                }}
              >
                {t("Sent_on")}: {myFormatDate(orderToView.createdAt)}
              </Typography>
            </Box>
          </Box>
        )}

        <CustomSnackbar
          message={messageSnakbar}
          severity={severitySnackbar}
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
        />
      </Box>
    </Modal>
  );

  return {
    ModalViewOrderComponent,
    handleOpenModalViewOrder,
    setOrderToView,
    setTypeViewOrder,
  };
};
