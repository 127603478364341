import {
  ArrowBack,
  EditOutlined,
  LinkOutlined,
  PhoneIphoneOutlined,
  Verified,
  WarningAmberOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useEditPasswordRequest,
  useEditPersonRequest,
} from "../../requestsApis/personRequest";
import { MuiTelInput } from "mui-tel-input";
import { FiEdit } from "react-icons/fi";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

function AddressElementTag({ title, title2, content }) {
  return (
    <Grid item xs={6} sm={4} md={6}>
      <Box textAlign="center">
        <Typography fontWeight="bold">{title}</Typography>
        {title2 && <Typography fontWeight="bold">{title2}</Typography>}
        <Typography variant="body2">{content}</Typography>
      </Box>
    </Grid>
  );
}

const StyledBoxAcInf = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: 2,
  paddingBottom: 2,
  marginBottom: 15,
}));

const StyledTypographyAcInfTitle = styled(Typography)(({ theme }) => ({
  wordBreak: "break-word",
  fontWeight: "bold",
  fontSize: 14,
  marginBottom: 8,
}));

const StyledTypographyAcInfValue = styled(Typography)(({ theme }) => ({
  wordBreak: "break-word",
}));

const AboutMePage = ({
  setSelectedTab,
  principal,
  setPrincipal,
  addWhatsappFromProfileHeader,
  closeAddWhatsappFromProfileHeader,
  addLocationFromAboutPage,
  openAddLocationFromAboutPage,
  verifyFromAboutPage,
  openVerifyFromAboutPage,
}) => {
  // edit person logic
  const [openDialogEditPerson, setOpenDialogEditPerson] = useState(false);
  // 0:no operation;   1:Full name;   2:Email;
  // 3:Phone Number;    4:Whatsapp;   5:Password
  const [typeOperation, setTypeOperation] = useState(0);

  const [personToEdit, setPersonToEdit] = useState(null);

  // password logic
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { t } = useTranslation();

  //  Mutations               *********
  // mut fullname, email, phoneNumber, whatsapp
  const mutationEditPerson = useEditPersonRequest(personToEdit);

  // mut password
  const mutationEditPassword = useEditPasswordRequest(
    principal?.id || 0,
    oldPassword,
    newPassword
  );

  // Methods    **************************
  const handleOpenDialogEditPerson = () => {
    setOpenDialogEditPerson(true);
  };

  const handleCloseDialogEditPerson = () => {
    setOpenDialogEditPerson(false);
    if (addWhatsappFromProfileHeader) {
      closeAddWhatsappFromProfileHeader();
    }
  };

  // handle fullname
  const handleEditFullname = () => {
    setTypeOperation(1);
    setPersonToEdit({
      id: principal?.id,
      firstname: principal?.firstname,
      lastname: principal?.lastname,
    });
    handleOpenDialogEditPerson();
  };

  // handle email
  const handleEditEmail = () => {
    setTypeOperation(2);
    setPersonToEdit({
      id: principal?.id,
      email: principal?.email,
    });
    handleOpenDialogEditPerson();
  };

  // handle phoneNumber
  const handleEditPhoneNumber = () => {
    setTypeOperation(3);
    setPersonToEdit({
      id: principal?.id,
      phoneNumber: principal?.phoneNumber,
    });
    handleOpenDialogEditPerson();
  };

  // handle Whatsapp
  const handleEditWhatsapp = () => {
    setTypeOperation(4);
    setPersonToEdit({
      id: principal?.id,
      whatsapp: principal?.whatsapp,
    });
    handleOpenDialogEditPerson();
  };

  // handle Password
  const handleEditPassword = () => {
    setTypeOperation(5);
    handleOpenDialogEditPerson();
  };

  // mutate person
  const handleMutatePerson = () => {
    mutationEditPerson.mutate();
  };

  const handleMutatePassword = () => {
    mutationEditPassword.mutate();
  };

  const handleEditOperation = () => {
    handleCloseDialogEditPerson();
    if (
      typeOperation === 1 ||
      typeOperation === 2 ||
      typeOperation === 3 ||
      typeOperation === 4
    ) {
      handleMutatePerson();
    }

    if (typeOperation === 5) {
      handleMutatePassword();
    }
  };

  // ********************     Effects
  useEffect(() => {
    setSelectedTab(5);
  }, []);

  // edit person
  useEffect(() => {
    if (mutationEditPerson.isError) {
      console.log(mutationEditPerson.error);
    }

    if (mutationEditPerson.isSuccess) {
      const savedPrincipal = mutationEditPerson.data?.data?.principal;
      let newPrincipal = {
        ...principal,
        firstname: savedPrincipal.firstname,
        lastname: savedPrincipal.lastname,
        email: savedPrincipal.email,
        phoneNumber: savedPrincipal.phoneNumber,
        whatsapp: savedPrincipal.whatsapp,
      };
      localStorage.setItem("principal", JSON.stringify(newPrincipal));
      setPrincipal(newPrincipal);
    }
  }, [mutationEditPerson.isPending]);

  // edit password
  useEffect(() => {
    if (mutationEditPassword.isError) {
      console.log(mutationEditPassword.error);
    }

    if (mutationEditPassword.isSuccess) {
      const savedPrincipal = mutationEditPassword.data?.data?.principal;
    }
  }, [mutationEditPassword.isPending]);

  // Add whatsapp from profile header
  useEffect(() => {
    if (addWhatsappFromProfileHeader) {
      handleEditWhatsapp();
    }
  }, [addWhatsappFromProfileHeader]);

  if (!principal) return null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <DialogLoginExpiredComponent />
        <Paper variant="outlined" sx={{ height: 470 }}>
          <Box
            sx={{
              backgroundColor: "#FFF5E5",
              padding: 1,
              borderRadius: 1,
            }}
          >
            <Typography variant="h6">{t("Account_Informations")}</Typography>
          </Box>
          <Stack direction="column" px={1} mt={1}>
            <StyledBoxAcInf>
              <Box>
                <StyledTypographyAcInfTitle>
                  {t("Full_name")}
                </StyledTypographyAcInfTitle>
                <StyledTypographyAcInfValue variant="body2">
                  {principal.firstname} {principal.lastname}
                </StyledTypographyAcInfValue>
              </Box>
              {typeOperation === 1 && mutationEditPerson.isPending ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  onClick={handleEditFullname}
                  startIcon={<EditOutlined fontSize="small" />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("Edit")}</u>
                </Button>
              )}
            </StyledBoxAcInf>

            <StyledBoxAcInf>
              <Box>
                <StyledTypographyAcInfTitle
                  sx={{ wordBreak: "break-word", fontWeight: "bold" }}
                >
                  {t("Email")}
                </StyledTypographyAcInfTitle>
                <StyledTypographyAcInfValue variant="body2">
                  {principal.email ? principal.email : "..."}
                </StyledTypographyAcInfValue>
              </Box>
              {typeOperation === 2 && mutationEditPerson.isPending ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  onClick={handleEditEmail}
                  startIcon={<EditOutlined fontSize="small" />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("Edit")}</u>
                </Button>
              )}
            </StyledBoxAcInf>

            <StyledBoxAcInf>
              <Box>
                <StyledTypographyAcInfTitle
                  sx={{ wordBreak: "break-word", fontWeight: "bold" }}
                >
                  {t("Phone_Number")}
                </StyledTypographyAcInfTitle>
                <StyledTypographyAcInfValue variant="body2">
                  {principal.phoneNumber}
                </StyledTypographyAcInfValue>
              </Box>
              {typeOperation === 3 && mutationEditPerson.isPending ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  onClick={handleEditPhoneNumber}
                  startIcon={<EditOutlined fontSize="small" />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("Edit")}</u>
                </Button>
              )}
            </StyledBoxAcInf>

            <StyledBoxAcInf>
              <Box>
                <StyledTypographyAcInfTitle
                  sx={{ wordBreak: "break-word", fontWeight: "bold" }}
                >
                  Whatsapp
                </StyledTypographyAcInfTitle>
                <StyledTypographyAcInfValue variant="body2">
                  {principal.whatsapp ? principal.whatsapp : "..."}
                </StyledTypographyAcInfValue>
              </Box>
              {typeOperation === 4 && mutationEditPerson.isPending ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  onClick={handleEditWhatsapp}
                  startIcon={<EditOutlined fontSize="small" />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("Edit")}</u>
                </Button>
              )}
            </StyledBoxAcInf>

            <StyledBoxAcInf>
              <Box>
                <StyledTypographyAcInfTitle
                  sx={{ wordBreak: "break-word", fontWeight: "bold" }}
                >
                  {t("Password")}
                </StyledTypographyAcInfTitle>
                <StyledTypographyAcInfValue variant="body2">
                  ***********
                </StyledTypographyAcInfValue>
              </Box>
              {typeOperation === 5 && mutationEditPassword.isPending ? (
                <CircularProgress size={20} />
              ) : (
                <Button
                  onClick={handleEditPassword}
                  startIcon={<EditOutlined fontSize="small" />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("Edit")}</u>
                </Button>
              )}
            </StyledBoxAcInf>

            <StyledBoxAcInf>
              <Box>
                <StyledTypographyAcInfTitle
                  sx={{ wordBreak: "break-word", fontWeight: "bold" }}
                >
                  {t("Account_Verified")} ?
                </StyledTypographyAcInfTitle>
                <StyledTypographyAcInfValue variant="body2" fontWeight="bold">
                  <Box
                    component="span"
                    sx={{
                      color: principal.accountVerified
                        ? "primary.main"
                        : "error.main",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {principal.accountVerified ? (
                      <>
                        <Verified color="primary" fontSize="small" />
                        {t("Verified")}
                      </>
                    ) : (
                      t("Not_Verified")
                    )}
                  </Box>
                </StyledTypographyAcInfValue>
              </Box>

              {!principal.accountVerified && (
                <Button
                  onClick={openVerifyFromAboutPage}
                  startIcon={<WarningAmberOutlined />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("to_Verify")}</u>
                </Button>
              )}
            </StyledBoxAcInf>
          </Stack>
        </Paper>
      </Grid>

      {/* Address Bottom Section */}

      <Grid item xs={12} md={6}>
        <Paper variant="outlined">
          <Box
            sx={{
              bgcolor: "background.paper",
              textAlign: "center",
              height: 470,
            }}
          >
            <Box
              sx={{
                mb: 2,
                overflow: "hidden",
              }}
            >
              <Box
                className="ad-top-title"
                sx={{
                  backgroundColor: "#ADDFFF",
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">{t("Address")}</Typography>
                <Button
                  onClick={openAddLocationFromAboutPage}
                  startIcon={<FiEdit fontSize="medium" />}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: "secondary.main",
                  }}
                >
                  <u>{t("Edit")}</u>
                </Button>
              </Box>

              <Box
                component="img"
                src="/images/google_map_icon_on_map.png"
                alt="Map img"
                sx={{
                  width: "100%",
                  height: 100,
                  objectFit: "fill",
                  transition: "transform 0.7s ease",
                  transformOrigin: "center",
                  cursor: "pointer",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              />
            </Box>

            {principal.secondAddress ? (
              <Grid container spacing={5}>
                <AddressElementTag
                  title={t("Address_Lines")}
                  content={principal.secondAddress.addressLines}
                />

                <AddressElementTag
                  title={t("Country")}
                  content={principal.secondAddress.countryName}
                />

                <AddressElementTag
                  title={t("District")}
                  content={principal.secondAddress.adminArea}
                />

                <AddressElementTag
                  title={t("Department")}
                  content={principal.secondAddress.subAdminArea}
                />

                <AddressElementTag
                  title={t("Locality")}
                  content={principal.secondAddress.locality}
                />

                <AddressElementTag
                  title={t("Street_Building")}
                  content={`${principal.secondAddress.street}, ${principal.secondAddress.building}`}
                />
              </Grid>
            ) : (
              <Typography>{t("No_Address_Yet")}</Typography>
            )}
          </Box>
        </Paper>
      </Grid>

      <DialogEditPersonCmp
        openDialog={openDialogEditPerson}
        handleCloseDialog={handleCloseDialogEditPerson}
        person={personToEdit}
        setPerson={setPersonToEdit}
        typeOperation={typeOperation}
        handleEditOperation={handleEditOperation}
        principal={principal}
        oldPassword={oldPassword}
        setOldPassword={setOldPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
      />
    </Grid>
  );
};
export default AboutMePage;

// Cmp Dialog Edit Person   ***************     *******************
const DialogEditPersonCmp = ({
  openDialog,
  handleCloseDialog,
  person,
  setPerson,
  typeOperation,
  handleEditOperation,
  principal,
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const [hideUseWhaNum, setHideUseWhaNum] = useState(false);
  const [hideAddWhaNum, setHideAddWhaNum] = useState(false);
  const [hideAddWhaLink, setHideAddWhaLink] = useState(false);
  // error phone
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);

  const { t } = useTranslation();

  // hide show Use
  const handleHideUseWhaNum = () => {
    setHideUseWhaNum(true);
  };

  const handleShowUseWhaNum = () => {
    setHideUseWhaNum(false);
  };

  // hide show Add Num
  const handleHideAddWhaNum = () => {
    setHideAddWhaNum(true);
  };

  const handleShowAddWhaNum = () => {
    setHideAddWhaNum(false);
  };

  // hide show Add Link
  const handleHideAddWhaLink = () => {
    setHideAddWhaLink(true);
  };

  const handleShowAddWhaLink = () => {
    setHideAddWhaLink(false);
  };

  // handle Change
  const handleChange = (e) => {
    setPerson({ ...person, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (newPhone) => {
    setPerson({
      ...person,
      phoneNumber: newPhone,
    });
  };

  // handle Use Phone
  const handleUsePhoneNumber = () => {
    setPerson({ ...person, whatsapp: principal.phoneNumber });
    handleHideAddWhaNum();
    handleHideAddWhaLink();
  };

  const validatePhoneNumber = () => {
    if (person.phoneNumber.length < 9) {
      setPhoneNumberHelperText(t("Enter_a_valid_phone_number"));
      setErrorPhoneNumber(true);
      return false;
    } else {
      setPhoneNumberHelperText("");
      setErrorPhoneNumber(false);
      return true;
    }
  };

  // handle Add Whatsapp Number
  const handleAddWhatsappNumber = () => {
    handleHideUseWhaNum();
    handleHideAddWhaLink();
  };

  // handle Add Whatsapp Link
  const handleAddWhatsappLink = () => {
    handleHideUseWhaNum();
    handleHideAddWhaNum();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        handleCloseDialog();
        handleShowUseWhaNum();
        handleShowAddWhaNum();
        handleShowAddWhaLink();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        {t("Edit_Personal_Informations")}
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{ paddingY: 1 }}
          direction="column"
          spacing={3}
          alignItems="center"
        >
          {typeOperation === 1 && (
            <>
              <TextField
                name="firstname"
                label={t("First_name")}
                required
                value={person.firstname}
                onChange={handleChange}
                sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
                color="secondary"
              />
              <TextField
                name="lastname"
                label={t("Last_name")}
                value={person.lastname}
                onChange={handleChange}
                sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
                color="secondary"
              />
            </>
          )}

          {typeOperation === 2 && (
            <TextField
              name="email"
              label={t("Email")}
              required
              value={person.email}
              onChange={handleChange}
              sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
              color="secondary"
            />
          )}

          {typeOperation === 3 && (
            <Box>
              <Typography sx={{ textAlign: "left" }}>
                {t("Phone_Number")} *
              </Typography>
              <MuiTelInput
                color="secondary"
                sx={{ width: { xs: "90%", sm: "25rem" } }}
                value={person.phoneNumber}
                onChange={handlePhoneChange}
                name="phoneNumber"
                defaultCountry="US"
                forceCallingCode
                focusOnSelectCountry
                disableFormatting
                error={errorPhoneNumber}
                helperText={phoneNumberHelperText}
                placeholder="000000000"
              />
            </Box>
          )}

          {typeOperation === 4 && (
            <>
              {(hideUseWhaNum || hideAddWhaNum || hideAddWhaLink) && (
                <Box width="100%">
                  <Button
                    onClick={() => {
                      handleShowUseWhaNum();
                      handleShowAddWhaNum();
                      handleShowAddWhaLink();
                    }}
                    startIcon={<ArrowBack />}
                  >
                    {t("Back")}
                  </Button>
                </Box>
              )}

              {!hideUseWhaNum && (
                <Button
                  onClick={handleUsePhoneNumber}
                  startIcon={<PhoneIphoneOutlined />}
                  variant="outlined"
                  color="secondary"
                  sx={{ textTransform: "none", width: "100%", paddingX: 5 }}
                >
                  {t("to_Use")} {principal.phoneNumber} {t("for_WhatsApp")}
                </Button>
              )}

              {!hideAddWhaNum && (
                <>
                  <Button
                    onClick={handleAddWhatsappNumber}
                    startIcon={<PhoneIphoneOutlined />}
                    color="secondary"
                    variant="outlined"
                    sx={{ textTransform: "none", width: "100%", paddingX: 5 }}
                  >
                    {t("Add_another_WhatsApp_Number")}
                  </Button>

                  {!hideAddWhaNum && hideAddWhaLink && hideUseWhaNum && (
                    <Box>
                      <Typography sx={{ textAlign: "left" }}>
                        {t("Phone_number")} *
                      </Typography>
                      <MuiTelInput
                        color="secondary"
                        sx={{ width: { xs: "90%", sm: "25rem" } }}
                        value={person.whatsapp}
                        onChange={(phone) => {
                          setPerson({
                            ...person,
                            whatsapp: phone,
                          });
                        }}
                        name="phoneNumber"
                        defaultCountry="US"
                        forceCallingCode
                        focusOnSelectCountry
                        disableFormatting
                        placeholder="000000000"
                      />
                    </Box>
                  )}
                </>
              )}

              {!hideAddWhaLink && (
                <>
                  <Button
                    onClick={handleAddWhatsappLink}
                    startIcon={<LinkOutlined />}
                    color="secondary"
                    variant="outlined"
                    sx={{ textTransform: "none", width: "100%", paddingX: 5 }}
                  >
                    {t("Add_WhatsApp_link")}
                  </Button>

                  {!hideAddWhaLink && hideAddWhaNum && hideUseWhaNum && (
                    <TextField
                      name="whatsapp"
                      label={t("Paste_Link")}
                      onChange={handleChange}
                      sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
                      color="secondary"
                    />
                  )}
                </>
              )}
            </>
          )}

          {typeOperation === 5 && (
            <>
              <TextField
                type="password"
                label={t("Old_Password")}
                required
                sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
                color="secondary"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />

              <TextField
                type="password"
                label={t("New_Password")}
                required
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
                color="secondary"
              />

              <TextField
                type="password"
                label={t("Confirm_new_Password")}
                required
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
                color="secondary"
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            handleCloseDialog();
            handleShowUseWhaNum();
            handleShowAddWhaNum();
            handleShowAddWhaLink();
          }}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => {
            if (typeOperation === 3) {
              if (validatePhoneNumber()) {
                handleCloseDialog();
                handleShowUseWhaNum();
                handleShowAddWhaNum();
                handleShowAddWhaLink();
                handleEditOperation();
              }
            } else {
              handleCloseDialog();
              handleShowUseWhaNum();
              handleShowAddWhaNum();
              handleShowAddWhaLink();
              handleEditOperation();
            }
          }}
          variant="contained"
          color="secondary"
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
