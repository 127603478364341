import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  MY_COUNTRIES,
  PRODUCTS_LOCATION_COUNTRY,
  PRODUCTS_LOCATION_COUNTRY_STRING,
} from "../../myHelpers/constants";
import { useState } from "react";
import { LanguageOutlined, PlaceOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PopoverProdLocationNav() {
  // Popover ProdLocation logic
  const navigate = useNavigate();
  const [anchorElPopoverProdLocation, setAnchorElPopoverProdLocation] =
    useState(null);
  const openPopoverProdLocation = Boolean(anchorElPopoverProdLocation);
  const idPopoverProdLocation = openPopoverProdLocation
    ? "simple-popover"
    : undefined;
  const urlLocation = useLocation();
  const { t } = useTranslation();

  // PopoverProdLocation logic methods
  const handleClickPopoverProdLocation = (e) => {
    setAnchorElPopoverProdLocation(e.currentTarget);
  };
  const handleClosePopoverProdLocation = () => {
    setAnchorElPopoverProdLocation(null);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleChangedLocation = (event, option) => {
    if (option !== null) {
      localStorage.setItem(
        PRODUCTS_LOCATION_COUNTRY_STRING,
        JSON.stringify(option)
      );
      handleClosePopoverProdLocation();
      window.location.href = "/";
    }
  };

  const handleChooseAllCountries = () => {
    localStorage.removeItem(PRODUCTS_LOCATION_COUNTRY_STRING);
    handleClosePopoverProdLocation();
    // if we are not on home page
    if (urlLocation.pathname !== "") {
      navigate("/");
    }
    handleRefresh();
  };

  return (
    <>
      <IconButton
        onClick={handleClickPopoverProdLocation}
        color="inherit"
        size="small"
        sx={{
          backgroundColor: openPopoverProdLocation ? "#E6E6FA" : "inherit",
        }}
      >
        {PRODUCTS_LOCATION_COUNTRY ? (
          <Box component="span" sx={{ "& > img": { flexShrink: 0 } }}>
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${PRODUCTS_LOCATION_COUNTRY.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${PRODUCTS_LOCATION_COUNTRY.code.toLowerCase()}.png`}
              alt=""
            />
          </Box>
        ) : (
          <PlaceOutlined fontSize="inherit" />
        )}
      </IconButton>
      <Popover
        sx={{ marginTop: 2, marginBottom: 4 }}
        id={idPopoverProdLocation}
        open={openPopoverProdLocation}
        anchorEl={anchorElPopoverProdLocation}
        onClose={handleClosePopoverProdLocation}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            padding: 2,
            width: { xs: "83vw", sm: 400 },
            minHeight: 450,
          }}
        >
          <Typography
            variant="h6"
            mb={2}
            fontWeight="bold"
            sx={{ fontSize: { xs: 15, sm: 20 }, textAlign: "center" }}
          >
            {t("Get_Products_from_a_specific_Country")}
          </Typography>
          <Box>
            <Button
              onClick={handleChooseAllCountries}
              size="large"
              variant="outlined"
              sx={{ width: "100%", marginBottom: 2, textTransform: "none" }}
              startIcon={<LanguageOutlined />}
            >
              {t("All_countries")}
            </Button>
            <Autocomplete
              id="country-select-demo"
              sx={{ width: "100%" }}
              options={MY_COUNTRIES}
              autoHighlight
              size="small"
              autoFocus
              onChange={handleChangedLocation}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box
                    key={key}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Choose_a_country")}
                  color="secondary"
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    },
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
}
