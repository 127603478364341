import { useMutation } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";

const basePath = "/followBoutique";

// Follow Boutique Request
export const useFollowBoutiqueRequest = () => {
  return useMutation({
    mutationFn: async (followBoutique) => {
      const fullUrl = `${BACK_URL}${basePath}/followBoutique`;

      return await axios.post(fullUrl, followBoutique, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Delete Follow Boutique Request
export const useDeleteFollowBoutiqueRequest = (boutiqueId, personId) => {
  const queryParams = {
    boutiqueId: boutiqueId,
    personId: personId,
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/unFollowBoutiquePerson?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.delete(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};
