import { Home, PhotoOutlined, YouTube } from "@mui/icons-material";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelectCmp from "../ui/LanguageSelectCmp";

const menuList = [
  { listItemText: "Home", listItemIcon: <Home /> },
  { listItemText: "Posts", listItemIcon: <PhotoOutlined /> },
  { listItemText: "Videos", listItemIcon: <YouTube /> },
];

const Sidebar = ({
  selectedMenuListIndex,
  setSelectedMenuListIndex,
  openDrawer,
  setOpenDrawer,
  getUrl,
  navTo,
  setNavTo,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMenuListItemClick = (event, index) => {
    setNavTo(index);
    navigate(getUrl(index !== 0 ? menuList[index].listItemText : ""));
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{ padding: 3, paddingRight: 6 }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Link
        to={`/`}
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        <img src="/images/logo_100_px.png" width="30" height="30" alt="Logo" />
        <Typography
          variant="h6"
          sx={{
            whiteSpace: "nowrap",
            fontWeight: "bold",
            fontSize: { xs: 20, md: 30 },
            "&:hover": {
              color: "secondary.main",
            },
          }}
        >
          Shop itemz
        </Typography>
      </Link>

      <List>
        {menuList.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              selected={navTo === index}
              onClick={(event) => handleMenuListItemClick(event, index)}
            >
              <ListItemIcon>{item.listItemIcon}</ListItemIcon>
              <ListItemText primary={t(item.listItemText)} />
            </ListItemButton>
          </ListItem>
        ))}

        {/* Dark Mode */}
        {/* <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ModeNight />
            </ListItemIcon>
            <Switch />
          </ListItemButton>
        </ListItem> */}
      </List>

      <LanguageSelectCmp />
    </Box>
  );

  return (
    <>
      <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
};

export default Sidebar;
