import { ArticleOutlined, StoreOutlined } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { useTranslation } from "react-i18next";

const AnOrder = ({ order }) => {
  const { t } = useTranslation();
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: { xs: 2, md: 0 },
        padding: 1,
        marginBottom: 2,
        borderRadius: 0,
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          width: { xs: "30%", md: "15%" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            cursor: "default",
            width: { xs: 75, md: 40 },
            height: { xs: 75, md: 40 },
            border: "1px solid gray",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {order.productOfOrderList?.[0]?.product?.image?.length > 20 ? (
            <Box
              component="img"
              src={order.productOfOrderList?.[0]?.product?.image}
              alt="Order img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <ArticleOutlined
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: { xs: "70%", md: "85%" },
        }}
      >
        <Typography
          sx={{
            textAlign: { xs: "start", md: "center" },
            width: { xs: "100%", md: "25%" },
            cursor: "default",
            fontSize: { xs: 13, md: 15 },
          }}
        >
          {order.productOfOrderList?.[0]?.product?.priceCode} {order.totalPrice}
        </Typography>

        <Typography
          sx={{
            textAlign: { xs: "start", md: "center" },
            width: { xs: "100%", md: "35%" },
            cursor: "default",
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "start", md: "center" },
            fontSize: { xs: 13, md: 15 },
          }}
        >
          <StoreOutlined fontSize="small" />
          {order.boutique?.name}
        </Typography>

        <Typography
          color="warning"
          sx={{
            textAlign: { xs: "start", md: "center" },
            width: { xs: "100%", md: "15%" },
            cursor: "default",
            display: { md: "inline-block" },
            fontSize: { xs: 13, md: 15 },
          }}
        >
          {t("No_payment_yet")}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textAlign: { xs: "start", md: "center" },
            width: { xs: "100%", md: "25%" },
            display: { md: "inline-block" },
            cursor: "default",
          }}
        >
          {myFormatDate(order.createdAt)}
        </Typography>
      </Box>
    </Paper>
  );
};
export default AnOrder;
