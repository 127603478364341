import {
  CancelSharp,
  Delete,
  ExpandMoreOutlined,
  MoreVertOutlined,
  ReportOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { FRONT_URL, PRINCIPAL } from "../../myHelpers/constants";
import { IoSend } from "react-icons/io5";

import PostCommentsWrapperCmp from "./PostCommentsWrapperCmp";
import { RWebShare } from "react-web-share";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalViewAPostCmp = ({
  open,
  handleClose,
  post,
  loadMorePostComments,
  queryViewRecentPostComments,
  postCommentsList,
  isFinishedPostComments,
  urlIsMyPosts,
  handleOpenDialogDeletePost,
  myComment,
  handleChangeMyComment,
  handleAddComment,
  handleDeletePostComment,
}) => {
  const [fullname, setFullname] = useState(
    `${post.person?.firstname} ${post.person?.lastname}`
  );

  const { t } = useTranslation();

  // menu vars
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  // share post vars
  const shareRef = useRef(null);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // share programmatic
  const handleProgrammaticShare = () => {
    shareRef.current?.click();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "50%", lg: "40%" },
            // height: 650,
            height: "90vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}
        >
          {/* Top Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              borderBottom: "1px solid gray",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Publication")}
            </Typography>
            <IconButton onClick={handleClose} color="error">
              <CancelSharp />
            </IconButton>
          </Box>

          {/* Main conaint */}
          <Box
            sx={{
              position: "relative",
              height: "80%",
              width: "100%",
              overflow: "auto",
              marginRight: 1,
            }}
          >
            <Card elevation={0}>
              <CardHeader
                avatar={
                  <Avatar
                    src={post.person?.profile}
                    sx={{ cursor: "pointer", border: "1px solid gray" }}
                  />
                }
                action={
                  <IconButton
                    onClick={handleOpenMenu}
                    aria-label="settings"
                    color="inherit"
                  >
                    <MoreVertOutlined />
                  </IconButton>
                }
                title={fullname}
                subheader={myFormatDate(post.createdAt)}
              />

              {/* Menu */}
              <>
                <RWebShare
                  data={{
                    text: "Post",
                    url: `${FRONT_URL}/post/view/post?postId=${post.id}`,
                    title: `${t(
                      "Share_this_post"
                    )} "${FRONT_URL}/post/view/post/${post.id}"`,
                  }}
                >
                  <Box component="span" ref={shareRef}></Box>
                </RWebShare>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                    onClick={(e) => {
                      handleCloseMenu(e);
                      handleProgrammaticShare();
                    }}
                  >
                    <ShareOutlined />
                    {t("to_Share")}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                    onClick={(e) => {
                      handleCloseMenu(e);
                    }}
                  >
                    <ReportOutlined />
                    {t("to_Report")}
                  </MenuItem>
                  {urlIsMyPosts && (
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                      }}
                      onClick={(e) => {
                        handleCloseMenu(e);
                        handleOpenDialogDeletePost();
                      }}
                    >
                      <Delete color="error" />
                      {t("to_Delete")}
                    </MenuItem>
                  )}
                </Menu>
              </>

              <CardContent>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {post.comment}
                </Typography>
              </CardContent>

              <CardMedia
                component="img"
                height="20%"
                image={post.image}
                alt={"img"}
                sx={{ objectFit: "contain" }}
              />

              <CardActions>
                <Box width="100%">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>
                      {post.totalPostComments} {t("Comments")}
                    </Typography>
                    <IconButton
                      onClick={handleProgrammaticShare}
                      size="small"
                      aria-label="share"
                      color="inherit"
                    >
                      <ShareOutlined />
                    </IconButton>
                  </Box>

                  <Divider />
                </Box>
              </CardActions>
            </Card>

            <PostCommentsWrapperCmp
              postCommentsList={postCommentsList}
              handleDeletePostComment={handleDeletePostComment}
            />

            <Box
              sx={{
                margin: 4,
                display: isFinishedPostComments.current ? "none" : "flex",
                justifyContent: "center",
              }}
            >
              {queryViewRecentPostComments.isFetching ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="outlined"
                  endIcon={<ExpandMoreOutlined />}
                  onClick={loadMorePostComments}
                  sx={{ whiteSpace: "nowrap", textTransform: "none" }}
                >
                  {t("Load_more")}
                </Button>
              )}
            </Box>
          </Box>

          {/* Footer */}
          <Box
            sx={{
              borderTop: "1px solid gray",
              paddingTop: 1,
              paddingLeft: 2,
              paddingRight: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              width="100%"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <Avatar
                src={PRINCIPAL?.profile}
                sx={{ cursor: "pointer", border: "1px solid gray" }}
              />
              <TextField
                label={t("Add_a_comment")}
                variant="outlined"
                size="small"
                multiline
                maxRows={5}
                color="secondary"
                value={myComment.commentText}
                onChange={handleChangeMyComment}
                sx={{ width: "100%" }}
              />

              <IconButton
                onClick={handleAddComment}
                color="secondary"
                disabled={myComment.commentText.length < 1}
              >
                <IoSend />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ModalViewAPostCmp;
