import { ExpandMoreOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

// Load More Btn Cmp
export const LoadMoreBtnCmp = ({ isFinished, query, loadMore }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        margin: 4,
        display: isFinished.current ? "none" : "flex",
        justifyContent: "center",
      }}
    >
      {query.isPending ? (
        <CircularProgress />
      ) : (
        <Button
          variant="outlined"
          endIcon={<ExpandMoreOutlined />}
          onClick={loadMore}
          sx={{ whiteSpace: "nowrap", textTransform: "none" }}
        >
          {t("Load_more")}
        </Button>
      )}
    </Box>
  );
};
