import { useEffect, useRef, useState } from "react";
import { useDeletePostRequest } from "../../requestsApis/postsRequests";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Delete,
  DeleteOutline,
  MoreVertOutlined,
  ReportOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { myFormatDate } from "../../myHelpers/helperFunctions";
import { RWebShare } from "react-web-share";
import { FRONT_URL, PRINCIPAL } from "../../myHelpers/constants";
import {
  useAddPostCommentRequest,
  useDeletePostCommentRequest,
  useViewRecentPostCommentsRequest,
} from "../../requestsApis/postCommentRequest";
import { IoSend } from "react-icons/io5";
import ModalViewAPostCmp from "../largeComponents/ModalViewAPostCmp";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const APost = ({
  post,
  handleDeletedPost,
  urlIsMyPosts,
  showSnackBarMessage,
  handleDialogLoginOpen,
}) => {
  const [fullname, setFullname] = useState(
    `${post.person?.firstname} ${post.person?.lastname}`
  );

  // menu vars
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // delete post vars
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  // share post vars
  const shareRef = useRef(null);

  const [postDescription, setPostDescription] = useState("");
  const [seeMorePostDescription, setSeeMorePostDescription] = useState(false);
  const commentLimit = 225;
  const [myComment, setMyComment] = useState({
    commenterId: PRINCIPAL?.id || 0,
    postId: post.id,
    commentText: "",
  });
  const [addedComment, setAddedComment] = useState(null);

  const [openModalViewPostComment, setOpenViewPostComment] = useState(false);
  const [postCommentsList, setPostCommentsList] = useState([]);
  const pageNumComments = useRef(0);
  const pageSizeComments = useRef(2);
  const isFinishedPostComments = useRef(false);
  const [enabledViewPostComments, setEnabledViewPostComments] = useState(false);
  var [deletedCommentId, setDeletedCommentId] = useState(0);
  const { t } = useTranslation();

  // mutation delete post
  const mutationDeletePost = useDeletePostRequest(post.id);
  const mutationAddPostComment = useAddPostCommentRequest();
  const queryViewRecentPostComments = useViewRecentPostCommentsRequest(
    post.id,
    pageNumComments.current,
    pageSizeComments.current,
    enabledViewPostComments
  );
  const mutationDeletePostComment = useDeletePostCommentRequest();

  // **********   Methods
  const handleOpenModalViewPostComment = () => {
    setOpenViewPostComment(true);
    handleViewPostComments();
  };
  const handleCloseModalViewPostComment = () => setOpenViewPostComment(false);

  const handleChangeMyComment = (e) => {
    setMyComment({ ...myComment, commentText: e.target.value });
  };

  const handleSeeMore = () => {
    if (post.comment.length > commentLimit) {
      if (!seeMorePostDescription) {
        setPostDescription(post.comment);
        setSeeMorePostDescription(true);
      } else {
        setPostDescription(
          `${post.comment.slice(0, commentLimit)} ...${t("see_more")} ↓`
        );
        setSeeMorePostDescription(false);
      }
    }
  };

  const handleAddComment = () => {
    if (!PRINCIPAL) {
      handleDialogLoginOpen();
    } else {
      mutationAddPostComment.mutate(myComment);

      setMyComment({ ...myComment, commentText: "" });
    }
  };

  const handleViewPostComments = () => {
    if (!enabledViewPostComments) {
      setEnabledViewPostComments(true);
    }
  };

  const loadMorePostComments = () => {
    if (
      !isFinishedPostComments.current &&
      !queryViewRecentPostComments.isLoading
    ) {
      pageNumComments.current++;
      queryViewRecentPostComments.refetch();
    }
  };

  // menu methods
  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // delete Post logic
  const handleOpenDialogDeletePost = () => {
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDeletePost = () => {
    setOpenDialogDelete(false);
  };

  const handleDeletePost = () => {
    handleCloseDialogDeletePost();
    mutationDeletePost.mutate();
  };

  // share programmatic
  const handleProgrammaticShare = () => {
    shareRef.current?.click();
  };

  // remove deleted PostComment from list
  const removeDeletedPostComment = () => {
    setPostCommentsList((prevList) =>
      prevList.filter((row) => row.id !== deletedCommentId)
    );
  };

  const handleDeletePostComment = (commentId) => {
    setDeletedCommentId(commentId);
    mutationDeletePostComment.mutate(commentId);
  };

  // delete effect
  useEffect(() => {
    if (mutationDeletePost.isError) {
    }

    if (mutationDeletePost.isSuccess) {
      handleDeletedPost(post);
    }
  }, [mutationDeletePost.isPending]);

  useEffect(() => {
    if (post !== null && post.comment !== null) {
      if (post.comment.length > commentLimit) {
        setPostDescription(
          `${post.comment.slice(0, commentLimit)} ...${t("see_more")} ↓`
        );
      } else {
        setPostDescription(post.comment);
      }
    }
  }, [post]);

  useEffect(() => {
    if (mutationAddPostComment.isError) {
      showSnackBarMessage(t("Error_commenting"), "error");
      console.log(mutationAddPostComment.error);
    }

    if (mutationAddPostComment.isSuccess) {
      setAddedComment(mutationAddPostComment.data?.data);
      setPostCommentsList((prevList) => [
        mutationAddPostComment.data?.data,
        ...prevList,
      ]);
    }
  }, [mutationAddPostComment.isPending]);

  useEffect(() => {
    if (queryViewRecentPostComments.isError) {
      console.log(queryViewRecentPostComments.error);
    }

    if (queryViewRecentPostComments.isSuccess) {
      setPostCommentsList((prevList) => [
        ...prevList,
        ...queryViewRecentPostComments.data.data,
      ]);

      console.log(queryViewRecentPostComments.data.data);

      if (
        queryViewRecentPostComments.data.data.length < pageSizeComments.current
      ) {
        isFinishedPostComments.current = true;
      }
    }
  }, [queryViewRecentPostComments.isPending]);

  useEffect(() => {
    if (mutationDeletePostComment.isError) {
      console.log(mutationDeletePostComment.error);
      showSnackBarMessage(t("Error_Deleting_comment"), "error");
    }

    if (mutationDeletePostComment.isSuccess) {
      showSnackBarMessage(t("Deleted_Comment_Successfully"), "success");
      removeDeletedPostComment();
    }
  }, [mutationDeletePostComment.isPending]);

  return (
    <Card
      sx={{ marginBottom: 5, width: "100%", maxWidth: 420, marginX: "auto" }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={post.person?.profile}
            sx={{ cursor: "pointer", border: "1px solid gray" }}
          />
        }
        action={
          <IconButton
            onClick={handleOpenMenu}
            aria-label="settings"
            size="small"
          >
            <MoreVertOutlined />
          </IconButton>
        }
        title={fullname}
        subheader={myFormatDate(post.createdAt)}
      />

      {/* Menu */}
      <>
        <RWebShare
          data={{
            text: "Post",
            url: `${FRONT_URL}/ViewPost/${post.id}`,
            title: `${t("Share_this_post")} "${FRONT_URL}/ViewPost/${post.id}"`,
          }}
        >
          <Box component="span" ref={shareRef}></Box>
        </RWebShare>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
        >
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
            onClick={(e) => {
              handleCloseMenu(e);
              handleProgrammaticShare();
            }}
          >
            <ShareOutlined />
            {t("to_Share")}
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
            onClick={(e) => {
              handleCloseMenu(e);
            }}
          >
            <ReportOutlined />
            {t("to_Report")}
          </MenuItem>
          {urlIsMyPosts && (
            <MenuItem
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
              onClick={(e) => {
                handleCloseMenu(e);
                handleOpenDialogDeletePost();
              }}
            >
              <Delete color="error" />
              {t("Delete")}
            </MenuItem>
          )}
        </Menu>
      </>

      <CardContent>
        <Typography
          onClick={handleSeeMore}
          variant="body2"
          sx={{
            whiteSpace: "pre-wrap",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {postDescription}
        </Typography>
      </CardContent>

      <CardMedia
        onClick={handleOpenModalViewPostComment}
        component="img"
        image={post.image}
        alt=""
        sx={{ cursor: "pointer", borderRadius: 4 }}
      />
      <Divider />
      <CardActions
        sx={{ paddingX: 2, display: "flex", justifyContent: "space-between" }}
      >
        <Box width="100%">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 1,
            }}
          >
            <Typography
              onClick={handleOpenModalViewPostComment}
              sx={{
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {post.totalPostComments} {t("Comments")}
            </Typography>
            <IconButton
              onClick={handleProgrammaticShare}
              size="small"
              aria-label="share"
              color="inherit"
            >
              <ShareOutlined />
            </IconButton>
          </Box>
          <Divider />
          <Box
            width="100%"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginY: 2,
            }}
          >
            <Avatar
              src={PRINCIPAL?.profile}
              sx={{ cursor: "pointer", border: "1px solid gray" }}
            />
            <TextField
              label={t("Add_a_comment")}
              variant="outlined"
              size="small"
              multiline
              maxRows={5}
              color="secondary"
              value={myComment.commentText}
              onChange={handleChangeMyComment}
              sx={{ width: "100%" }}
            />

            <IconButton
              onClick={handleAddComment}
              color="secondary"
              disabled={myComment.commentText.length < 1}
            >
              <IoSend />
            </IconButton>
          </Box>

          <Divider />

          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            {addedComment && (
              <Box
                onClick={handleOpenModalViewPostComment}
                width="95%"
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  gap: 2,
                  paddingY: 2,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Avatar
                  src={PRINCIPAL?.profile}
                  sx={{
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    border: "1px solid gray",
                  }}
                />
                <Typography
                  sx={{
                    whiteSpace: "pre-wrap",
                    whiteSpaceCollapse: "break-spaces",
                    wordBreak: "break-word",
                    textWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {addedComment.commentText}
                </Typography>

                <IconButton color="inherit">
                  <MoreVertOutlined />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </CardActions>

      <ModalViewAPostCmp
        open={openModalViewPostComment}
        handleClose={handleCloseModalViewPostComment}
        post={post}
        handleViewPostComments={handleViewPostComments}
        loadMorePostComments={loadMorePostComments}
        queryViewRecentPostComments={queryViewRecentPostComments}
        postCommentsList={postCommentsList}
        isFinishedPostComments={isFinishedPostComments}
        showSnackBarMessage={showSnackBarMessage}
        urlIsMyPosts={urlIsMyPosts}
        handleOpenDialogDeletePost={handleOpenDialogDeletePost}
        myComment={myComment}
        handleChangeMyComment={handleChangeMyComment}
        handleAddComment={handleAddComment}
        handleDeletePostComment={handleDeletePostComment}
      />

      {/* Dialog Delete Post ? */}
      <DialogDeletePostCmp
        openDialogDelete={openDialogDelete}
        handleCloseDialogDeletePost={handleCloseDialogDeletePost}
        post={post}
        handleDeletePost={handleDeletePost}
      />
    </Card>
  );
};
export default APost;

// Cmp Dialog Delete Post
const DialogDeletePostCmp = ({
  openDialogDelete,
  handleCloseDialogDeletePost,
  post,
  handleDeletePost,
}) => {
  return (
    <Dialog
      open={openDialogDelete}
      onClose={handleCloseDialogDeletePost}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" />
        <span>{t("Delete_this_post")} ?</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}>
          {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleCloseDialogDeletePost}
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeletePost}
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("to_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
