import {
  Delete,
  DeleteOutline,
  Edit,
  PhotoCamera,
  Preview,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DialogModifyProfilePicture } from "./DialogModifyProfilePicture";
import { useDeleteProfilePicRequest } from "../../requestsApis/personRequest";
import { CustomSnackbar } from "../ui/CustomSnackbar";
import { FiEdit } from "react-icons/fi";
import { useModalViewImage } from "../../hooks/useModalViewImage";
import { useTranslation } from "react-i18next";

export const ProfileHeaderCmp = ({ principal, setPrincipal }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuProfPic = Boolean(anchorEl);
  const [openDialogModifProfPic, setOpenDialogModifProfPic] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  const [durationSnackbar, setDurationSnackbar] = useState(20000);

  const [openDialogDeleteProfPic, setOpenDialogDeleteProfPic] = useState(false);
  const theme = useTheme();
  const isMediumOrlarger = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const {
    ModalViewImageFullSizeCmp,
    handleModalViewImageFullSizeOpen,
    setImageToViewFullSizeLink,
  } = useModalViewImage();

  // mutations
  const mutationDeleteProfPic = useDeleteProfilePicRequest(principal.id);

  //   *********************          Methods
  const handleOpenMenuProfPic = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuProfPic = () => {
    setAnchorEl(null);
  };

  const handleModifyProfPic = () => {
    handleCloseMenuProfPic();
    handleOpenDialogModifProfPic();
  };

  const handleDeleteProfPic = () => {
    handleCloseMenuProfPic();
    mutationDeleteProfPic.mutate();
  };

  const handleOpenDialogModifProfPic = () => {
    setOpenDialogModifProfPic(true);
  };

  const handleCloseDialogModifProfPic = () => {
    setOpenDialogModifProfPic(false);
  };

  const handleClickOpenDialogDeleteProfPic = () => {
    handleCloseMenuProfPic();
    setOpenDialogDeleteProfPic(true);
  };

  const handleCloseDialogDeleteProfPic = () => {
    setOpenDialogDeleteProfPic(false);
  };

  const viewImageFullSize = () => {
    setImageToViewFullSizeLink(principal?.profile || "");
    handleModalViewImageFullSizeOpen();
    handleCloseMenuProfPic();
  };

  //   *********************          use Effects

  useEffect(() => {
    if (mutationDeleteProfPic.isError) {
      console.log(mutationDeleteProfPic.error.response);
      setMessageSnackbar(t("Error_deleting_profile_picture"));
      setSeveritySnackbar("error");
      setOpenSnackbar(true);
    }

    if (mutationDeleteProfPic.isSuccess) {
      setMessageSnackbar(t("Deleted_profile_picture_successfully"));
      setSeveritySnackbar("success");
      setOpenSnackbar(true);
    }
  }, [mutationDeleteProfPic.isPending]);

  return (
    <Box sx={{ marginBottom: 2 }}>
      <ModalViewImageFullSizeCmp />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingY: 2,
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ position: "relative", display: "inline-block" }}
            onClick={handleOpenMenuProfPic}
          >
            <Avatar
              src={principal.profile}
              sx={{
                width: 40,
                height: 40,
                mr: 2,
                cursor: "pointer",
                border: "2px solid gray",
              }}
            />
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                bottom: 5,
                right: 10,
                backgroundColor: "whitesmoke",
                boxShadow: 2,
                padding: "3px",
                color: "black",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              <PhotoCamera sx={{ fontSize: 10 }} />
            </IconButton>
          </Box>

          <Box>
            <Typography sx={{ wordBreak: "break-word" }}>
              {principal.firstname} {principal.lastname}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              @
              {principal.accountVerified
                ? t("Business_account")
                : t("Personal_Account")}
            </Typography>
          </Box>
          <Menu
            id="menu-profile-pic"
            anchorEl={anchorEl}
            open={openMenuProfPic}
            onClose={handleCloseMenuProfPic}
            MenuListProps={{ "aria-labelledby": "profile-button" }}
          >
            <MenuItem
              onClick={viewImageFullSize}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Preview fontSize="small" />
              {t("View_picture")}
            </MenuItem>

            <MenuItem
              onClick={handleModifyProfPic}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Edit fontSize="small" />
              {t("Modify_picture")}
            </MenuItem>

            <MenuItem
              onClick={handleClickOpenDialogDeleteProfPic}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Delete color="error" fontSize="small" />
              {t("Delete_picture")}
            </MenuItem>
          </Menu>
        </Box>

        <Link to="about">
          {isMediumOrlarger ? (
            <Button
              startIcon={<FiEdit fontSize="medium" />}
              variant="text"
              sx={{
                textTransform: "none",
                color: "secondary.main",
              }}
            >
              <u>{t("Edit")}</u>
            </Button>
          ) : (
            <IconButton color="secondary">
              <FiEdit />
            </IconButton>
          )}
        </Link>

        <DialogModifyProfilePicture
          principal={principal}
          setPrincipal={setPrincipal}
          open={openDialogModifProfPic}
          handleClose={handleCloseDialogModifProfPic}
          setMessageSnackbar={setMessageSnackbar}
          setSeveritySnackbar={setSeveritySnackbar}
          setOpenSnackbar={setOpenSnackbar}
          setDurationSnackbar={setDurationSnackbar}
        />

        <DialogDeleteProfPic
          open={openDialogDeleteProfPic}
          handleClose={handleCloseDialogDeleteProfPic}
          handleDelete={handleDeleteProfPic}
        />
        <CustomSnackbar
          message={messageSnakbar}
          severity={severitySnackbar}
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          duration={durationSnackbar}
        />
      </Box>
      <Divider />
    </Box>
  );
};

const DialogDeleteProfPic = ({ open, handleClose, handleDelete }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <DeleteOutline color="error" /> {t("Delete_Profile_Picture")} ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ minWidth: { xs: "inherit", sm: "25rem" } }}
          id="alert-dialog-description"
        >
          {t("This_action_can_not_be_undone")} !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
          variant="text"
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleDelete();
            handleClose();
          }}
          color="error"
          autoFocus
          sx={{ textTransform: "none", paddingX: 5 }}
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
