import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ProductsCmp from "../homePage/ProductsCmp";
import { ExpandMoreOutlined, FolderOutlined } from "@mui/icons-material";
import { useGetProductsFromDossierRequest } from "../../requestsApis/dossiersRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import { useTranslation } from "react-i18next";

const ViewDossierPage = () => {
  const { dossierIdParam } = useParams();
  const dossierId = Number(dossierIdParam);
  const [dossierToView, setDossierToView] = useState(null);
  let pageNumProd = useRef(0);
  let pageSizeProd = useRef(24);
  let isFinishedProd = useRef(false);
  const [productsList, setProductsList] = useState([]);

  const { t } = useTranslation();

  const urlLocation = useLocation();
  const [urlIsMyCatalogue, setUrlIsMyCatalogue] = useState(
    urlLocation.pathname.includes(`profile/myCatalogue/${dossierId}`)
  );
  const [urlIsMyCatalogueAndImOwner, setUrlIsMyCatalogueAndImOwner] =
    useState(false);

  const queryGetProductsFromDossier = useGetProductsFromDossierRequest(
    dossierId || 0,
    pageNumProd.current,
    pageSizeProd.current
  );

  const [errorPage, setErrorPage] = useState("");

  const loadMoreProducts = () => {
    if (!isFinishedProd.current) {
      if (!queryGetProductsFromDossier.isLoading) {
        pageNumProd.current++;
        queryGetProductsFromDossier.refetch();
      }
    }
  };

  //    ************    Effect

  useEffect(() => {
    if (queryGetProductsFromDossier.isError) {
      console.log(queryGetProductsFromDossier.error);
      setErrorPage(t("Error_404_Page_not_found"));
    }

    if (queryGetProductsFromDossier.isSuccess) {
      if (dossierToView == null) {
        const dossier = queryGetProductsFromDossier.data.data;
        setDossierToView(dossier);

        if (urlIsMyCatalogue) {
          if (dossier.creatorId === PRINCIPAL.id) {
            setUrlIsMyCatalogueAndImOwner(true);
          }
        }
      }

      if (queryGetProductsFromDossier.data?.data) {
        const newProducts = queryGetProductsFromDossier.data?.data?.productList;

        setProductsList((prevProducts) => [...prevProducts, ...newProducts]);

        if (newProducts.length < pageSizeProd.current) {
          isFinishedProd.current = true;
        }
      } else {
        setErrorPage(t("Error_404_Page_not_found"));
      }
    }
  }, [queryGetProductsFromDossier.isPending]);

  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />
      <Box>
        {dossierToView ? (
          <Box>
            <Typography
              variant="body2"
              sx={{ marginY: 1, display: "flex", alignItems: "center", gap: 1 }}
            >
              <FolderOutlined /> {t("My_Catalog")} &gt;
              <Box
                component="span"
                sx={{ color: "secondary.main", marginLeft: 1 }}
              >
                {dossierToView.name}
              </Box>
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {dossierToView.totalProducts} {t("Favorites_Products")}
            </Typography>
            <ProductsCmp
              products={productsList}
              setProductsList={setProductsList}
              urlIsMyCatalogueAndImOwner={urlIsMyCatalogueAndImOwner}
              dossierId={dossierToView.id}
            />
          </Box>
        ) : (
          <>
            <Typography variant="h6" fontWeight="bold" fontSize={40}>
              {errorPage}
            </Typography>
          </>
        )}

        {/* Progress bar */}
        <Box
          sx={{
            margin: 4,
            display: isFinishedProd.current ? "none" : "flex",
            justifyContent: "center",
          }}
        >
          {queryGetProductsFromDossier.isFetching ? (
            <CircularProgress />
          ) : (
            <Button
              variant="outlined"
              endIcon={<ExpandMoreOutlined />}
              onClick={loadMoreProducts}
              sx={{
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            >
              {t("Load_more")}
            </Button>
          )}
        </Box>
      </Box>
    </ContainerizedBox>
  );
};
export default ViewDossierPage;
