import {
  Box,
  Button,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ContainerizedBox } from "../../components/ui/ContainerizedBox";
import {
  AddBusinessOutlined,
  FavoriteBorderOutlined,
  LiveHelpOutlined,
  LocationOnOutlined,
  PersonOutline,
  ShoppingCartOutlined,
  StoreOutlined,
  Verified,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import FavoritesPage from "./FavoritesPage";
import OrdersSentPage from "./OrdersSentPage";
import FollowingPage from "./FollowingPage";
import MyPostsPage from "./MyPostsPage";
import AboutMePage from "./AboutMePage";
import { useProfileRequest } from "../../requestsApis/personRequest";
import { PRINCIPAL } from "../../myHelpers/constants";
import { ProfileHeaderCmp } from "../../components/largeComponents/ProfileHeaderCmp";
import ModalVerifyMyAccountCmp from "../../components/largeComponents/ModalVerifyMyAccountCmp";
import { CustomSnackbar } from "../../components/ui/CustomSnackbar";
import ModalAddAddressLocation from "../../components/largeComponents/ModalAddAddressLocation";
import { DialogLoginExpiredComponent } from "../../components/largeComponents/DialogLoginExpiredComponent";
import MyStoresPage from "./MyStoresPage";
import { useTranslation } from "react-i18next";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: "black",
  backgroundColor: "#f7f7f7",
  minHeight: "30px",
  borderRadius: "3px",
  "&.Mui-selected": {
    color: "black",
    backgroundColor: "#bdb2ff",
  },
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  marginRight: theme.spacing(2),
}));

const ProfilePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumOrlarger = useMediaQuery(theme.breakpoints.up("md"));

  const [principal, setPrincipal] = useState(PRINCIPAL);
  const [selectedTab, setSelectedTab] = useState(0);

  const { t } = useTranslation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnakbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  const [addWhatsappFromProfileHeader, setAddWhatsappFromProfileHeader] =
    useState(false);
  const [addLocationFromAboutPage, setAddLocationFromAboutPage] =
    useState(false);
  const [verifyFromAboutPage, setVerifyFromAboutPage] = useState(false);

  // queries
  const queryProfile = useProfileRequest(PRINCIPAL?.id || 0);

  // snackbar
  const showSnackBarMessage = (message, severity) => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setOpenSnackbar(true);
  };

  // ********************     Methods

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const openAddWhatsappFromProfileHeader = () => {
    setAddWhatsappFromProfileHeader(true);
  };

  const closeAddWhatsappFromProfileHeader = () => {
    setAddWhatsappFromProfileHeader(false);
  };

  const openAddLocationFromAboutPage = () => {
    setAddLocationFromAboutPage(true);
  };

  const closeAddLocationFromAboutPage = () => {
    setAddLocationFromAboutPage(false);
  };

  const openVerifyFromAboutPage = () => {
    setVerifyFromAboutPage(true);
  };

  const closeVerifyFromAboutPage = () => {
    setVerifyFromAboutPage(false);
  };

  // ********************     Effects
  useEffect(() => {
    if (!principal) {
      window.location.href = "/signIn";
    }
  }, [principal]);

  useEffect(() => {
    if (queryProfile.isSuccess && queryProfile.data) {
      let fetchedPrincipal = queryProfile?.data?.data?.principal;

      localStorage.setItem("principal", JSON.stringify(fetchedPrincipal));
      setPrincipal(fetchedPrincipal);
    }
  }, [queryProfile.isPending]);

  if (!principal) return null;
  return (
    <ContainerizedBox>
      <DialogLoginExpiredComponent />
      <ProfileHeaderCmp principal={principal} setPrincipal={setPrincipal} />

      {/* WhatsApp Buttons */}
      <BtnWhatAppsCmp
        principal={principal}
        setPrincipal={setPrincipal}
        queryProfile={queryProfile}
        showSnackBarMessage={showSnackBarMessage}
        openAddWhatsappFromProfileHeader={openAddWhatsappFromProfileHeader}
        addLocationFromAboutPage={addLocationFromAboutPage}
        openAddLocationFromAboutPage={openAddLocationFromAboutPage}
        closeAddLocationFromAboutPage={closeAddLocationFromAboutPage}
        verifyFromAboutPage={verifyFromAboutPage}
        closeVerifyFromAboutPage={closeVerifyFromAboutPage}
      />

      {/* Tabs sx={{ width: "100%", display: "flex", justifyContent: "center" }}*/}
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{ style: { display: "none" } }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs"
          sx={{ marginTop: 2, minHeight: "30px" }}
        >
          <StyledTab
            label={isMediumOrlarger ? t("My_Stores") : null}
            icon={<StoreOutlined fontSize="small" />}
            iconPosition="start"
            onClick={() => navigate("")}
          />
          <StyledTab
            label={isMediumOrlarger ? t("Favorites") : null}
            icon={<FavoriteBorderOutlined fontSize="small" />}
            iconPosition="start"
            onClick={() => navigate("favorites")}
          />
          <StyledTab
            label={isMediumOrlarger ? t("Orders_Sent") : null}
            icon={<ShoppingCartOutlined fontSize="small" />}
            iconPosition="start"
            onClick={() => navigate("ordersSent")}
          />
          <StyledTab
            label={isMediumOrlarger ? t("Following") : null}
            icon={<AddBusinessOutlined fontSize="small" />}
            iconPosition="start"
            onClick={() => navigate("following")}
          />
          <StyledTab
            label={isMediumOrlarger ? t("My Posts") : null}
            icon={<LiveHelpOutlined fontSize="small" />}
            iconPosition="start"
            onClick={() => navigate("myPosts")}
          />
          <StyledTab
            label={isMediumOrlarger ? t("About") : null}
            icon={<PersonOutline fontSize="small" />}
            iconPosition="start"
            onClick={() => navigate("about")}
          />
        </Tabs>
      </Box>

      {/* Paper Routes */}
      <Paper
        variant="outlined"
        sx={{ paddingX: 1, paddingY: 2, minHeight: "300px" }}
      >
        {/* <main>
          <Outlet />
        </main> */}
        <Routes>
          <Route
            path=""
            element={<MyStoresPage setSelectedTab={setSelectedTab} />}
          />
          <Route
            path="favorites"
            element={<FavoritesPage setSelectedTab={setSelectedTab} />}
          />
          <Route
            path="ordersSent"
            element={<OrdersSentPage setSelectedTab={setSelectedTab} />}
          />
          <Route
            path="following"
            element={<FollowingPage setSelectedTab={setSelectedTab} />}
          />
          <Route
            path="myPosts"
            element={<MyPostsPage setSelectedTab={setSelectedTab} />}
          />

          {/* About */}
          <Route
            path="about"
            element={
              <AboutMePage
                setSelectedTab={setSelectedTab}
                principal={principal}
                setPrincipal={setPrincipal}
                addWhatsappFromProfileHeader={addWhatsappFromProfileHeader}
                closeAddWhatsappFromProfileHeader={
                  closeAddWhatsappFromProfileHeader
                }
                addLocationFromAboutPage={addLocationFromAboutPage}
                openAddLocationFromAboutPage={openAddLocationFromAboutPage}
                verifyFromAboutPage={verifyFromAboutPage}
                openVerifyFromAboutPage={openVerifyFromAboutPage}
              />
            }
          />
        </Routes>
      </Paper>

      <CustomSnackbar
        message={messageSnakbar}
        severity={severitySnackbar}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
    </ContainerizedBox>
  );
};
export default ProfilePage;

const BtnWhatAppsCmp = ({
  principal,
  setPrincipal,
  queryProfile,
  showSnackBarMessage,
  openAddWhatsappFromProfileHeader,
  addLocationFromAboutPage,
  openAddLocationFromAboutPage,
  closeAddLocationFromAboutPage,
  verifyFromAboutPage,
  closeVerifyFromAboutPage,
}) => {
  const [clickBtnAddressFromVerify, setClickBtnAddressFromVerify] =
    useState(false);

  const { t } = useTranslation();

  const openClickBtnAddressFromVerify = () => {
    setClickBtnAddressFromVerify(true);
  };

  const closeClickBtnAddressFromVerify = () => {
    setClickBtnAddressFromVerify(false);
  };

  const handleAddWhatsappFromProfileHeader = () => {
    openAddWhatsappFromProfileHeader();
  };

  return (
    <Box>
      {queryProfile.isPending ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            overflow: "auto",
            paddingY: 4,
          }}
        >
          <Skeleton variant="rounded" width="20%" height={25} />
          <Skeleton variant="rounded" width="20%" height={25} />
          <Skeleton variant="rounded" width="20%" height={25} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: 1, sm: 2 },
            overflow: "auto",
            paddingBottom: { xs: 2, md: 4 },
          }}
        >
          <Box
            sx={{
              paddingX: { xs: 0, md: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {principal.secondAddress ? (
              <Link to="/profile/about">
                <Button
                  startIcon={<LocationOnOutlined />}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  {principal.secondAddress.locality
                    ? principal.secondAddress.locality
                    : principal.secondAddress.countryName}
                </Button>
              </Link>
            ) : (
              <Box sx={{ textAlign: "center", minWidth: 200 }}>
                <LocationOnOutlined color="secondary" fontSize="small" />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: 12, md: 14 } }}
                >
                  {t("Add_a_Location")}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: 12, marginBottom: 1, maxWidth: 200 }}
                >
                  {t(
                    "Boost_your_visibility_and_make_it_easy_to_locate_your_business"
                  )}
                </Typography>
              </Box>
            )}

            <ModalAddAddressLocation
              showSnackBarMessage={showSnackBarMessage}
              principal={principal}
              setPrincipal={setPrincipal}
              clickBtnAddressFromVerify={clickBtnAddressFromVerify}
              openClickBtnAddressFromVerify={openClickBtnAddressFromVerify}
              closeClickBtnAddressFromVerify={closeClickBtnAddressFromVerify}
              addLocationFromAboutPage={addLocationFromAboutPage}
              closeAddLocationFromAboutPage={closeAddLocationFromAboutPage}
              queryProfile={queryProfile}
            />
          </Box>

          <Box
            sx={{
              paddingX: { xs: 0, md: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {principal.accountVerified ? (
              <Typography
                color="primary"
                fontSize={13}
                fontWeight="bold"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Verified fontSize="small" />
                {t("Verified")}
              </Typography>
            ) : (
              <Box sx={{ textAlign: "center", minWidth: 200 }}>
                {principal?.accountInVerification ? (
                  <Button
                    startIcon={<WarningAmberOutlined />}
                    variant="outlined"
                    color="warning"
                    size="small"
                    sx={{ textTransform: "none" }}
                  >
                    {t("In_Progress")}
                  </Button>
                ) : (
                  <>
                    <WarningAmberOutlined color="error" fontSize="small" />
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{ fontSize: { xs: 12, md: 14 } }}
                    >
                      {t("Verify_My_Account")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: 12, marginBottom: 1, maxWidth: 200 }}
                    >
                      {t(
                        "Enhance_your_online_credibility_and_unlock_more_features"
                      )}
                    </Typography>
                  </>
                )}

                <ModalVerifyMyAccountCmp
                  showSnackBarMessage={showSnackBarMessage}
                  principal={principal}
                  setPrincipal={setPrincipal}
                  openClickBtnAddressFromVerify={openClickBtnAddressFromVerify}
                  verifyFromAboutPage={verifyFromAboutPage}
                  closeVerifyFromAboutPage={closeVerifyFromAboutPage}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              paddingX: { xs: 0, md: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {principal.whatsapp ? (
              <Link to="about">
                <Button
                  onClick={handleAddWhatsappFromProfileHeader}
                  startIcon={<WhatsApp />}
                  variant="outlined"
                  color="success"
                  size="small"
                  sx={{ textTransform: "none" }}
                >
                  WhatsApp
                </Button>
              </Link>
            ) : (
              <Box sx={{ textAlign: "center", minWidth: 200 }}>
                <WhatsApp color="success" fontSize="small" />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ fontSize: { xs: 12, md: 14 } }}
                >
                  {t("Add_a_WhatsApp")}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: 12, marginBottom: 1, maxWidth: 200 }}
                >
                  {t("Make_it_easy_for_others_to_contact_you_or_your_business")}
                </Typography>

                <Link to="about">
                  <Button
                    onClick={handleAddWhatsappFromProfileHeader}
                    startIcon={<WhatsApp />}
                    variant="outlined"
                    color="success"
                    size="small"
                    sx={{ textTransform: "none" }}
                  >
                    {t("Add_WhatsApp")}
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
