import { useMutation, useQuery } from "@tanstack/react-query";
import { BACK_URL, JWT_TOKEN } from "../myHelpers/constants";
import axios from "axios";
const basePath = "/notification";

// See All Notifications Request ❌
export const useSeeAllNotificationsRequest = (userId) => {
  const queryParams = {
    userId: userId,
  };
  const queryString = new URLSearchParams(queryParams).toString();

  const fullUrl = `${BACK_URL}${basePath}/seeAllNotifications?${queryString}`;

  return useMutation({
    mutationFn: async () => {
      return await axios.put(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
  });
};

// Get Notifications Request
export const useGetNotificationsRequest = (personId, pageNum, pageSize) => {
  const queryParams = {
    pageNum: pageNum,
    pageSize: pageSize,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = `${BACK_URL}${basePath}/getNotifications/${personId}?${queryString}`;

  return useQuery({
    queryKey: [fullUrl],
    queryFn: async () => {
      return await axios.get(fullUrl, {
        headers: {
          Authorization: JWT_TOKEN,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: personId !== 0,
  });
};
