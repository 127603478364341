const { useEffect } = require("react");
const { useState } = require("react");

const useIsDeviceAndroid = () => {
  const [isDeviceAndroid, setIsDeviceAndroid] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsDeviceAndroid(/android/i.test(userAgent));
  }, []);

  return isDeviceAndroid;
};

export default useIsDeviceAndroid;
